import { useState, useEffect, useCallback } from 'react';

const useButtonlListener = handleButtonPressFunction => {
  // adding the eventListener for pressing the button
  useEffect(() => {
    document.addEventListener('keydown', handleButtonPressFunction);
    document.addEventListener('keyup', handleButtonPressFunction);
    return () => {
      document.removeEventListener('keydown', handleButtonPressFunction);
      document.removeEventListener('keyup', handleButtonPressFunction);
    };
  }, [handleButtonPressFunction]);
};

export default useButtonlListener;

export const useCtrlListener = () => {
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const handleCtrlPressFunction = useCallback(e => {
    if (e.keyCode === 17 || e.keyCode === 91) {
      if (e.type === 'keydown') {
        setCtrlPressed(true);
      } else setCtrlPressed(false);
    }
  }, []);
  useButtonlListener(handleCtrlPressFunction);

  return ctrlPressed;
};

export const useCtrlKListener = () => {
  const [ctrlKPressed, setCtrlKPressed] = useState(false);
  const handleCtrlPressFunction = useCallback(e => {
    if ((e.ctrlKey || e.metaKey) && (e.key === 'k' || e.key === 'л')) {
      if (e.type === 'keydown') {
        e.preventDefault();
        e.stopPropagation();
        setCtrlKPressed(true);
      } else setCtrlKPressed(false);
    }
  }, []);
  useButtonlListener(handleCtrlPressFunction);

  return ctrlKPressed;
};

export const useEnterListener = () => {
  const [enterPressed, setEnterPressed] = useState(false);
  const handleEnterPressFunction = useCallback(e => {
    if (e.key === 'Enter') {
      if (e.type === 'keydown') {
        setEnterPressed(true);
      } else setEnterPressed(false);
    }
  }, []);
  useButtonlListener(handleEnterPressFunction);

  return enterPressed;
};

export const useEscapeListener = () => {
  const [escapePressed, setEscapePressed] = useState(false);
  const handleEscapePressFunction = useCallback(e => {
    if (e.key === 'Escape') {
      if (e.type === 'keydown') {
        e.preventDefault();
        setEscapePressed(true);
      } else setEscapePressed(false);
    }
  }, []);
  useButtonlListener(handleEscapePressFunction);

  return escapePressed;
};

export const useArrowDownListener = () => {
  const [arrowDownPressed, setArrowDownPressed] = useState(false);
  const handleArrowDownPressFunction = useCallback(e => {
    if (e.key === 'ArrowDown') {
      if (e.type === 'keydown') {
        setArrowDownPressed(true);
      } else setArrowDownPressed(false);
    }
  }, []);
  useButtonlListener(handleArrowDownPressFunction);

  return arrowDownPressed;
};

export const useArrowUpListener = () => {
  const [arrowUpPressed, setArrowUpPressed] = useState(false);
  const handleArrowUpPressFunction = useCallback(e => {
    if (e.key === 'ArrowUp') {
      if (e.type === 'keydown') {
        setArrowUpPressed(true);
      } else setArrowUpPressed(false);
    }
  }, []);
  useButtonlListener(handleArrowUpPressFunction);

  return arrowUpPressed;
};
