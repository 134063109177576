import { useState, useEffect, useContext } from 'react';

import { PushNotificationContext } from '../contexts/PushNotificationContext';

const NetworkDetectorHoc = ({ children, rerenderPage }) => {
  const [isDisconnected, setIsDisconnected] = useState(false);
  const { pushNotification, hidePushNotification, showPushNotifications } = useContext(PushNotificationContext);
  const [requestsCounter, setRequestsCounter] = useState(0);

  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  useEffect(() => {
    if (isDisconnected) {
      pushNotification({
        data: {
          name: "Looks like you're offline. Please check your network connection and try again",
        },
        id: 'offline-notification',
        pushNotificationTimeout: 60000,
      });
    } else if (
      !isDisconnected &&
      showPushNotifications.length > 0 &&
      showPushNotifications.find(el => el.id === 'offline-notification')
    ) {
      rerenderPage();
      hidePushNotification('offline-notification');
      pushNotification({
        data: {
          name: 'Ok, now back online',
        },
        id: 'back-online',
      });
    }
  }, [isDisconnected]);

  const handleConnectionChange = () => {
    setRequestsCounter(requestsCounter + 1);
    if (requestsCounter < 5) {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        const webPing = setInterval(() => {
          fetch(`${process.env.REACT_APP_WEBSITE_URL}/api/check`, {
            mode: 'no-cors',
          })
            .then(() => {
              setIsDisconnected(false);
              setRequestsCounter(0);
              return clearInterval(webPing);
            })
            .catch(() => setIsDisconnected(true));
        }, 15000);
        return;
      }

      return setIsDisconnected(true);
    }
  };

  return children;
};

export default NetworkDetectorHoc;
