import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { EchoContext } from '../contexts/EchoContext';
import { v4 as uuidv4 } from 'uuid';
import { PushNotificationContext } from '../contexts/PushNotificationContext';

const WsListener = () => {
  const [isListenerActivated, setIsListenerActivated] = useState(false);
  const { echo } = useContext(EchoContext);
  const { user, isAuth } = useContext(AuthContext);
  const { pushNotification } = useContext(PushNotificationContext);

  useEffect(() => {
    if (isAuth && echo && user && user.id && !isListenerActivated) {
      echo.private(`users.${user.id}`).listen('.talosHealthCheckFinished', res => {
        if (res.message) {
          pushNotification({
            data: {
              type: 'success',
              name: res.message,
            },
            id: uuidv4(),
          });
        }
      });
      echo.private(`users.${user.id}`).listen('.K8sPoolChanged', res => {
        if (res.message) {
          pushNotification({
            data: {
              type: 'success',
              name: res.message,
            },
            id: uuidv4(),
            timeout: 50000,
          });
        }
      });
      echo.private(`users.${user.id}`).listen('.deviceBackupChanged', res => {
        if (res.message) {
          pushNotification({
            data: {
              type: 'success',
              name: res.message,
            },
            id: uuidv4(),
            timeout: 50000,
          });
        }
      });
      setIsListenerActivated(true);
    }
  }, [isAuth, echo, user]);

  return null;
};

export default WsListener;
