export default class Errors {
  /**
   * Errors constructor.
   * @param fields
   */
  constructor(fields) {
    this.fields = fields;
    this.initErrors(fields);
  }

  /**
   * Init empty form errors.
   */
  initErrors(fields) {
    this.errors = {};

    fields.forEach(field => {
      this.errors[field] = [];
    });
  }

  /**
   * Determine whether the given field contains any error.
   * @param field
   * @returns {boolean}
   */
  has(field) {
    return this.errors[field] && this.errors[field].length;
  }

  /**
   * TODO: include only errors from initial fields (ignore another fields)
   * Determine whether any field contains any error.
   * @returns {boolean}
   */
  any() {
    return Object.values(this.errors).some(errors => {
      return errors.length;
    });
  }

  /**
   * Get the first error of the given field.
   * @param field
   * @returns {null|*}
   */
  first(field) {
    if (this.errors[field] && this.errors[field].length > 0) {
      return this.errors[field][0];
    }

    return '';
  }

  /**
   * Get all errors where the field name contains the given substring.
   * @param substring
   * @returns {Array}
   */
  findBySubstring(substring) {
    const matchedErrors = [];

    Object.keys(this.errors).forEach(field => {
      if (field.includes(substring)) {
        matchedErrors.push(...this.errors[field]);
      }
    });

    return matchedErrors;
  }

  /**
   * Get all errors of the given field.
   * @param field
   * @returns {null|*}
   */
  get(field) {
    return this.errors[field];
  }

  /**
   * Assign errors.
   * @param errors
   * @param onlyOriginalFields
   */
  assign(errors, onlyOriginalFields = false) {
    Object.keys(errors).forEach(field => {
      if (onlyOriginalFields) {
        if (this.fields.includes(field)) {
          this.errors[field] = errors[field];
        }
      } else {
        this.errors[field] = errors[field];
      }
    });
  }

  /**
   * Clear the field errors.
   * @param field
   */
  clear(field) {
    this.errors[field] = [];
  }

  /**
   * Clear all errors where the field name contains the given substring.
   * @param field
   */
  clearSubstring(field) {
    Object.keys(this.errors).forEach(key => {
      if (key.includes(field)) {
        this.errors[key] = [];
      }
    });
  }

  /**
   * Reset errors for all fields
   */
  reset() {
    Object.keys(this.errors).forEach(field => {
      this.errors[field] = [];
    });
  }

  all() {
    const errors = [];

    Object.keys(this.errors).forEach(field => {
      this.errors[field].forEach(error => {
        errors.push(error);
      });
    });

    return errors;
  }
}
