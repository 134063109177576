import React, { useCallback, useContext, useEffect, useState } from 'react';
import EntityHeader from '../UI/EntityPage/EntityHeader';
import DocumentSvg from '../../images/document.svg';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from '../../axiosInstance';
import clsx from 'clsx';
import Paginator from '../UI/Table/Paginator';
import NoEntitiesMessage from '../UI/EntityPage/NoEntitiesMessage';
import Skeleton from 'react-loading-skeleton';
import { v4 as uuidv4 } from 'uuid';
import { createdAtColumn, DEFAULT_SIZE_PER_PAGE } from '../../utils/constants';
import SearchInput from '../UI/SearchInput';
import debounce from 'lodash/debounce';
import { handleDateObjectConversion } from '../../utils/timeConversion';
import { Button } from 'reactstrap';
import AcceptDocumentsModal from './Modals/AcceptDocumentsModal';
import { AuthContext } from '../contexts/AuthContext';
import { HeaderContext } from '../contexts/HeaderContext';

const DocumentListPage = () => {
  const { setHeader } = useContext(HeaderContext);
  useEffect(() => {
    setHeader('Documents');
  }, [setHeader]);
  const { user, userPermissions } = useContext(AuthContext);

  const columns = [
    {
      dataField: 'name',
      text: 'Document Name',
      formatter: (c, row) => {
        return (
          <p>
            {c} {!!row.required && <span className="sm badge badge-danger badge-pill ml-1">Required</span>}{' '}
            {row.hasParent && <span className="sm badge badge-success badge-pill ml-1">New</span>}
          </p>
        );
      },
    },
    {
      dataField: 'description',
      text: 'Document description',
    },
    {
      dataField: 'ownerTenantName',
      text: 'Owner',
    },
    createdAtColumn(1, 'createdAt', 'Date created', false),
    createdAtColumn(1, 'updatedAt', 'Date modified', false),
    {
      dataField: 'version',
      text: 'Version',
    },
    {
      dataField: 'acceptedAt',
      text: 'Status',
      formatter: (c, row) => {
        if (c) {
          return (
            <p title={`By ${row.acceptedBy}`}>
              <i className="far fa-check-circle on" /> Accepted (
              {handleDateObjectConversion(c, {
                showHours: true,
                showSeconds: false,
                format: false,
                isTimestamp: false,
              })}
              )
            </p>
          );
        } else {
          return (
            <p>
              <i className="fas fa-exclamation-triangle font-warning" /> Not accepted
            </p>
          );
        }
      },
    },
    {
      dataField: 'id',
      text: '',
      formatter: (c, row) => {
        return <i className="fas fa-download mr-4" onClick={() => downloadDocument(c, row.name)} />;
      },
    },
  ];

  const skeletonColumns = columns.map(el => ({
    ...el,
    formatter: () => <Skeleton />,
  }));
  let skeletonData = [];
  let i = 0;
  while (i < 5) {
    const dummyDataObject = { id: uuidv4() };
    columns.map(el => (dummyDataObject[el.dataField] = uuidv4()));
    skeletonData = skeletonData.concat(dummyDataObject);
    i++;
  }

  const [documents, setDocuments] = useState([]);
  const [pager, setPager] = useState({ currentPage: 1 });
  const [fetchingData, setFetchingData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const tableSizes = JSON.parse(localStorage.getItem('tableSizes'));
  const [sizePerPage, setSizePerPage] = useState(
    tableSizes && tableSizes.documents ? tableSizes.documents : DEFAULT_SIZE_PER_PAGE
  );

  const [search, setSearch] = useState('');
  const [acceptVisible, setAcceptModalVisible] = useState(false);

  useEffect(() => {
    if (!tableSizes || !tableSizes.documents) {
      localStorage.setItem('tableSizes', JSON.stringify({ ...tableSizes, documents: DEFAULT_SIZE_PER_PAGE }));
    }
    fetchDocuments(currentPage, sizePerPage, search);
  }, []);

  useEffect(() => {
    fetchDocuments(currentPage, sizePerPage, search);
  }, [sizePerPage, currentPage]);

  const delayedQuery = useCallback(
    debounce((currentPage, sizePerPage, search) => {
      fetchDocuments(currentPage, sizePerPage, search);
    }, 500),
    []
  );

  const setSizePerPageHandler = perPage => {
    setSizePerPage(perPage);
    localStorage.setItem('tableSizes', JSON.stringify({ ...tableSizes, documents: perPage }));
  };

  const handleSearch = search => {
    setSearch(search);
    delayedQuery(currentPage, sizePerPage, search);
  };

  const fetchDocuments = (page, perPage, search) => {
    if (page && perPage) {
      setFetchingData(true);
      axios
        .get('/api/user/documents', { params: { page, perPage, search } })
        .then(response => {
          setDocuments(response.data.data);
          setPager(response.data.meta);
          setFetchingData(false);
        })
        .catch(() => {
          setFetchingData(false);
        });
    }
  };

  const downloadDocument = async (id, name) => {
    await axios
      .post(
        'api/user/documents/download',
        { id },
        {
          responseType: 'blob',
        }
      )
      .then(res => {
        if (res && res.data) {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name + '.pdf');
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  return (
    <>
      <div className="w-100 px-4">
        <EntityHeader
          customMessage="Find all relevant contracts and other legal documents."
          entitySvg={DocumentSvg}
          entityName="Documents"
          dataCy="talos-header"
        />
        <div className="entity-block-table-header mx-auto mt">
          <SearchInput
            onSearch={handleSearch}
            placeholder="Search document"
            className="input-group"
            dataCy="search-input-document"
          />
          {user.isXelonChild && userPermissions.allowAcceptDocuments && (
            <Button color="primary" onClick={() => setAcceptModalVisible(true)}>
              Accept documents
            </Button>
          )}
        </div>
        {!fetchingData ? (
          documents.length > 0 ? (
            <div className="w-100 mt-2">
              <BootstrapTable
                keyField="id"
                data={documents}
                hover={true}
                columns={columns}
                classes="entity-block-table"
                wrapperClasses={clsx('mb text-left entity-block-table-container mx-auto')}
              />
              {
                <Paginator
                  pager={pager}
                  setSizePerPageHandler={setSizePerPageHandler}
                  setCurrentPage={setCurrentPage}
                  fetchingData={fetchingData}
                />
              }
            </div>
          ) : (
            <NoEntitiesMessage dataCy="no-announcements" entityName={'Documents'} className="w-100 mt-3" />
          )
        ) : (
          <div className="mt-4">
            <BootstrapTable
              keyField="id"
              data={skeletonData}
              hover={true}
              columns={skeletonColumns}
              classes="entity-block-table "
              wrapperClasses={clsx('mb text-left entity-block-table-container mx-auto')}
            />
          </div>
        )}
      </div>
      <AcceptDocumentsModal
        modalOpen={acceptVisible}
        toggleModal={() => {
          if (acceptVisible) {
            fetchDocuments(currentPage, sizePerPage, search);
          }

          if (user.acceptDocumentsDaysLeft < 0) {
            window.location.reload();
          }

          setAcceptModalVisible(!acceptVisible);
        }}
      />
    </>
  );
};

export default DocumentListPage;
