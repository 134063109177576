import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import withPushNotification from './withPushNotification';

const withErrorHandler = (WrappedComponent, instance) =>
  withPushNotification(({ pushNotification, ...props }) => {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
      if (pushNotification && !counter) {
        instance.interceptors.request.use(req => {
          return req;
        });
        instance.interceptors.response.use(
          res => {
            if (res && res.data && res.data.message) {
              pushNotification({
                data: { name: res.data.message + '', type: 'success' },
                id: uuidv4(),
              });
            }
            return res;
          },
          error => {
            if (error.response && error.response.data && error.response.data.errors) {
              if (typeof error.response.data.errors === 'string') {
                pushNotification({
                  data: {
                    name: error.response.data.errors,
                    type: 'danger',
                  },
                  id: uuidv4(),
                });
              } else {
                Object.entries(error.response.data.errors).forEach(([key, value]) =>
                  pushNotification({
                    data: {
                      name: value,
                      type: 'danger',
                    },
                    id: uuidv4(),
                  })
                );
              }
            } else {
              let errorName;
              if (error.response && error.response.data.error) {
                if (typeof error.response.data.error === 'object') {
                  errorName = '';
                  Object.keys(error.response.data.error).forEach(key => {
                    errorName += error.response.data.error[key] + ' ';
                  });
                } else {
                  errorName = error.response.data.error;
                }
              } else {
                errorName =
                  error.response && error.response.data && error.response.data.message
                    ? error.response.data.message
                    : error.message;
              }

              pushNotification({
                data: {
                  name: errorName,
                  type: 'danger',
                },
                id: uuidv4(),
              });
            }

            return Promise.reject(error);
          }
        );
        setCounter(1);
      }
    }, [pushNotification]);

    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  });

export default withErrorHandler;
