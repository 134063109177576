import React from 'react';
import pluralize from 'pluralize';
import AttachedLink from '../AttachedLink';
import { Card } from 'reactstrap';

const EntityHeader = ({ entitySvg, entityName, attachedLinks, sideContent, noPlural, customMessage, dataCy }) => {
  return (
    <Card className="row flex-row no-gutters col-md-12 mx-auto p-0 flex-wrap justify-content-between">
      <div className="header-main-content-container">
        <img src={entitySvg} alt={entityName} className="header-icon mr-4 ml-4" />
        <div className="col d-flex flex-column justify-content-center ml-2 max-width-rem-40">
          <h1 className="pl-0">{noPlural ? entityName : pluralize.plural(entityName)}</h1>
          {customMessage ? (
            <h2>{customMessage}</h2>
          ) : (
            <h2>Here you can find the list of all your {noPlural ? entityName : pluralize.plural(entityName)}</h2>
          )}
        </div>
      </div>
      {process.env.REACT_APP_WHITELABELING !== 'true' &&
        (sideContent || (attachedLinks && attachedLinks.length > 0)) && (
          <div className="header-attached-links-container">
            {sideContent
              ? sideContent
              : attachedLinks
              ? attachedLinks.map(({ to, label, type = 'link', blank = true }) => (
                  <AttachedLink
                    key={label}
                    to={to}
                    blank={blank}
                    type={type}
                    label={label}
                    dataCy={`${dataCy}-${label}`}
                  />
                ))
              : null}
          </div>
        )}
    </Card>
  );
};

export default EntityHeader;
