import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import RangedInput from './RangedInput';

const NewRangedInput = ({ onChange, value, name, min, max, step, ...props }) => {
  useEffect(() => {
    if (min > validValue) onChange(min);
  }, [min]);
  // if (value === undefined) onChange(min);
  const validValue = value === undefined || !parseInt(value) ? 0 : parseInt(value);
  if (validValue > max) onChange(max);

  return (
    <RangedInput
      dataCy={props.dataCy}
      name={name}
      handleInputChange={event => onChange(event.target.value)}
      min={() => validValue === min}
      max={() => validValue === max}
      onBlur={() => {
        if (validValue > max) onChange(max);
        if (validValue < min) onChange(min);
      }}
      added={() => onChange(validValue + step > max ? max : validValue + step)}
      subtracted={() => onChange(validValue - step < min ? min : validValue - step)}
      val={validValue}
      {...props}
    />
  );
};

NewRangedInput.defaultValue = {
  step: 1,
};

NewRangedInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  value: PropTypes.number,
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
};

export default NewRangedInput;
