import React from 'react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const NoDataMsg = props =>
  // noCard means no borders around the message are necessary
  props.noCard ? (
    <div className={clsx('mt-1 pl-2', props.className)}>
      <CardBody>
        <p>{props.message || `No ${props.value ? props.value + ' were' : 'data was'} added`}</p>
      </CardBody>
    </div>
  ) : (
    <Card className={clsx('mt', props.className)}>
      <CardBody>
        <p>{props.message || `No ${props.value ? props.value + ' were' : 'data was'} added`}</p>
      </CardBody>
    </Card>
  );

NoDataMsg.propTypes = {
  value: PropTypes.string,
};

export default NoDataMsg;
