import React, { useContext } from 'react';
import { Card, FormFeedback } from 'reactstrap';
import clsx from 'clsx';

import Input from '../UI/Input';
import { calcMounthlyTotal, calcSetupFee } from './utils';
import { ipPrice, valueToLabel } from './initData';
import { ViewContext } from '../contexts/ViewContext';

const RowItem = ({ field, label, value, setup, month }) => (
  <div key={field} className="d-flex flex-column justify-content-between mb-3 border-bottom">
    <h3>{valueToLabel[field] || field}</h3>
    <div key={field} className="d-flex flex-row justify-content-between">
      <div>{label}</div>
      <div>{value && (setup ? value.setupPrice : Math.round(value.price * month * 100) / 100 || undefined)}</div>
    </div>
  </div>
);

const fieldToDom = (setup = false, month) => ([field, subject]) => {
  const { label, value } = subject;
  if (field !== 'hdd') {
    return (
      <RowItem
        field={field}
        label={label}
        value={field === 'ips' ? { value, price: ipPrice * (value - 1) } : value}
        setup={setup}
        month={field !== 'billing_frequency' ? month || 1 : 1}
      />
    );
  } else {
    return (
      <div key={field} className="d-flex flex-column justify-content-between mb-3">
        <h3>Hard Disks</h3>
        {Object.entries(subject).map(([hddName, value]) => (
          <div key={hddName} className="d-flex flex-row justify-content-between">
            <div>{hddName}</div>
            <div>{(value && value.value.price * (month || 1)) || undefined}</div>
          </div>
        ))}
      </div>
    );
  }
};

const BillCard = ({ formData, billingPeriod, email, onEmailChange, onDownload, onSave, error }) => {
  const mounthlyTotal = calcMounthlyTotal(formData, billingPeriod);
  const setupFee = calcSetupFee(formData, billingPeriod);
  const view = useContext(ViewContext);
  const mobile = view === 'xs';
  return (
    <Card
      className={clsx(
        mobile ? 'max-height-600' : 'position-fixed col-3 max-height-all overflow-scroll d-block',
        'p-0 dedicated'
      )}
      style={{ top: 0, right: 0 }}
    >
      <div className="d-flex flex-row p-3">
        <i className="my-auto fas fa-shopping-cart mx-2" alt="cart" style={{ width: 20 }} />
        <h1 className="p-0">Cart Summary</h1>
      </div>
      <div className={clsx(mobile && 'overflow-scroll d-block')}>
        <div className="d-flex flex-column p-4 border-bottom">
          {Object.keys(valueToLabel)
            .map(key => [key, formData[key]])
            .filter(([el, value]) => !!value)
            .map(fieldToDom(false, billingPeriod.value.month))}
        </div>
        <div className="d-flex flex-row p-4 border-bottom justify-content-between">
          <h3>{billingPeriod.value.adverb + ' Excluding VAT'}</h3>
          <h3>{mounthlyTotal + 'CHF'}</h3>
        </div>
        <div className="d-flex flex-column p-4">
          <div className="d-flex flex-column justify-content-between mb-3 border-bottom">
            <div className="d-flex flex-row justify-content-between">
              <h3>Setup Fees</h3>
              <div>{setupFee}</div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ bottom: 0 }} className="d-flex flex-column border-top position-sticky bg-white">
        <div className="d-flex flex-row border-bottom p-4 justify-content-between">
          <h3>First Invoice Total</h3>
          <h3>{setupFee + mounthlyTotal + 'CHF'}</h3>
        </div>
        <h2 className="mt-4 ml-4 mr-4 mb-0">Email</h2>
        <Input
          required
          placeholder="enter your email"
          type="text"
          pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
          value={email}
          className="m-4 w-auto"
          onChange={(name, value) => onEmailChange(value)}
        />
        {error ? <FormFeedback className="d-block input__danger-message mb-3 text-center">{error}</FormFeedback> : null}

        <button className="btn btn-light ml-4 mb-4 mr-4" type="button" onClick={onDownload}>
          Download PDF
        </button>
        <button type="button" className="btn btn-primary ml-4 mb-4 mr-4" onClick={onSave}>
          Continue
        </button>
      </div>
    </Card>
  );
};

export default BillCard;
