import React, { useContext, useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';

import axios from '../../axiosInstance';
import Form from '../UI/Form';
import HeaderedCard from '../UI/HeaderedCard';
import Input from '../UI/Input';
import { orgType } from './init';
import OrderSummaryCard from './OrderSummaryCard';
import { ViewContext } from '../contexts/ViewContext';
import countries from '../../utils/countries';

const DedicatedContactForm = () => {
  const { token } = useParams();
  const history = useHistory();
  const [billData, setBillData] = useState(undefined);
  const [formData, setFormData] = useState({});

  const handleFormDataChange = field => value => {
    setFormData({ ...formData, [field]: value });
  };

  const fetchSummary = () => {
    axios.get(`api/dedicated/configuration/${token}`).then(res => {
      if (res && res.data) {
        setBillData(res.data);
      }
    });
  };

  useEffect(() => {
    fetchSummary();
  }, []);

  const handleSubmit = () => {
    const payload = {
      ...formData,
      email: billData.email,
    };

    axios.post(`api/dedicated/configuration/${token}`, payload).then(res => {
      if (res && res.status === 200) {
        history.push('/congratulations');
      }
    });
  };

  const mobile = useContext(ViewContext) === 'xs';

  let countriesArray = [];
  for (const country in countries) {
    countriesArray = countriesArray.concat({
      label: countries[country] || 'Select country',
      value: countries[country],
    });
  }

  return (
    <Form className="py-5 row justify-content-around" submit={handleSubmit}>
      <div className="col-md-6 d-flex flex-column dedicated">
        <HeaderedCard headerLabel="Your Information" containerClassName="mb-3" className="d-flex flex-column">
          <div className="row d-flex no-gutters flex-row p-4 border-bottom">
            <div className="col-md-6 col-12 pr-md-2 mb-3 mb-mt-0 d-flex flex-column flex-wrap">
              <h3>First Name</h3>
              <Input
                value={formData.first_name}
                required
                onChange={(name, value) => handleFormDataChange('first_name')(value)}
              />
            </div>
            <div className="col-md-6 col-12 mb-3 mb-mt-0 pl-md-2 d-flex flex-column">
              <h3>Last Name</h3>
              <Input
                value={formData.last_name}
                required
                onChange={(name, value) => handleFormDataChange('last_name')(value)}
              />
            </div>
            <div className="col-md-6 col-12  pr-md-2 mb-3 mb-mt-0 d-flex flex-column mt-3">
              {orgType.map(el => (
                <FormGroup check key={el.label} inline>
                  <input
                    type="radio"
                    name="type"
                    id={el.value}
                    value={el.value}
                    checked={formData.type === el.value}
                    required
                    onChange={event => {
                      handleFormDataChange('type')(event.target.value);
                    }}
                  />
                  <label htmlFor={el.value} className="m-0 ml-3">
                    {el.label}
                  </label>
                </FormGroup>
              ))}
            </div>
          </div>
          <div className="row d-flex no-gutters flex-row p-4 border-bottom">
            <div className="col-md-6 col-12  pr-md-2 mb-3 mb-mt-0 d-flex flex-column flex-wrap">
              <h3>Company Name</h3>
              <Input
                value={formData.firmenname}
                required
                onChange={(name, value) => handleFormDataChange('firmenname')(value)}
              />
            </div>
            <div className="col-md-6 col-12 pl-md-2 mb-3 mb-mt-0 d-flex flex-column">
              <h3>EU VAT</h3>
              <Input value={formData.eu_vat} onChange={(name, value) => handleFormDataChange('eu_vat')(value)} />
            </div>
          </div>
          <div className="row d-flex no-gutters flex-row p-4">
            <div className="col-md-6 col-12 pr-md-2 mb-3 mb-mt-0 d-flex flex-column flex-wrap">
              <h3>Email</h3>
              <Input
                value={billData && billData.email}
                disabled
                // onChange={(name, value) => handleFormDataChange('email')(value)}
              />
            </div>
            <div className="col-md-6 col-12 pl-md-2 mb-3 mb-mt-0 d-flex flex-column">
              <h3>Create Password</h3>
              <Input
                value={formData.password}
                required
                type="password"
                onChange={(name, value) => handleFormDataChange('password')(value)}
              />
            </div>
          </div>
        </HeaderedCard>
        <HeaderedCard headerLabel="Contact Information" containerClassName="mb-3" className="d-flex flex-column p-4">
          <FormGroup>
            <Label for="address">
              <h3>Address</h3>
            </Label>
            <Input
              type="text"
              name="address"
              id="address"
              placeholder="Enter company address"
              value={formData['address']}
              onChange={(name, value) => handleFormDataChange('address')(value)}
              required
            />
            <FormFeedback>This field is required</FormFeedback>
          </FormGroup>

          <div className="flex-group">
            <FormGroup className="mr-md-2">
              <Select
                data-cy="dedicated-contact-form-country"
                name="country"
                defaultValue={{
                  label: 'Select country',
                  value: undefined,
                }}
                value={
                  formData['country']
                    ? {
                        label: formData['country'],
                        value: formData['country'],
                      }
                    : undefined
                }
                options={countriesArray}
                onChange={val => handleFormDataChange('country')(val.value)}
              />
              <FormFeedback>This field is required</FormFeedback>
            </FormGroup>
            <FormGroup className="mr-md-2">
              <Input
                type="text"
                name="city"
                placeholder="City"
                value={formData['city']}
                onChange={(name, value) => handleFormDataChange('city')(value)}
                required
              />
              <FormFeedback>This field is required</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="postcode"
                placeholder="Postcode"
                value={formData['postcode']}
                onChange={(name, value) => handleFormDataChange('postcode')(value)}
                required
              />
              <FormFeedback>This field is required</FormFeedback>
            </FormGroup>
          </div>
          <div className="col-6 pl-2 d-flex flex-column">
            <h3>Mobile Phone (optional)</h3>
            <Input value={formData.phone} onChange={(name, value) => handleFormDataChange('phone')(value)} />
          </div>
        </HeaderedCard>
        {mobile &&
          (billData && billData.summary ? (
            <OrderSummaryCard
              formData={billData && billData.summary ? JSON.parse(billData.summary) : null}
              billingPeriod={billData && billData.summary ? JSON.parse(billData.summary).billing_frequency : null}
              email={billData.email}
              onEmailChange={() => {}}
            />
          ) : (
            <Skeleton />
          ))}
      </div>
      {!mobile &&
        (billData && billData.summary ? (
          <OrderSummaryCard
            formData={billData && billData.summary ? JSON.parse(billData.summary) : null}
            billingPeriod={billData && billData.summary ? JSON.parse(billData.summary).billing_frequency : null}
            email={billData.email}
            onEmailChange={() => {}}
          />
        ) : (
          <div className="position-fixed p-0 col-3 dedicated" style={{ top: 0, right: 0 }}>
            <Skeleton height={70} />
          </div>
        ))}
    </Form>
  );
};

export default DedicatedContactForm;
