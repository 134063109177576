import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#F25F5C"
        d="M4 16h12V4H4v12zm8 2H8v2H6v-2H3a1 1 0 01-1-1v-3H0v-2h2V8H0V6h2V3a1 1 0 011-1h3V0h2v2h4V0h2v2h3a1 1 0 011 1v3h2v2h-2v4h2v2h-2v3a1 1 0 01-1 1h-3v2h-2v-2zM6 6h8v8H6V6z"
      ></path>
    </svg>
  );
}

export default Icon;
