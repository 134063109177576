import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, FormFeedback } from 'reactstrap';
import Select from 'react-select';

import countries from '../../utils/countries';
import axios from '../../axiosInstance';
import countriesWithVat from '../Onboarding/TrialSelect/CountriesWithVat';
import { AuthContext } from '../contexts/AuthContext';

const BillingAddressModal = ({ modalIsOpen, onClose, onClickContinue, billingAddress, setBillingAddress }) => {
  const [vatInputVisible, setVatInputVisible] = useState(false);
  const { user, setUserInfo } = useContext(AuthContext);

  const handleInputChange = (key, value) => {
    setBillingAddress({ ...billingAddress, [key]: value });
  };

  useEffect(() => {
    (async () => {
      await setUserInfo();
      initBillingAddress();
    })();
  }, []);

  const initBillingAddress = () => {
    if (user && user.tenant) {
      const { address, address2, city, postcode, country, vat } = user.tenant;
      const data = { address, address2, city, postcode, country, vat };
      setBillingAddress(data);
    }
  };

  let countriesArray = [];
  for (const country in countries) {
    countriesArray = countriesArray.concat({
      label: countries[country] || 'Select country',
      value: countries[country],
    });
  }

  let continueButtonDisabled = false;
  for (const key in billingAddress) {
    if (key !== 'address2' && key !== 'vat' && !billingAddress[key]) {
      continueButtonDisabled = true;
      break;
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      toggle={() => onClose('demoOptions')}
      style={{ width: '650px' }}
      className="wider-modal"
      id="billing-address-modal"
    >
      <ModalHeader toggle={() => onClose()}>Billing Address</ModalHeader>
      <ModalBody>
        <div className="row">
          <FormGroup className="col-6">
            <label htmlFor="address-line-1">
              <span className="bold">Address Line 1 *</span>
            </label>
            <input
              value={billingAddress.address}
              onChange={e => handleInputChange('address', e.target.value)}
              type="text"
              id="address-line-1"
              className="form-control"
              required
            />
          </FormGroup>
          <FormGroup className="col-6">
            <label htmlFor="address-line-2">
              <span className="bold">Address Line 2</span>
            </label>
            <input
              value={billingAddress.address2}
              onChange={e => handleInputChange('address2', e.target.value)}
              type="text"
              id="address-line-2"
              className="form-control"
            />
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-3">
            <label htmlFor="city">
              <span className="bold">City *</span>
            </label>
            <input
              value={billingAddress.city}
              onChange={e => handleInputChange('city', e.target.value)}
              type="text"
              id="city"
              className="form-control"
              required
            />
          </FormGroup>
          <FormGroup className="col-3">
            <label htmlFor="postcode">
              <span className="bold">Postcode *</span>
            </label>
            <input
              value={billingAddress.postcode}
              onChange={e => handleInputChange('postcode', e.target.value)}
              type="text"
              id="postcode"
              className="form-control"
              required
            />
          </FormGroup>
          <FormGroup className="col-3">
            <label htmlFor="country">
              <span className="bold">Country *</span>
            </label>
            <Select
              data-cy="billing-address-modal-country"
              name="country"
              defaultValue={{
                label: 'Select country',
                value: undefined,
              }}
              value={
                billingAddress.country
                  ? {
                      label: billingAddress.country,
                      value: billingAddress.country,
                    }
                  : undefined
              }
              options={countriesArray}
              onChange={val => {
                setVatInputVisible(countriesWithVat.indexOf(val.value) !== -1);
                handleInputChange('country', val.value);
              }}
            />
            <FormFeedback>This field is required</FormFeedback>
          </FormGroup>
          <FormGroup className="col-3" style={!vatInputVisible ? { display: 'none' } : {}}>
            <label htmlFor="postcode">
              <span className="bold">VAT</span>
            </label>
            <input
              value={billingAddress.vat}
              onChange={e => handleInputChange('vat', e.target.value)}
              type="text"
              id="vat"
              className="form-control"
              required
            />
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="ml-auto"
          type="button"
          disabled={continueButtonDisabled}
          color="secondary"
          onClick={() => {
            if (vatInputVisible) {
              const { vat, country } = billingAddress;
              axios
                .post('api/user/validateVatNumber', {
                  vat,
                  country,
                })
                .then(() => {
                  onClickContinue();
                });
            } else {
              onClickContinue();
            }
          }}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BillingAddressModal;
