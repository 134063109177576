import React, { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import CreditsModal from '../BillingPlan/CreditsModal';
import WithWANUplink from '../AddDevicePage/WithWANUplink';
import BillingAddressModal from '../AddDevicePage/BillingAddressModal';
import { AuthContext } from '../contexts/AuthContext';
import { PushNotificationContext } from './PushNotificationContext';
import axios from '../../axiosInstance';

const DemoOptionsContext = React.createContext();

const DemoOptionsProvider = ({ children }) => {
  const { tenant, demoTenant, setDemoTenant } = useContext(AuthContext);
  const { pushNotification } = useContext(PushNotificationContext);
  const [total, setTotal] = useState(undefined);
  const [cloudId, setCloudId] = useState(undefined);
  const [refreshInvoices, setRefreshInvoices] = useState(false);
  const [modalOpen, setModalOpen] = useState({
    withWanUplink: false,
    billingAddress: false,
    credits: false,
  });
  const [dropInReload, setDropInReload] = useState(true);

  const [billingAddress, setBillingAddress] = useState({
    address: null,
    address2: null,
    city: null,
    postcode: null,
    country: null,
    vat: null,
  });

  const handleAddressModalOpen = () => {
    setModalOpen({
      billingAddress: true,
      credits: false,
      withWanUplink: false,
    });
  };

  const handleWithWANUplinkOpen = () => {
    setModalOpen({
      billingAddress: false,
      credits: false,
      withWanUplink: true,
    });
  };

  const handleCreditsModalOpen = () => {
    if (total) {
      setModalOpen({
        withWanUplink: false,
        billingAddress: false,
        credits: true,
      });
    } else {
      axios.get(`/api/user/${tenant}/billing/tokens/monthly`).then(res => {
        if (res && res.data !== undefined) {
          setTotal(res.data);
          setModalOpen({
            withWanUplink: false,
            billingAddress: false,
            credits: true,
          });
        }
      });
    }
  };

  const handleModalsClose = () => {
    setModalOpen({
      billingAddress: false,
      credits: false,
      withWanUplink: false,
    });
  };

  const handleCreditsButtonClicked = () => {
    axios.get('/api/user/payment/cards/count').then(res => {
      if (res && res.data) {
        if (res.data.count && !demoTenant) {
          handleCreditsModalOpen();
        } else {
          handleAddressModalOpen();
        }
      } else {
        pushNotification({
          data: {
            type: 'danger',
            name: 'Cannot get data about payment methods',
          },
          id: uuidv4(),
        });
      }
    });
  };

  const handleCreditsBuy = async amount => {
    let res;
    try {
      res = await axios.post(`/api/user/billing/tokens/buy`, {
        tokens: amount,
      });
    } catch (e) {
      console.warn(e.message);
    }
    res && handleModalsClose();

    setRefreshInvoices(!refreshInvoices);
  };

  const upgradeToFullTrial = async (creditsModalShow = true) => {
    await axios.post(`api/user/upgradeToFullTrial`, {
      ...billingAddress,
    });
    setDemoTenant(false);
    if (creditsModalShow) {
      handleCreditsModalOpen();
    }
  };

  return (
    <DemoOptionsContext.Provider
      value={{
        handleAddressModalOpen,
        handleWithWANUplinkOpen,
        handleCreditsModalOpen,
        handleModalsClose,
        handleCreditsButtonClicked,
        billingAddress,
        setBillingAddress,
        setCloudId,
        refreshInvoices,
        upgradeToFullTrial,
        dropInReload,
      }}
    >
      <>
        {modalOpen.billingAddress && (
          <BillingAddressModal
            modalIsOpen={modalOpen.billingAddress}
            billingAddress={billingAddress}
            setBillingAddress={setBillingAddress}
            onClose={handleModalsClose}
            onClickContinue={handleWithWANUplinkOpen}
          />
        )}
        {modalOpen.withWanUplink && (
          <WithWANUplink
            modalIsOpen={modalOpen.withWanUplink}
            tenant={tenant}
            onClose={() => {
              handleModalsClose();
              setTimeout(() => setDropInReload(!dropInReload), 1000);
            }}
            billingAddress={billingAddress}
            onSubmit={async () => {
              await upgradeToFullTrial();
              setTimeout(() => setDropInReload(!dropInReload), 1000);
            }}
            onClickBack={() => {
              handleAddressModalOpen();
              setTimeout(() => setDropInReload(!dropInReload), 1000);
            }}
          />
        )}
        {modalOpen.credits && (
          <CreditsModal
            open={modalOpen.credits}
            onCreditsBuy={handleCreditsBuy}
            onClose={handleModalsClose}
            total={total}
          />
        )}
      </>
      {children}
    </DemoOptionsContext.Provider>
  );
};
const DemoOptionsConsumer = DemoOptionsContext.Consumer;

export { DemoOptionsProvider, DemoOptionsConsumer };
export default DemoOptionsContext;
