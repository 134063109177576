import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, FormGroup, FormFeedback } from 'reactstrap';
import Select from 'react-select';

import { AuthContext } from '../../contexts/AuthContext';
import PaymentMethodSelectCard from '../../Payments/PaymentMethodSelectCard';
import TickWifiSvg from '../../../images/tick-wifi.svg';
import NoWifiSvg from '../../../images/no-wifi.svg';
import GreenTick from '../../../images/green-tick.svg';
import BluringOverlay from '../../UI/BluringOverlay';
import axios from '../../../axiosInstance';

const TrialSelect = ({
  trialType,
  setTrialType,
  billingFormOpen,
  setBillingFormOpen,
  billingAddress,
  setBillingAddress,
  setCards,
  trialSelectRef,
}) => {
  const { user, setDemoTenant } = useContext(AuthContext);
  const [customerId, setCustomerId] = useState(user && user.tenant ? user.tenant.customer_id : null);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(false);

  const [blurredFormPart, setBlurredFormPart] = useState('billing-method');
  const [countriesList, setCountriesList] = useState([]);

  const fetchCountries = () => {
    axios.get(`/api/user/countries-list`).then(res => {
      if (res && res.data) {
        const countriesArray = res.data.map(item => ({
          label: item.country,
          value: item.country,
        }));
        countriesArray.unshift({ label: 'Select country', value: '' });

        setCountriesList(countriesArray);
      }
    });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleBillingAddressChange = (key, value) => {
    setBillingAddress({ ...billingAddress, [key]: value });
  };

  const fetchCards = () => {
    if (customerId) {
      axios.get(`/api/user/payment/customer`).then(res => {
        if (res.data && res.data.paymentMethods && res.data.paymentMethods.length) {
          setCards(res.data.paymentMethods);
          setBillingFormOpen(true);
          setBlurredFormPart(undefined);
          setTrialType('full');
        }
      });
    }
  };

  const handlePaymentMethodSelected = () => {
    const payload = { ...billingAddress };
    axios
      .post('api/user/upgradeToFullTrial', payload)
      .then(() => {
        if (!customerId) {
          axios.get(`api/user/tenant/${user.tenant.tenant_identifier}`).then(res => {
            if (res && res.data && res.data.customer_id) {
              setCustomerId(res.data.customer_id);
              fetchCards();
              setDemoTenant(false);
            }
          });
        } else {
          fetchCards();
        }
      })
      .catch(e => console.warn(e.message));
  };

  useEffect(() => {
    if (paymentMethodSelected) {
      handlePaymentMethodSelected();
    }
  }, [paymentMethodSelected]);

  useEffect(fetchCards, [customerId]);

  let continueButtonDisabled = false;
  for (const key in billingAddress) {
    if (key !== 'address2' && key !== 'vat' && !billingAddress[key]) {
      continueButtonDisabled = true;
      break;
    }
  }

  return !billingFormOpen ? (
    <div ref={trialSelectRef} className="row onboarding-row">
      <div className="col-12">
        <p className="text-center font-size-xl font-weight-bold">Select trial type</p>
      </div>
      <div className="col-6">
        <Card className="onboarding-trial-select-card">
          <CardHeader></CardHeader>
          <CardBody>
            <div className="onboarding-wifi-div onboarding-tick-wifi-div">
              <img src={TickWifiSvg} alt="TickWifiSvg" />
            </div>
            <h3>With WAN Uplink & Credit card</h3>
            <div style={{ width: '80%' }}>
              <p>
                Connect your card (for security reasons) and get 50 CHF for your trial for FREE!
                <b> No charges required.</b>
              </p>
            </div>

            <button
              onClick={() => {
                setTrialType(undefined);
                setBillingFormOpen(true);
              }}
              data-cy="full-trial-btn"
              value="full"
              type="button"
              className="btn btn-light mt-3"
            >
              Select Full Trial
            </button>
          </CardBody>
        </Card>
      </div>
      <div className="col-6">
        <Card className="onboarding-trial-select-card">
          <BluringOverlay
            enabled={trialType === 'limited'}
            forwardComponent={
              <div className="onboarding-overlay-forward-component">
                <div className="onboarding-overlay-forward-component-sub">
                  <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                    <img src={GreenTick} alt="green-tick" /> Limited Trial Selected
                  </span>
                </div>
              </div>
            }
          >
            <CardHeader />
            <CardBody>
              <div className="onboarding-wifi-div onboarding-no-wifi-div">
                <img src={NoWifiSvg} alt="TickWifiSvg" />
              </div>
              <h3>No WAN Uplink, no Credit card required</h3>
              <p>
                Try out the service and upgrade when you need.
                <br />
                <b>Trial with limitations.</b>
              </p>
              <button
                data-cy="limited-trial-btn"
                onClick={() => setTrialType('limited')}
                value="limited"
                type="button"
                className="btn btn-light mt-3"
              >
                Select Limited Trial
              </button>
            </CardBody>
          </BluringOverlay>
        </Card>
      </div>
    </div>
  ) : (
    <div ref={trialSelectRef} className="row onboarding-row">
      <div className="col-12">
        <p className="text-center font-size-xl font-weight-bold">Attach payment method</p>
      </div>
      <div className="col-12">
        <Card className="onboarding-billing-card">
          <div
            style={{
              background: 'linear-gradient(270deg, #3da99c 0%, #2fc48a 48.94%)',
              width: '100%',
              height: 8,
            }}
          ></div>
          <BluringOverlay
            enabled={trialType === 'full'}
            forwardComponent={
              <div className="onboarding-overlay-forward-component">
                <div className="onboarding-overlay-forward-component-sub">
                  <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                    <img src={GreenTick} alt="green-tick" /> Successfully attached!
                  </span>
                </div>
              </div>
            }
          >
            <CardBody>
              <div className="row">
                <div className="col-6" style={{ borderRight: '1px solid #e5e5e5' }}>
                  <BluringOverlay enabled={blurredFormPart === 'billing-address'}>
                    <div className="row">
                      <FormGroup className="col-6">
                        <label htmlFor="address-line-1">
                          <span className="bold">Address Line 1 *</span>
                        </label>
                        <input
                          data-cy="billing-address-address-input"
                          value={billingAddress.address}
                          onChange={e => handleBillingAddressChange('address', e.target.value)}
                          type="text"
                          id="address-line-1"
                          className="form-control"
                        />
                      </FormGroup>
                      <FormGroup className="col-6">
                        <label htmlFor="address-line-2">
                          <span className="bold">Address Line 2</span>
                        </label>
                        <input
                          data-cy="billing-address-address2-input"
                          value={billingAddress.address2}
                          onChange={e => handleBillingAddressChange('address2', e.target.value)}
                          type="text"
                          id="address-line-2"
                          className="form-control"
                        />
                      </FormGroup>
                      <FormGroup className="col-6">
                        <label htmlFor="postcode">
                          <span className="bold">Postcode *</span>
                        </label>
                        <input
                          data-cy="billing-address-postcode-input"
                          value={billingAddress.postcode}
                          onChange={e => handleBillingAddressChange('postcode', e.target.value)}
                          type="text"
                          id="postcode"
                          className="form-control"
                        />
                      </FormGroup>
                      <FormGroup className="col-6">
                        <label htmlFor="city">
                          <span className="bold">City *</span>
                        </label>
                        <input
                          data-cy="billing-address-city-input"
                          value={billingAddress.city}
                          onChange={e => handleBillingAddressChange('city', e.target.value)}
                          type="text"
                          id="city"
                          className="form-control"
                        />
                      </FormGroup>
                      <FormGroup className="col-6">
                        <label htmlFor="country">
                          <span className="bold">Country *</span>
                        </label>
                        <Select
                          data-cy="billing-address-country-select"
                          name="country"
                          defaultValue={{
                            label: 'Select country',
                            value: undefined,
                          }}
                          value={
                            billingAddress.country
                              ? {
                                  label: billingAddress.country,
                                  value: billingAddress.country,
                                }
                              : undefined
                          }
                          options={countriesList}
                          onChange={val => handleBillingAddressChange('country', val.value)}
                        />
                        <FormFeedback>This field is required</FormFeedback>
                      </FormGroup>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          data-cy="close-billing-form-btn"
                          type="button"
                          className="btn btn-light w-100"
                          onClick={() => setBillingFormOpen(false)}
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          data-cy="open-payment-section-btn"
                          type="button"
                          className="btn btn-blue-dark w-100"
                          disabled={continueButtonDisabled}
                          onClick={() => {
                            setBlurredFormPart('billing-address');
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </BluringOverlay>
                </div>
                <div className="col-6">
                  <BluringOverlay enabled={blurredFormPart === 'billing-method'}>
                    {trialType !== 'full' ? (
                      <>
                        <PaymentMethodSelectCard
                          boxClass="box_B border-0"
                          onPaymentMethodSelected={() => setPaymentMethodSelected(true)}
                        />
                        <button
                          data-cy="close-payment-section-btn"
                          type="button"
                          className="btn btn-light mt-3 w-100"
                          onClick={() => setBlurredFormPart('billing-method')}
                        >
                          Back
                        </button>
                      </>
                    ) : null}
                  </BluringOverlay>
                </div>
              </div>
            </CardBody>
          </BluringOverlay>
        </Card>
      </div>
    </div>
  );
};

TrialSelect.propTypes = {
  trialType: PropTypes.string,
  setTrialType: PropTypes.func,
  billingFormOpen: PropTypes.bool,
  setBillingFormOpen: PropTypes.func,
  billingAddress: PropTypes.object,
  setBillingAddress: PropTypes.func,
  setCards: PropTypes.func,
  trialSelectRef: PropTypes.object,
};

export default TrialSelect;
