import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, ButtonGroup } from 'reactstrap';

const FormTabs = ({ options, value, onChange, className }) => {
  return (
    <ButtonGroup className={className}>
      {options.map(tab => (
        <Button
          data-cy={tab.dataCy ? tab.dataCy : `form-tab-${tab.value}`}
          key={tab.value}
          className={clsx('btn', tab.value === value ? 'btn-blue-filled' : 'btn-secondary')}
          onClick={() => onChange(tab.value)}
          type="button"
        >
          {tab.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

FormTabs.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default FormTabs;
