import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import PaymentImage from '../images/mirage-message-sent.png';
import { ViewContext } from './contexts/ViewContext';

const CheckYourEmail = () => {
  const mobile = useContext(ViewContext) === 'xs';
  return (
    <div className={clsx('not-found-page d-flex dedicated', mobile && 'flex-column')}>
      <div className="col-md-6 d-flex align-items-center justify-content-center mt-auto mt-md-5 mt-md-0">
        <img className="img-fluid w-50" src={PaymentImage} alt="Not found" />
      </div>
      <div className="col-md-6 not-found-page-right-side d-flex flex-column justify-content-center mt-5 mt-md-0 mb-auto mb-md-0">
        <h1 className="h1 font-weight-bold px-0 mx-0">Your Dedicated Order: Check your Inbox</h1>
        <p className="h2">Please, proceed with your order  by the link we’ve sent to your E-Mail address</p>
        <Button color="primary" className="mt-5" onClick={() => (window.location.href = 'https://xelon.ch')}>
          Go Home
        </Button>
      </div>
    </div>
  );
};

export default withRouter(CheckYourEmail);
