import CpuIcon from '../../images/Config/cpu';
import RamIcon from '../../images/Config/ram';
import SwapIcon from '../../images/Config/swap';
import HddIcon from '../../images/Config/hdd';

export const rangedInputOptions = {
  cpucores: {
    icon: CpuIcon,
    background: 'rgba(242, 95, 92, 0.1)',
    borderColor: 'rgba(242, 95, 92, 0.3)',
    allowed: 1,
    step: 1,
    max: 16,
    min: 1,
    title: 'CPU Cores',
  },
  memory: {
    icon: RamIcon,
    background: 'rgba(78, 183, 154, 0.1)',
    borderColor: 'rgba(78, 183, 154, 0.3)',
    allowed: 1,
    step: 1,
    max: 128,
    min: 1,
    title: 'RAM, Gb',
  },
  disksize: {
    icon: HddIcon,
    background: 'rgba(70, 100, 233, 0.1)',
    borderColor: 'rgba(70, 100, 233, 0.3)',
    allowed: 1,
    step: 5,
    max: 2048,
    min: 5,
    title: 'Disk Size, Gb',
  },
  swapdisksize: {
    icon: SwapIcon,
    background: 'rgba(255, 193, 7, 0.1)',
    borderColor: 'rgba(255, 193, 7, 0.3)',
    allowed: 1,
    step: 1,
    max: 20,
    min: 1,
    title: 'SWAP, Gb',
  },
};

export const rangedInputOptionsDemo = {
  cpucores: {
    icon: CpuIcon,
    allowed: 1,
    title: 'CPU Cores',
    step: 1,
    max: 2,
    min: 1,
  },
  memory: {
    icon: RamIcon,
    allowed: 1,
    title: 'RAM, Gb',
    step: 1,
    max: 4,
    min: 1,
  },
  disksize: {
    icon: HddIcon,
    allowed: 1,
    step: 1,
    max: 100,
    min: 5,
    title: 'Disk Size, Gb',
  },
  swapdisksize: {
    icon: SwapIcon,
    allowed: 1,
    step: 1,
    max: 20,
    min: 1,
    title: 'SWAP, Gb',
  },
};
