import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';

import axios from '../../../axiosInstance';
import { handleDateConversion } from '../../../utils/timeConversion';
import Form from '../../UI/Form';
import { convertDataForSkeleton } from '../../UI/CustomizedSkeletonComponent';
import NoEntitiesSvg from '../../../images/no-entities-exist.png';
import DeleteConfirmationModal from '../../shared/DeleteConfirmationModal';

const SSHKeysModal = ({ toggleModal, isOpen }) => {
  const [existingKeys, setExistingKeys] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [modalType, setModalType] = useState(false);
  const [name, setName] = useState('');
  const [fingerPrint, setFingerPrint] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteSshId, setDeleteSshId] = useState(undefined);

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerClasses: 'pb-4',
      classes: 'my-account-ssh-table-cell sm',
    },
    {
      dataField: 'created_at',
      text: 'Date',
      sort: true,
      headerClasses: 'pb-4',
      classes: 'my-account-ssh-table-cell lg',
    },
    {
      dataField: 'fingerprint',
      text: 'Fingerprint',
      sort: true,
      headerClasses: 'pb-4',
      headerStyle: () => {
        return { width: '35%', textAlign: 'center' };
      },
      classes: 'my-account-ssh-table-cell lg',
    },
    {
      dataField: 'id',
      sort: true,
      classes: 'my-account-ssh-table-cell sm',
      text: (
        <button
          data-cy="ssh-modal-add-btn"
          type="button"
          className="btn btn-primary w-100"
          onClick={() => setModalType('add')}
        >
          Create New
        </button>
      ),
      formatter: id => (
        <button
          data-cy={`ssh-modal-delete-${id}-btn`}
          type="button"
          className="btn btn-pink px-3 py-2"
          onClick={() => {
            setDeleteSshId(id);
            setDeleteModalVisible(true);
          }}
        >
          <i className="fas fa-trash-alt" />
        </button>
      ),
    },
  ];

  const fetchExistingKeys = () => {
    axios.get('api/user/sshKeys').then(res => {
      if (res && res.data) {
        if (res.data.length) {
          const keyCreationDateConverted = handleDateConversion(res.data);
          setExistingKeys(keyCreationDateConverted);
          setModalType('list');
        } else {
          setModalType('empty');
        }
      }
    });
  };

  const handleKeyAdd = () => {
    const payload = { name, ssh_key: fingerPrint };
    axios
      .post('/api/user/vmlist/ssh/add', payload)
      .then(res => {
        if (res && res.data) {
          const keyCreationDateConverted = handleDateConversion([res.data]);
          setExistingKeys([...existingKeys, ...keyCreationDateConverted]);
          setName('');
          setFingerPrint('');
          setModalType('list');
          setRefresh(!refresh);
          fetchExistingKeys();
        }
      })
      .catch(e => console.warn(e.message));
  };

  const convertedSkeletonColumns = convertDataForSkeleton(columns, 3);

  const getModalContent = () => {
    switch (modalType) {
      case 'list': {
        return <BootstrapTable keyField="id" data={existingKeys} bordered={false} columns={columns} />;
      }
      case 'add': {
        return (
          <>
            <FormGroup>
              <Label for="name">Display Name</Label>
              <Input type="text" name="name" id="name" value={name} onChange={e => setName(e.target.value)} required />
            </FormGroup>
            <FormGroup>
              <Label for="sshkey">Key</Label>
              <Input
                type="textarea"
                name="ssh_key"
                id="sshkey"
                value={fingerPrint}
                onChange={e => setFingerPrint(e.target.value)}
                required
              />
            </FormGroup>
          </>
        );
      }
      case 'empty': {
        return (
          <div className="d-flex flex-row justify-content-center py-5 px-3">
            <img src={NoEntitiesSvg} className="width-px-150 width-md-px-180 mr-5" alt="nothing-was-added" />
            <div className="h-100 my-auto">
              <p className="font-weight-bold" style={{ fontSize: 20 }}>
                No SSH Keys were added
              </p>
              <button
                data-cy="ssh-modal-create-new-btn"
                type="button"
                className="btn btn-primary"
                onClick={() => setModalType('add')}
              >
                Create new
              </button>
            </div>
          </div>
        );
      }
      default: {
        return (
          <BootstrapTable
            keyField="id"
            columns={convertedSkeletonColumns[0]}
            data={convertedSkeletonColumns[1]}
            wrapperClasses="text-left mt"
          />
        );
      }
    }
  };

  const getModalFooter = () => {
    switch (modalType) {
      case 'add': {
        return (
          <div style={{ width: '100%' }}>
            <button type="submit" data-cy="ssh-modal-submit-btn" className="btn btn-blue-filled float-right">
              Finish and Save
            </button>
            <button
              type="button"
              data-cy="ssh-modal-cancel-btn"
              className="btn btn-light float-right mr-3"
              onClick={() => {
                setName('');
                setFingerPrint('');
                setRefresh(!refresh);
                setModalType(existingKeys.length ? 'list' : 'empty');
              }}
            >
              Cancel
            </button>
          </div>
        );
      }
      default: {
        return (
          <button
            style={{ float: 'right' }}
            type="button"
            data-cy="ssh-modal-close-btn"
            className="btn btn-blue-filled ml-auto"
            onClick={() => toggleModal('sshKeys')}
          >
            Close
          </button>
        );
      }
    }
  };

  const toggleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible);
  };

  useEffect(() => {
    fetchExistingKeys();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} id="ssh-keys-modal">
        <Form submit={handleKeyAdd} key={refresh}>
          <ModalHeader toggle={() => toggleModal('passwordChange')}>
            {modalType === 'add' ? 'Create new SSH Key' : 'SSH Keys'}
          </ModalHeader>
          <ModalBody className="overflow-auto">{getModalContent()}</ModalBody>
          <ModalFooter style={{ border: '1px solid #E5E5E5', padding: '1.5rem' }}>{getModalFooter()}</ModalFooter>
        </Form>
      </Modal>
      <DeleteConfirmationModal
        modalOpen={deleteModalVisible}
        toggleModalItem={toggleDeleteModal}
        url={`/api/user/vmlist/ssh/${deleteSshId}/delete`}
        entity="SSH key"
        onDeleteFinished={fetchExistingKeys}
      />
    </>
  );
};

export default SSHKeysModal;
