import React, { useState } from 'react';
import { Button } from 'reactstrap';

import ConfirmationModal from './ConfimationModal';
import clsx from 'clsx';

const ButtonWithConfirmationModal = ({
  onClick,
  message,
  modalCancelText,
  modalConfirmText,
  label,
  children,
  eventHandler,
  dataCy,
  passwordConfirmation,
  allowAction,
  isWithStyle = true,
  className = '',
  ...props
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {isWithStyle ? (
        <Button
          data-cy={dataCy}
          disabled={allowAction || false}
          className={className}
          onClick={e => {
            props.stopPropagation && e.stopPropagation();
            eventHandler && eventHandler(e);
            setOpen(true);
          }}
          {...props}
        >
          {children}
        </Button>
      ) : (
        <div
          data-cy={dataCy}
          onClick={e => {
            props.stopPropagation && e.stopPropagation();
            eventHandler && eventHandler(e);
            setOpen(true);
          }}
        >
          {children}
        </div>
      )}
      <ConfirmationModal
        toggleModal={() => setOpen(false)}
        onSubmit={password => onClick(password)}
        open={open}
        label={label}
        message={message}
        dataCy={dataCy}
        modalCancelText={modalCancelText}
        modalConfirmText={modalConfirmText}
        passwordConfirmation={passwordConfirmation || false}
      />
    </>
  );
};

export default ButtonWithConfirmationModal;
