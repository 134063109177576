import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

const NewSearchInput = ({
  onChange,
  placeholder,
  value,
  className,
  foldable,
  changeOnSubmit,
  changeOnBlur,
  dataCy,
  style,
  isFocus = false,
}) => {
  const inputEl = useRef(null);
  const [folded, setFolded] = useState(true);
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    if (value === '' && document.activeElement !== inputEl.current) {
      setFolded(true);
    }
  }, [value]);

  useEffect(() => {
    if (isFocus) {
      inputEl.current.focus();
    }
  }, [isFocus]);

  const handleBlur = () => {
    if (changeOnBlur) {
      onChange(value || inputValue);
    } else if (value === '') setFolded(true);
  };
  return (
    <div className={clsx('position-relative h-100', className)}>
      {foldable && folded ? (
        <button className="btn btn-white h-100" onClick={() => setFolded(false)}>
          <i className="fa fa-search" style={{ right: '18px', top: '35%', height: '13px' }} />
        </button>
      ) : (
        <>
          <input
            className="form-control form-control__search"
            ref={inputEl}
            type="text"
            autoComplete="off"
            placeholder={placeholder}
            value={changeOnSubmit ? inputValue : value}
            onChange={event => (changeOnSubmit ? setInputValue(event.target.value) : onChange(event.target.value))}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                onChange(e.target.value);
              }
            }}
            onBlur={() => handleBlur()}
            data-cy={`search-input-${dataCy}`}
            style={style}
          />
          <i
            className="fa fa-search position-absolute my-auto"
            style={{
              right: '10px',
              top: '35%',
              height: '7px',
              color: '#999',
            }}
          />
        </>
      )}
    </div>
  );
};

NewSearchInput.defaultProps = {
  foldable: false,
  changeOnSubmit: false,
};

export default NewSearchInput;
