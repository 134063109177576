export default class ValidatedFields {
  /**
   * ValidatedFields constructor.
   * @param fields
   */
  constructor(fields) {
    this.fields = fields;
    this.validatedFields = [];
  }

  /**
   * Determine whether the given field contains sussess validation.
   * @param field
   * @returns {boolean}
   */
  has(field) {
    return this.validatedFields.indexOf(field) >= 0;
  }

  /**
   * Clear the field validation.
   * @param field
   */
  clear(field) {
    const index = this.validatedFields.indexOf(field);
    if (index !== -1) {
      this.validatedFields.splice(index, 1);
    }
  }

  /**
   * Clear the field validation where the field name contains the given substring.
   * @param field
   */
  clearSubstring(field) {
    this.validatedFields = this.validatedFields.filter(validatedField => !validatedField.includes(field));
  }

  /**
   * Reset validated fields property
   */
  reset() {
    this.validatedFields = [];
  }

  /**
   * Set validated fields property
   */
  setValidatedFields(errorFields) {
    const validatedFields = [];
    this.fields.forEach(field => {
      const index = errorFields.indexOf(field);
      if (index === -1) {
        validatedFields.push(field);
      }
    });

    this.validatedFields = validatedFields;
  }
}
