import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Dropdown, ButtonGroup, DropdownMenu, DropdownToggle, Popover, PopoverBody } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

import { ViewContext } from '../../contexts/ViewContext';

const Tabs = ({ options, type, value, className }) => {
  const [dropdownOpen, toggleDropdown] = useState(false);
  const popoversObject = {};
  options.map(el => (el.id ? (popoversObject[el.id] = false) : null));
  const [popoverOpen, setPopoverOpen] = useState(popoversObject);

  const screenSize = useContext(ViewContext);
  return screenSize === 'lg' || screenSize === 'xl' ? (
    <div className={clsx('tabs-container', type === 'sm' && 'border-0', className)}>
      {options ? (
        <ButtonGroup className={clsx('d-flex flex-row w-100', 'tab-container-' + type)}>
          {options.map(option => {
            let dataCy;
            if (option.dataCy) {
              dataCy = option.dataCy;
            } else if (typeof option.label === 'string') {
              dataCy = option.label;
            } else {
              dataCy = option.value;
            }
            return (
              <button
                data-cy={dataCy}
                key={option.value}
                type="button"
                onClick={() => option.onClick(option.value)}
                className={clsx(option.value === value ? 'tab-selected' : 'tab', option.className)}
              >
                {option.label}
                {option.infoMessage && (
                  <span>
                    <i id={`popover-${option.id}`} className="fa fa-info-circle font-gray ml-2" />
                    <Popover
                      placement="top"
                      modifiers={{
                        // preventOverflow: {
                        //   enabled: false,
                        // },
                        flip: {
                          enabled: false,
                        },
                      }}
                      isOpen={popoverOpen[option.id]}
                      trigger="hover"
                      target={`popover-${option.id}`}
                      toggle={() => {
                        const isToggled = popoverOpen[option.id];
                        const popoverOpenUpdated = {
                          ...popoverOpen,
                          [option.id]: !isToggled,
                        };
                        setPopoverOpen(popoverOpenUpdated);
                      }}
                    >
                      <PopoverBody>{option.infoMessage}</PopoverBody>
                    </Popover>
                  </span>
                )}
              </button>
            );
          })}
        </ButtonGroup>
      ) : (
        <Skeleton />
      )}
    </div>
  ) : (
    <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropdown(!dropdownOpen)}>
      <DropdownToggle className="btn-white w-100 custom-dropdown select" caret>
        {options.find(el => el.value === value) && options.find(el => el.value === value).label}
      </DropdownToggle>
      <DropdownMenu className="w-100 dropdown-links">
        {options.map(option => (
          <button
            data-cy={option.label}
            key={option.value}
            type="button"
            className="btn btn-outline-primary w-100 border-0"
            onClick={() => {
              option.onClick(option.value);
              toggleDropdown(false);
            }}
          >
            {option.label}
          </button>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      onClick: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  type: PropTypes.oneOf(['lg', 'md', 'sm']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Tabs;
