import React from 'react';
import PropTypes from 'prop-types';

import StepCard from '../../AddDevicePage/StepCard';
import NewRangedInput from '../../UI/NewRangedInput';

const HardwareSettings = ({ sideTitle, results, onChange, rangedInputOptions }) => (
  <div className="row onboarding-row" style={{ paddingBottom: 200 }}>
    <div className="col-12">
      <StepCard title="Hardware" sideTitle={sideTitle}>
        <div className="ranged-inputs-wrp mt-2 mx-4">
          {Object.keys(rangedInputOptions).map(el =>
            rangedInputOptions[el].allowed ? (
              <div className="d-flex flex-column justify-content-center align-items-center" key={el}>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    borderRadius: '50%',
                    border: `1px solid ${rangedInputOptions[el].borderColor}`,
                    width: '42px',
                    height: '42px',
                    background: rangedInputOptions[el].background,
                  }}
                >
                  {rangedInputOptions[el].icon()}
                </div>
                <NewRangedInput
                  key={el}
                  name={el}
                  title={rangedInputOptions[el].title || 'loading'}
                  value={results[el] || rangedInputOptions[el]['min']}
                  onChange={value => onChange(el)(value)}
                  step={rangedInputOptions[el].step}
                  max={rangedInputOptions[el].max}
                  min={rangedInputOptions[el].min}
                  required={true}
                />
              </div>
            ) : null
          )}
        </div>
      </StepCard>
    </div>
  </div>
);

HardwareSettings.propTypes = {
  sideTitle: PropTypes.string,
  results: PropTypes.object,
  onChange: PropTypes.func,
  rangedInputOptions: PropTypes.object,
};

export default HardwareSettings;
