import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="12"
      fill="none"
      viewBox="0 0 20 12"
    >
      <path
        fill="#4EB79A"
        d="M20 2.092V1a1 1 0 00-1-1H1a1 1 0 00-1 1v1.092c.581.206 1 .756 1 1.408 0 .652-.419 1.202-1 1.408V8h20V4.908c-.581-.206-1-.756-1-1.408 0-.652.419-1.202 1-1.408zM7 6H5V2h2v4zm4 0H9V2h2v4zm4 0h-2V2h2v4zM0 12h2v-.833a.5.5 0 111 0V12h4v-.833a.5.5 0 111 0V12h4v-.833a.5.5 0 111 0V12h4v-.833a.5.5 0 111 0V12h2V9H0v3z"
      ></path>
    </svg>
  );
}

export default Icon;
