import React, { useState, useEffect } from 'react';

import * as globalVars from '../helpers/variables';

const ViewContext = React.createContext();

const ViewProvider = props => {
  const [screenSize, setScreenSize] = useState('lg');

  useEffect(() => {
    screenSizeChangedHandler();
    window.addEventListener('resize', screenSizeChangedHandler);
    return () => {
      window.removeEventListener('resize', screenSizeChangedHandler);
    };
  }, []);

  const screenSizeChangedHandler = () => {
    if (window.matchMedia(globalVars.xlScreenSize).matches) {
      setScreenSize('xl');
    } else if (window.matchMedia(globalVars.lgScreenSize).matches) {
      setScreenSize('lg');
    } else if (window.matchMedia(globalVars.mdScreenSize).matches) {
      setScreenSize('md');
    } else {
      setScreenSize('xs');
    }
  };

  return <ViewContext.Provider value={screenSize}>{props.children}</ViewContext.Provider>;
};

const ViewConsumer = ViewContext.Consumer;

export { ViewConsumer, ViewContext };
export default ViewProvider;
