import React, { useContext } from 'react';
import { FormFeedback } from 'reactstrap';
import clsx from 'clsx';

import ServerCard from './ServerCard';
import { cpuOptions } from './initData';
import CpuIcon from '../../images/cpu.svg';
import HeaderedCard from '../UI/HeaderedCard';
import { ViewContext } from '../contexts/ViewContext';

const CpuCards = ({ onCpuChanges, currentCpu, billingPeriod, error }) => {
  const view = useContext(ViewContext);
  return (
    <HeaderedCard
      className="d-flex flex-row justify-content-between flex-wrap p-4"
      containerClassName="mb-3"
      headerLabel="CPU Options"
    >
      {[cpuOptions[0], undefined, ...cpuOptions.slice(1)].map((cpu, index) =>
        cpu ? (
          <div className={clsx('col-12 col-md-6 m-0 p-0', index % 2 === 0 ? 'pr-md-3' : 'pl-md-3')} key={cpu.label}>
            <ServerCard
              className="p-0 w-100"
              value={cpu.label}
              onChange={value => {
                const chosenCpu = cpuOptions.find(el => el.label === value);
                onCpuChanges({ label: chosenCpu.label, value: chosenCpu });
              }}
              currentValue={currentCpu && currentCpu.label}
              config={cpu.label}
              cost={cpu.price * billingPeriod.value.month}
              billingPeriod={billingPeriod.value.adverb}
              icon={
                cpu.dual ? (
                  <>
                    <img src={CpuIcon} style={{ height: 40 }} alt="cpu" />
                    <img src={CpuIcon} style={{ height: 40 }} alt="cpu" />
                  </>
                ) : (
                  <img src={CpuIcon} alt="cpu" />
                )
              }
            />
          </div>
        ) : (
          view !== 'sm' && <div className={clsx(' col-6 m-0 p-0', 'pr-3')} />
        )
      )}
      <FormFeedback className="d-block input__danger-message">{error}</FormFeedback>
    </HeaderedCard>
  );
};

export default CpuCards;
