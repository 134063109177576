import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import NewSearchInput from './NewSearchInput';

const SearchInput = ({
  className,
  onSearch,
  changeOnSubmit,
  changeOnBlur,
  placeholder,
  dataCy,
  style,
  value,
  isFocus = false,
}) => (
  <NewSearchInput
    className={clsx(className || 'mr-auto')}
    onChange={onSearch}
    changeOnSubmit={changeOnSubmit}
    changeOnBlur={changeOnBlur}
    placeholder={placeholder}
    dataCy={dataCy}
    style={style}
    value={value}
    isFocus={isFocus}
  />
);

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  changeOnSubmit: PropTypes.func,
};

export default SearchInput;
