import React from 'react';
import { Card } from 'reactstrap';
import clsx from 'clsx';

const CardWithHeader = ({ containerClassName, className, header, children, cockpit, headerLeft }) => {
  return (
    <Card className={clsx('p-0', containerClassName)}>
      {header && (
        <h3
          className={clsx(
            'bg-grey p-3 d-flex align-items-center border-bottom mb-0',
            cockpit && 'cockpit',
            headerLeft ? '' : 'justify-content-center'
          )}
        >
          {header}
        </h3>
      )}
      <div className={className}>{children}</div>
    </Card>
  );
};

export default CardWithHeader;
