import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

const TotpTwoFactorAuth = () => {
  return (
    <Card className="login">
      <CardBody>
        <p>
          If you no longer have access to your device, contact your organization admin to have your 2FA settings reset.
          They can do it by going to My Organization > Users > Edit > Reset TOTP 2FA.
        </p>
        <Link className="btn btn-link p-0" to="/login">
          Back to login
        </Link>
      </CardBody>
    </Card>
  );
};

export default TotpTwoFactorAuth;
