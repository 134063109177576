import React, { useContext } from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';
import clsx from 'clsx';

import HeaderedCard from '../UI/HeaderedCard';
import Select from '../UI/SelectRequired';
import NewRangedInput from '../UI/NewRangedInput';
import { class1Options, burstableOptions, uplinkOptions, uplinksOptions } from './initData';
import { ViewContext } from '../contexts/ViewContext';

const NetworkCard = ({
  ips,
  uplink,
  burstable,
  traffic,
  uplinkAmount,
  onIpsChange,
  onUplinkChange,
  onBurstableChange,
  onTrafficChange,
  onUplinkAmountChange,
  errors,
}) => {
  const isBurstable = uplink && uplink.label === 'Burstable (95th % CDR)';
  const view = useContext(ViewContext);
  return (
    <HeaderedCard className="d-flex flex-column py-4" containerClassName="mb-3" headerLabel="Network & Uplink">
      <div
        className={clsx(
          'row no-gutters border-bottom ',
          view === 'xs' ? 'flex-column-reverse align-items-center' : 'flex-row'
        )}
      >
        <NewRangedInput
          onChange={value => {
            const ipsObject = { label: value + ' IPs', value };
            onIpsChange(ipsObject);
          }}
          value={ips && ips.value}
          step={1}
          min={1}
          title="Number of IP`s"
          name="number_of_ips"
          max={3}
          classes="col-md-3 pt-0 pb-4"
        />
        <div className="d-flex flex-column col-md-9 pb-3">
          <h3 className="text-center text-md-left">IP Addresses</h3>
          {process.env.REACT_APP_WHITELABELING !== 'true' && (
            <div>
              <div className="text-center text-md-left">Please contact our sales team (sales@xelon.ch)</div>
              <div className="text-center text-md-left">if you need more than 3 IP's</div>
            </div>
          )}
        </div>
        {errors.first('ips') ? (
          <FormFeedback className="d-block input__danger-message">{errors.first('ips')}</FormFeedback>
        ) : null}
      </div>
      <div className="row no-gutters flex-row p-4">
        <div className="d-flex flex-column col-md-6 pr-md-2">
          <h3>Uplink</h3>
          <Select required value={uplink} onChange={onUplinkChange} options={uplinkOptions} />
        </div>
        {errors.first('uplink') ? (
          <FormFeedback className="d-block input__danger-message">{errors.first('uplink')}</FormFeedback>
        ) : null}

        <div className="d-flex flex-column col-md-6 pl-md-2">
          <h3>{isBurstable ? 'Burstable (95th % CDR)' : 'Fair use Traffic Limit'}</h3>
          {isBurstable ? (
            <div>
              <Select
                value={burstable}
                required
                key={uplink.label}
                options={burstableOptions.map(el => ({
                  label: el.label,
                  value: el,
                }))}
                onChange={onBurstableChange}
              />
              <FormFeedback className="d-block input__danger-message">{errors.first('burstable')}</FormFeedback>
            </div>
          ) : (
            <div>
              <Select
                value={traffic}
                required
                key={uplink.label}
                options={class1Options.map(el => ({
                  label: el.label,
                  value: el,
                }))}
                onChange={onTrafficChange}
              />
              <FormFeedback className="d-block input__danger-message">{errors.first('class_1_traffic')}</FormFeedback>
            </div>
          )}
        </div>
        <div className="d-flex flex-column mt-3">
          {uplinksOptions.map(el => (
            <FormGroup check key={el.label} inline>
              <input
                type="radio"
                name="type"
                id={el.label}
                value={el.label}
                checked={uplinkAmount && uplinkAmount.label === el.label}
                required
                onChange={event => {
                  const currentUplinks = uplinksOptions.find(el => el.label === event.target.value);
                  onUplinkAmountChange({
                    label: currentUplinks.label,
                    value: currentUplinks,
                  });
                }}
              />
              <label htmlFor={el.label} className="m-0 ml-3">
                {el.label}
              </label>
              {errors.first('uplink_amount') ? (
                <FormFeedback className="d-block input__danger-message">{errors.first('uplink_amount')}</FormFeedback>
              ) : null}
            </FormGroup>
          ))}
        </div>
      </div>
    </HeaderedCard>
  );
};

export default NetworkCard;
