import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import LaunchPng from '../images/mirage-upgrade.png';

const MaintenancePage = () => {
  return (
    <Modal className="wider-modal-2" isOpen={true}>
      <ModalBody className="text-center">
        <img src={LaunchPng} className="img-fluid mx-auto d-block w-50" alt="launch" />
        <h1>We're down on scheduled maintenance.</h1>
        <h1 className="my-3">Please check back soon.</h1>
        <h2 className="my-4">We expect to be back around 2PM CET, Feb 22</h2>
      </ModalBody>
    </Modal>
  );
};

export default MaintenancePage;
