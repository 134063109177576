import React, { useState } from 'react';
import { Card, CardBody, FormGroup, Input, Button, FormFeedback } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import axios from '../../axiosInstance.js';
import Form from '../UI/Form';

const PasswordResetFromEmail = props => {
  const [data, setData] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    token: props.match.params.token,
  });
  const [sent, setSentStatus] = useState(null);
  const [sending, setSending] = useState(false);

  const htmlParser = ReactHtmlParser;

  const handleInputChange = e => {
    const dataCopy = { ...data };
    dataCopy[e.target.name] = e.target.value;
    setData(dataCopy);
  };

  const passResetHandler = () => {
    if (!sending) {
      setSending(true);
      axios
        .post('api/user/password/reset', data)
        .then(r => {
          setSending(false);
          if (r && r.status === 200) {
            setSentStatus(r.data.message);
          }
          setTimeout(() => props.history.push('/login'), 3000);
        })
        .catch(e => setSending(false));
    }
  };

  return (
    <div className="auth-block">
      <h1>
        {process.env.REACT_APP_WEBSITE_NAME_HTML ? (
          <span>{htmlParser(process.env.REACT_APP_WEBSITE_NAME_HTML)}</span>
        ) : (
          <span className="bold">{process.env.REACT_APP_WEBSITE_NAME}</span>
        )}
      </h1>
      <Card className="login">
        <CardBody>
          {!sent ? (
            <Form submit={passResetHandler}>
              <p>Reset password</p>
              <FormGroup>
                <Input
                  data-cy="reset-passw-input-email"
                  onChange={handleInputChange}
                  placeholder="Email"
                  type="email"
                  name="email"
                  autoComplete="your-email"
                  value={data.email}
                  required
                />
                <FormFeedback />
              </FormGroup>
              <FormGroup>
                <Input
                  data-cy="reset-passw-input-passw"
                  onChange={handleInputChange}
                  placeholder="New password"
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  value={data.password}
                  minLength={6}
                  required
                />
                <FormFeedback />
              </FormGroup>
              <FormGroup>
                <Input
                  data-cy="reset-passw-input-passw-confirm"
                  onChange={handleInputChange}
                  placeholder="Repeat new password"
                  type="password"
                  name="password_confirmation"
                  autoComplete="new-password-repeat"
                  value={data.password_confirmation}
                  minLength={6}
                  required
                />
                <FormFeedback />
              </FormGroup>
              <Button data-cy="save-passw-button" disabled={sending} color="primary" className="w-100">
                Save new password
              </Button>
            </Form>
          ) : (
            sent
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withRouter(PasswordResetFromEmail);
