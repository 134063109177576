import React, { useState, useEffect, useContext } from 'react';
import DropIn from 'braintree-web-drop-in-react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { v4 as uuidv4 } from 'uuid';
import braintree from 'braintree-web';

import { AuthContext } from '../contexts/AuthContext';
import { PushNotificationContext } from '../contexts/PushNotificationContext';
import axios from '../../axiosInstance';
import countries from '../../utils/countries';

const Store = props => {
  const [instance, setInstance] = useState(undefined);
  const [clientToken, setClientToken] = useState(null);
  const [nonce, setNonce] = useState(null);
  const [bin, setBin] = useState(null);
  const [type, setType] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [paymentMethodWasSelected, setPaymentMethodWasSelected] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const { pushNotification } = useContext(PushNotificationContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchClientToken = async () => {
      // Get a client token for authorization from your server
      const response = await axios.get(`api/user/payment${!props.anon ? '?withSave=1' : ''}`);
      const clientToken = await response.data; // If returned as JSON string
      setClientToken(clientToken);
    };
    fetchClientToken();
  }, []);

  useEffect(() => {
    if (instance) {
      addPayment();
    }
  }, [paymentMethodWasSelected]);

  const addPayment = async () => {
    // Send the nonce to your server
    const response = await instance.requestPaymentMethod(function(err, payload) {
      if (err) {
        console.warn(err);
        return;
      }
      setNonce(payload.nonce);
      setBin(payload.details.bin);
      setType(payload.type);
    });
  };

  const buy = async () => {
    const clientInstance = await braintree.client.create({
      authorization: clientToken,
    });
    const threeDSecure = await braintree.threeDSecure.create({
      version: 2,
      client: clientInstance,
    });

    const { address, address2, city, country, postcode } = user.tenant;

    const { phone, email, name, surname } = user;

    let countryCodeAlpha2 = Object.keys(countries).filter(key => countries[key] === country);
    countryCodeAlpha2 = countryCodeAlpha2.length ? countryCodeAlpha2[0] : '';

    const threeDSecureParameters = {
      amount: props.price,
      nonce,
      bin,
      email,
      billingAddress: {
        givenName: name,
        surname,
        phoneNumber: phone,
        streetAddress: address,
        extendedAddress: address2,
        locality: city,
        postalCode: postcode,
        countryCodeAlpha2,
      },
      onLookupComplete: (data, next) => {
        next();
      },
    };
    const response = await threeDSecure.verifyCard(threeDSecureParameters);

    if (response.liabilityShifted) {
      await axios.post(`api/user/payment/${props.id}`, {
        nonce: response.nonce,
        type,
      });
      props.getInvoices();
      props.setUserInfo();
      props.onClose();
      props.onPaymentComplete && props.onPaymentComplete();
    } else {
      pushNotification({
        data: {
          type: 'danger',
          name: 'Your payment could not be processed. Please contact your bank support.',
        },
        id: uuidv4(),
      });
      setRefresh(!refresh);
      setShowButton(false);
    }
  };
  if (!clientToken) {
    return (
      <div>
        <Skeleton height={20} />
      </div>
    );
  } else {
    return (
      <div className="m-3">
        <DropIn
          key={refresh}
          preselectVaultedPaymentMethod={false}
          onPaymentOptionSelected={args => {
            // setNonce(false);
            // setType(false);
          }}
          onNoPaymentMethodRequestable={args => {
            setNonce(false);
            setType(false);
            setShowButton(true);
          }}
          onPaymentMethodRequestable={args => {
            setNonce(false);
            setType(false);
            setShowButton(true);
            args.paymentMethodIsSelected && setPaymentMethodWasSelected(prevState => prevState + 1);
          }}
          options={{
            authorization: clientToken,
            paypal: {
              flow: 'checkout',
              amount: props.price,
              currency: 'EUR',
              buttonStyle: {
                color: 'blue',
                shape: 'rect',
                size: 'medium',
              },
            },
          }}
          onInstance={async instance => {
            setInstance(instance);
          }}
        />
        {showButton &&
          (nonce ? (
            <button className="btn btn-blue w-100 my-3" onClick={buy}>
              Pay Invoice
            </button>
          ) : (
            <button className="btn btn-blue w-100 my-3" onClick={addPayment}>
              Add Payment
            </button>
          ))}
      </div>
    );
  }
};

const PaymentModal = ({ open, invoice, onClose, onPaymentComplete, getInvoices, setUserInfo }) => {
  return (
    open && (
      <Modal isOpen={!!open} toggle={onClose}>
        <Store
          price={invoice.total}
          id={invoice.id}
          anon={open.type === 'anon'}
          onClose={onClose}
          onPaymentComplete={onPaymentComplete}
          getInvoices={getInvoices}
          setUserInfo={setUserInfo}
        />
      </Modal>
    )
  );
};

Store.propTypes = {
  anon: PropTypes.bool,
  id: PropTypes.number,
  getInvoices: PropTypes.func,
  setUserInfo: PropTypes.func,
  onClose: PropTypes.func,
  price: PropTypes.number,
};

PaymentModal.propTypes = {
  open: PropTypes.bool,
  invoice: PropTypes.object,
  onClose: PropTypes.func,
  getInvoices: PropTypes.func,
  setUserInfo: PropTypes.func,
};

export default PaymentModal;
