import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Form from '../../../utils/form/form';
import cloneObject from '../../../utils/cloneObject';
import NewRangedInput from '../../UI/NewRangedInput';
import axios from '../../../axiosInstance';
import { AuthContext } from '../../contexts/AuthContext';
import clsx from 'clsx';

const EditControlPlaneModal = ({
  clusterIdentifier,
  minDiskSize,
  modalOpen,
  toggleModal,
  fetchPoolCpResources,
  clusterControlPlanes,
  clusterControlResources,
}) => {
  const [isInput, setIsInput] = useState(false);
  const [resources, setResources] = useState(null);
  const [controlPlaneCost, setControlPlaneCost] = useState(0);
  const [data, setData] = useState(
    new Form({
      cpu_core_count: clusterControlResources.control_plane_cpu,
      memory: clusterControlResources.control_plane_ram,
      disk: clusterControlResources.control_plane_disk,
    })
  );
  const { tenant } = useContext(AuthContext);

  const fetchBillingPlan = async tenant => {
    const billing = await axios.get(`api/user/${tenant}/billing-resources`);
    if (billing.data) {
      setResources(billing.data.resources.reduce((accum, el) => ({ ...accum, [el.resource_id]: { ...el } }), {}));
    }
  };

  useMemo(() => {
    fetchBillingPlan(tenant);
  }, []);

  const findPriceBySlug = slugToFind => {
    for (const key in resources) {
      if (resources[key].slug === slugToFind) {
        return resources[key].price;
      }
    }
    return null;
  };

  useEffect(() => {
    if (resources) {
      const price =
        findPriceBySlug('cpu') * data.originalData.cpu_core_count +
        findPriceBySlug('ram') * data.originalData.memory +
        findPriceBySlug('storage') * data.originalData.disk;

      setControlPlaneCost(price);
    }
  }, [resources, data]);

  const handleDataChange = (key, value) => {
    const dataClone = cloneObject(data);
    dataClone.originalData[key] = key === 'name' ? value : parseInt(value);
    setData(dataClone);
  };

  const handleSubmit = e => {
    e.preventDefault();
    axios
      .patch(`api/user/kubernetes-talos/${clusterIdentifier}/edit-control-planes`, data.originalData)
      .then(res => {
        if (res.status === 200) {
          fetchPoolCpResources();
          toggleModal();
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  return (
    <Modal isOpen={modalOpen} className={clsx(data.originalData.has_extra_storage && 'modal-width-680')}>
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggleModal}>Edit Control Planes</ModalHeader>
        <ModalBody>
          <div className="row no-gutters flex-row mb-3">
            <div className="col-6 pr-2 d-flex flex-column">
              <div className="d-flex align-items-center ml-2">
                <h4 className="mb-0">Control Planes</h4>
              </div>
            </div>
            <div className="col-6">
              <p className="mt-2 mb-0 text-right mr-3">
                {clusterControlPlanes.length} nodes * CHF {controlPlaneCost.toFixed(2)} ={' '}
                {(clusterControlPlanes.length * controlPlaneCost).toFixed(2)} CHF/hour
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <p className="bold mb-2 mt-0 text-center">CPU, Cores</p>
              <NewRangedInput
                classes="mx-auto"
                onChange={e => handleDataChange('cpu_core_count', e)}
                value={data.originalData.cpu_core_count}
                step={1}
                min={2}
                name="node"
                dataCy="cpu-amount"
              />
            </div>
            <div className="d-flex flex-column">
              <p className="bold mb-2 mt-0 text-center">RAM, GB</p>
              <NewRangedInput
                classes="mx-auto"
                onChange={e => handleDataChange('memory', e)}
                value={data.originalData.memory}
                step={1}
                min={2}
                name="node"
                dataCy="ram-amount"
              />
            </div>
            <div className="d-flex flex-column">
              <p className="bold mb-2 mt-0 text-center">Storage, GB</p>
              <NewRangedInput
                classes="mx-auto"
                onChange={e => handleDataChange('disk', e)}
                value={data.originalData.disk}
                step={1}
                min={minDiskSize}
                name="node"
                dataCy="storage-amount"
              />
            </div>
          </div>
          <div className="">
            <div className="alert alert-primary" role="alert">
              <div className="d-flex align-items-center">
                <i className="fas fa-info-circle mr-3" style={{ fontSize: '1.5em' }} />
                <p className="mb-0">
                  Note: Hard Disk can only be extended. After saving changes control planes will be updated one by one.
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex flex-row bg-gray pt-3">
          <Button type="button" className="ml-auto btn-light" onClick={toggleModal}>
            Cancel
          </Button>
          <Button type="submit" color="secondary">
            Change
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditControlPlaneModal;
