export const xsScreenSize = '(min-width: 0)';
export const smScreenSize = '(min-width: 576px)';
export const mdScreenSize = '(min-width: 769px)';
export const lgScreenSize = '(min-width: 992px)';
export const xlScreenSize = '(min-width: 1280px)';

const lgScreenHeight = '(min-height: 960px)';

export const xsScreen = window.matchMedia(xsScreenSize).matches;
export const smScreen = window.matchMedia(smScreenSize).matches;
export const mdScreen = window.matchMedia(mdScreenSize).matches;
export const lgScreen = window.matchMedia(lgScreenSize).matches;
export const xlScreen = window.matchMedia(xlScreenSize).matches;
export const lgHeightScreen = window.matchMedia(lgScreenHeight).matches;

export const primaryColor = 'rgba(84, 138, 243, 1)';
export const secondaryColor = '#cbdbfb';
export const successColor = '#4eb79a';
export const dangerColor = '#f25f5c';
export const infoColor = '#17a2b8';
export const warningColor = '#ffc107';
