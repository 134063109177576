import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Modal, ModalBody } from 'reactstrap';

const VideoContext = React.createContext();

const VideoProvider = ({ children }) => {
  const [showVideo, setShowVideo] = useState(false);
  const onOpenVideo = url => setShowVideo(url);
  const onCloseVideo = () => setShowVideo(false);
  return (
    <VideoContext.Provider value={{ onOpenVideo, onCloseVideo }}>
      {showVideo && (
        <Modal isOpen={showVideo} toggle={onCloseVideo} className="max-width-700">
          <ModalBody className="d-flex align-items-center justify-content-center">
            <ReactPlayer className="video-player" url={showVideo} controls={true} />
          </ModalBody>
        </Modal>
      )}
      {children}
    </VideoContext.Provider>
  );
};
export { VideoProvider };
export default VideoContext;
