import React, { useContext, useState, useEffect } from 'react';
import groupArray from 'group-array';

import axios from '../../axiosInstance';
import { withAuthContext } from '../hoc/withAuthContext';
import OrgInfo from './OrgInfo';
import BillingInfo from './BillingInfo';
import sections from './sectionsData';
import SectionRouts from './SectionRouts';
import CreditsCard from './CreditsCard';
import Invoices from './Invoices';
import initData from './initData';
import PathTabs from '../UI/PathTabs';
import { NavigationTabs } from '../UI/Tabs';
import PaymentMethodSelectCard from '../Payments/PaymentMethodSelectCard';
import { AuthContext } from '../contexts/AuthContext';
import DemoOptionsContext from '../contexts/DemoOptionsContext';
import { handleDateObjectConversion, handleDateConversion } from '../../utils/timeConversion';
import Transactions from './Transactions';

const PlanDetails = ({ credits, monthlyUsage, billingPlan, sections, ...props }) => {
  const { user, userPermissions } = useContext(AuthContext);
  const { dropInReload } = useContext(DemoOptionsContext);

  return (
    <>
      {userPermissions.allowViewCreditsInfo && (user.isFirstTenant || user.isXelonChild) && (
        <CreditsCard
          containerClassName="mt-3 mt-md-0 mb"
          balance={credits}
          total={monthlyUsage || 0}
          period={monthlyUsage > 0 ? Math.floor(credits / monthlyUsage) : 0}
        />
      )}

      {userPermissions.allowManagePaymentMethods && (user.isFirstTenant || user.isXelonChild) && (
        <PaymentMethodSelectCard requireBillingAddress={true} dropInReload={dropInReload} />
      )}

      <NavigationTabs
        type="md"
        options={sections.map(el => ({
          path: el.path,
          label: el.title,
          infoMessage: el.infoMessage,
          id: el.id,
        }))}
        className="mt rounded-top rounded-0"
      />

      <SectionRouts {...props} billing_plan={billingPlan} />
    </>
  );
};

const BillingPlan = props => {
  const [billingPlan, setBillingPlan] = useState(null);
  const [billingPlanState, setBillingPlanState] = useState(initData.billing_plan_state);
  const [billingInfo, setBillingInfo] = useState(initData.billing_info);
  const [orgInfo, setOrgInfo] = useState(initData.org_info);
  const [monthlyUsage, setMonthlyUsage] = useState(undefined);
  const [invoices, setInvoices] = useState(undefined);
  const { user, userPermissions } = useContext(AuthContext);

  useEffect(() => {
    getBillingPlan();
    getOrgInfo();
    fetchMonthlyUsage();
  }, []);

  const getBillingPlan = () => {
    axios.post('/api/user/billing/user').then(res => {
      if (res && res.data) {
        const b_plan = res.data;
        b_plan.resources = groupArray(b_plan.resources, 'type');
        initBillingPlan(b_plan);
      }
    });
  };

  const getOrgInfo = () => {
    axios.get('/api/user/currentTenant').then(res => {
      if (res && res.data) {
        initOrgInfo(res.data);
        initBillingPlanState(res.data);
      }
    });
  };

  const getInvoices = () => {
    axios.get('/api/user/invoice/list').then(res => {
      if (res && res.data) {
        const invoicesDateConverted = handleDateConversion(res.data);
        setInvoices(invoicesDateConverted);
      }
    });
  };

  const fetchMonthlyUsage = () => {
    if (!user.isFirstTenant) {
      axios
        .get(`/api/user/${props.authContext.tenant}/billing/tokens/monthly`)
        .then(res => res && res.data !== undefined && setMonthlyUsage(res.data));
    }
  };

  const initOrgInfo = data => {
    const newOrgInfo = { ...orgInfo };
    Object.keys(newOrgInfo).forEach(el => {
      newOrgInfo[el].val = data[el];
      if (el === 'created_at') {
        newOrgInfo[el].val = handleDateObjectConversion(data[el]);
      }
    });
    setOrgInfo(newOrgInfo);
  };

  const initBillingPlan = data => {
    const b_info = { ...billingInfo };
    Object.keys(b_info).forEach(el => {
      b_info[el].val = data[el];
    });

    setBillingPlan(data);
    setBillingInfo(b_info);
  };

  const initBillingPlanState = data => {
    const billingPlanStateCurr = { ...billingPlanState };

    billingPlanStateCurr['trial'].val = data['trial'];
    billingPlanStateCurr['state'].val = data['active'] ? 'Active' : 'Disabled';

    setBillingPlanState(billingPlanStateCurr);
  };

  const sectionsPath = [
    userPermissions.allowViewOrganizationInvoices && {
      name: 'invoice-history',
      path: '/invoice-history',
      title: 'Invoice History',
      component: <Invoices handleShowInvoices getInvoices={getInvoices} invoices={invoices} />,
      exact: true,
    },
    userPermissions.allowViewOrganizationInvoices && {
      name: 'transactions-overview',
      path: '/transaction-overview',
      title: 'Transaction Overview',
      component: <Transactions />,
      exact: true,
    },
    {
      name: 'plan-details',
      path: '',
      title: 'Plan details',
      component: (
        <PlanDetails
          credits={user.credits}
          monthlyUsage={monthlyUsage}
          sections={sections}
          billingPlan={billingPlan}
          {...props}
        />
      ),
    },
  ];
  return (
    <>
      <div className="col-md-4 l-side mb-3">
        <OrgInfo data={orgInfo} />
        <BillingInfo data={billingInfo} billingPlanState={billingPlanState} />
      </div>
      <div className="col-md-8 r-side">
        <PathTabs className="mb-4" type="md" sections={sectionsPath} reverse showTabsOnlyForDirectCustomers />
      </div>
    </>
  );
};

export default withAuthContext(BillingPlan);
