import React, { useContext } from 'react';

import VideoContext from '../contexts/VideoContext';

const AttachedLink = ({ to, label, blank, type, dataCy }) => {
  const { onOpenVideo } = useContext(VideoContext);
  return type === 'video' ? (
    <div
      data-cy={dataCy}
      className="btn btn-link text-left d-flex flex-raw"
      onClick={() => {
        onOpenVideo(to);
      }}
    >
      <i className="fas fa-play mr-3 my-auto" />
      {label}
    </div>
  ) : (
    <a data-cy={dataCy} className="btn btn-link text-left d-flex flex-raw" target={blank && '_blank'} href={to}>
      <i className="fas fa-link mr-3 my-auto" />
      {label}
    </a>
  );
};

export default AttachedLink;
