const customerTypeOptions = [
  {
    label: 'System Integrator',
    value: 'system-integrator',
  },
  {
    label: 'Developer/SaaS',
    value: 'developer-saas',
  },
  {
    label: 'Enterprise',
    value: 'enterprise',
  },
  {
    label: 'Internal',
    value: 'internal',
  },
];

export default customerTypeOptions;
