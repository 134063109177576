import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';

import axios from '../../axiosInstance';
import PasswordField from '../UI/NewPasswordField';
import Form from '../../utils/form/form';
import cloneObject from '../../utils/cloneObject';

const ChangePasswordAuthUserModal = ({ changePasswordModalIsOpen, setChangePasswordModalIsOpen }) => {
  const [data, setData] = useState(
    new Form({
      oldPassword: '',
      newPassword: '',
      newPassword_confirmation: '',
    })
  );

  const onInputChange = e => {
    const newData = cloneObject(data);
    const name = e.name || e.target.name;
    newData[name] = e.value || (e.target ? e.target.value : '');
    newData.clearResults(name);
    if (name === 'newPassword_confirmation') {
      newData.clearResults('newPassword');
    }
    setData(newData);
  };

  const onSave = async e => {
    e.preventDefault();
    try {
      await data.submitUsing(async () => {
        const response = await axios.post('api/user/password/update', { ...data.data() });

        if (response && response.status === 200) {
          setChangePasswordModalIsOpen(false);
        }
      });
    } catch (e) {
      // for rerender component
      setData(cloneObject(data));
    }
  };

  return (
    <Modal isOpen={changePasswordModalIsOpen} className="max-width-30">
      <ModalHeader toggle={() => setChangePasswordModalIsOpen(!changePasswordModalIsOpen)}>Change Password</ModalHeader>
      <form onSubmit={onSave}>
        <ModalBody>
          <FormGroup>
            <PasswordField
              name="oldPassword"
              passwordClassname={data.errors.has('oldPassword') && 'is-invalid'}
              validationMessage={data.errors.first('oldPassword')}
              value={data.oldPassword}
              handleInputChange={onInputChange}
              label="Old Password"
              dataCy="change-password-0"
            />
          </FormGroup>
          <FormGroup>
            <PasswordField
              name="newPassword"
              passwordClassname={data.errors.has('newPassword') && 'is-invalid'}
              validationMessage={data.errors.first('newPassword')}
              value={data.newPassword}
              handleInputChange={onInputChange}
              label="New Password"
              dataCy="change-password-1"
            />
          </FormGroup>
          <FormGroup>
            <PasswordField
              name="newPassword_confirmation"
              passwordClassname={data.errors.has('newPassword_confirmation') && 'is-invalid'}
              validationMessage={data.errors.first('newPassword_confirmation')}
              value={data.newPassword_confirmation}
              handleInputChange={onInputChange}
              label="Password Confirmation"
              dataCy="change-password-2"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="d-flex">
          <Button
            data-cy="change-password-cancel"
            className="w-50 btn btn-light"
            onClick={() => setChangePasswordModalIsOpen(false)}
          >
            Cancel
          </Button>
          <Button data-cy="change-password-save" type="submit" color="success" className="w-50 btn">
            Save changes
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ChangePasswordAuthUserModal;
