import { ipPrice } from './initData';

export const formNewDiskName = oldDiskName => {
  return 'Harddisk ' + (Number(oldDiskName.match(/\d+/g)[0]) + 1);
};

export const calcMounthlyTotal = (formData, billingPeriod) => {
  return Object.entries(formData).reduce((accum, [field, data]) => {
    if (field === 'hdd') {
      return (
        accum +
        Object.values(data).reduce((diskAccum, disk) => {
          return diskAccum + (disk.value.price ? disk.value.price * billingPeriod.value.month : 0);
        }, 0)
      );
    }
    if (field === 'ips') {
      const price = data ? (data.value ? (data.value - 1) * ipPrice * billingPeriod.value.month : 0) : 0;

      return accum + price;
    }
    const multiplier = field === 'billing_frequency' ? 1 : billingPeriod.value.month;
    const price = data ? (data.value ? (data.value.price ? Number(data.value.price * multiplier) : 0) : 0) : 0;
    return price + accum;
  }, 0);
};
export const calcSetupFee = (formData, billingPeriod) => {
  return Object.entries(formData).reduce((accum, [field, data]) => {
    const price = data ? (data.value ? (data.value.setupPrice ? data.value.setupPrice : 0) : 0) : 0;
    return accum + price;
  }, 0);
};

export const getInitialValue = el => {
  return { label: el.label, value: el };
};
