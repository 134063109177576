import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, FormGroup, Input } from 'reactstrap';
import ReactJson from 'react-json-view';

import Form from '../../../utils/form/form';
import cloneObject from '../../../utils/cloneObject';
import axios from '../../../axiosInstance';
import Spinner from '../../UI/Spinner';

const ClusterListPage = () => {
  const [isPending, setIsPenging] = useState(false);
  const [logs, setLogs] = useState([]);
  const [data, setData] = useState(
    new Form({
      search: '',
    })
  );

  const onInputChange = e => {
    const newData = cloneObject(data);
    newData[e.target.name] = e.target.value;
    newData.clearResults(e.target.name);
    setData(newData);
  };

  const onSubmit = async () => {
    try {
      await data.submitUsing(async () => {
        fetchLogs();
      });
    } catch (e) {
      // for rerender component
      setData(cloneObject(data));
      setIsPenging(false);
    }
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      fetchLogs();
    }
  };

  const fetchLogs = async () => {
    setIsPenging(true);
    const result = await axios.get('/api/user/custom-log/get', {
      params: data.data(),
    });
    setLogs(result.data);
    setIsPenging(false);
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const logList = logs.map(log => {
    return <ReactJson src={JSON.parse(log.json_data)} key={log.id} />;
  });

  return (
    <form className="py-5 row no-gutters justify-content-around">
      <div className="col-md-10 d-flex flex-column dedicated">
        <Card className="row no-gutters flex-column mb-3">
          <div className="row no-gutters flex-row border-bottom p-4 no-wrap">
            <div className="d-flex flex-column pl-4 col-8 col-md-auto">
              <h1 className="pl-0 line-height-1 mb-1">Custom logs list</h1>
              <FormGroup className="form-inline mt-3">
                <Input
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Search"
                  value={data.search}
                  onChange={onInputChange}
                  onKeyDown={onKeyDown}
                />
                <Button type="button" color="success" className="ml-3" onClick={onSubmit}>
                  Submit
                </Button>
                {isPending && <Spinner size="sm" className="ml-3" />}
              </FormGroup>
            </div>
          </div>
          <div className="row flex-row no-gutters p-4">
            <div className="col-12 col-md-6 pr-md-2 d-flex flex-column mb-3 mb-md-0">{logList}</div>
          </div>
        </Card>
      </div>
    </form>
  );
};

export default withRouter(ClusterListPage);
