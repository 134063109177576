import React from 'react';
import { FormGroup } from 'reactstrap';
import clsx from 'clsx';

const ServerCard = ({ className, value, cost, currentValue, icon, config, onChange, billingPeriod }) => {
  const isSelected = value === currentValue;
  return (
    <FormGroup check inline key={value} className={className}>
      <label
        htmlFor={value}
        className={clsx(
          isSelected ? 'form-check-label-btn-selected' : 'form-check-label-btn',
          'd-flex flex-row p-0 no-gutters w-100'
        )}
      >
        <div
          className={clsx('col-2 d-flex flex-column align-items-center border-right', isSelected && 'border-blue-dark')}
        >
          {icon}
        </div>
        <div className="col-10 no-gutters d-flex flex-column justify-content-around text-dark">
          <div className={clsx('border-bottom p-3 text-left', isSelected && 'border-blue-dark')}>{config}</div>
          <div className="d-flex font-weight-bold flex-row justify-content-between px-3">
            <div>{billingPeriod + ' Payment'}</div>
            <div>{cost + ' CHF'}</div>
          </div>
        </div>

        <input
          type="radio"
          name="category"
          className="form-check-input"
          id={value}
          value={value}
          checked={value === currentValue}
          onChange={event => onChange(event.target.value)}
        />
      </label>
    </FormGroup>
  );
};

export default ServerCard;
