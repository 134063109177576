import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import NotFoundImage from '../images/mirage-page-not-found.png';
import ErrorImage from '../images/error.svg';
import ReportModal from './Reports/ReportModal';

const NotFound = props => {
  const [reportModalVisible, setReportModalVisible] = useState(false);
  let homePath = '/';

  if (props.history.location.pathname.includes('login')) {
    homePath = null;
  }
  return (
    <div className="not-found-page d-flex flex-wrap">
      <div className="col-md-6 d-flex align-items-center justify-content-center mt-md-0">
        <img className="img-fluid w-50" src={NotFoundImage} alt="Not found" />
      </div>
      <div className="col-md-6 not-found-page-right-side d-flex flex-column justify-content-center mt-md-0">
        <img className="img-fluid w-25 mb-5 img-night" src={ErrorImage} alt="404 error" />
        {homePath ? (
          <>
            <p className="h2">We are not quite sure what went wrong. You can</p>
            <p className="h2">
              <u className="h2 font-blue cursor-pointer" onClick={() => props.history.push(homePath)}>
                head back to Dashboard
              </u>
              , or try looking on our
            </p>
            <p className="h2">
              <u
                className="h2 font-blue cursor-pointer"
                onClick={() => window.open('https://www.xelon.ch/docs', '_blank')}
              >
                Knowledge Base
              </u>{' '}
              if you need some help.
            </p>
          </>
        ) : (
          <>
            <p className="h2">Not really much going on in these parts...</p>
            <p className="h2">How about you head back home?</p>
          </>
        )}
        {homePath && (
          <div className="d-flex flex-wrap align-items-center mt">
            <Button
              data-cy="notfound-dashboard"
              color="primary"
              className="btn mr-2 mb-1"
              onClick={() => props.history.push(homePath)}
            >
              Dashboard
            </Button>
            <Button
              data-cy="notfound-report-bug"
              type="button"
              className="btn-light"
              onClick={() => setReportModalVisible(true)}
            >
              Report a bug
            </Button>
          </div>
        )}
      </div>
      {reportModalVisible && (
        <ReportModal
          modalOpen={reportModalVisible}
          type="Bug"
          toggleModal={() => setReportModalVisible(!reportModalVisible)}
        />
      )}
    </div>
  );
};

export default withRouter(NotFound);
