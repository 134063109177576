import React, { useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { handleCopyText } from '../../utils/commonUtils';
import { PushNotificationContext } from '../contexts/PushNotificationContext';

const ListEntityName = ({ entityName, className, isPStorage }) => {
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const { pushNotification } = useContext(PushNotificationContext);

  return isPStorage ? (
    <div onMouseEnter={() => setShowCopyIcon(true)} onMouseLeave={() => setShowCopyIcon(false)}>
      <span
        style={{
          position: 'relative',
          height: '1rem',
          width: '1rem',
        }}
      >
        {showCopyIcon && (
          <button
            style={{
              position: 'absolute',
              top: '-1rem',
              left: '-3rem',
              color: '#777E90',
            }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleCopyText(entityName);
              pushNotification({
                data: {
                  type: 'success',
                  name: 'Name has been copied',
                },
                id: uuidv4(),
              });
            }}
            className="btn"
          >
            <i className="far fa-files-o" aria-hidden="true" />
          </button>
        )}
      </span>
      <div className="header-t1" style={{ height: '1.5rem', width: '14rem' }}>
        {entityName}
      </div>
    </div>
  ) : (
    <div
      onMouseEnter={() => setShowCopyIcon(true)}
      onMouseLeave={() => setShowCopyIcon(false)}
      className={`d-flex align-items-center mb-0 ${className ? className : ''}`}
    >
      {entityName}
      <span
        style={{
          position: 'relative',
          height: '1rem',
          width: '1rem',
        }}
      >
        {showCopyIcon && (
          <button
            style={{ position: 'absolute', top: '-1rem', color: '#777E90' }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleCopyText(entityName);
              pushNotification({
                data: {
                  type: 'success',
                  name: 'Name has been copied',
                },
                id: uuidv4(),
              });
            }}
            className="btn"
          >
            <i className="far fa-files-o" aria-hidden="true" />
          </button>
        )}
      </span>
    </div>
  );
};

export default ListEntityName;
