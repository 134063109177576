import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Frame from '../UI/Frame';
import PushNotification from '../UI/PushNotification';
import { withViewContext } from '../hoc/withViewContext';

const PushNotificationContext = React.createContext({});

const PushNotificationProvider = props => {
  const [showPushNotifications, setShowPushNotifications] = useState([]);

  const pushNotification = newPushNotification => {
    if (typeof newPushNotification === 'string') {
      showPushNotification({
        data: {
          name: newPushNotification,
        },
        id: newPushNotification,
        created_at: new Date(),
      });
    }
    if (typeof newPushNotification === 'object') {
      if (newPushNotification.data && newPushNotification.data.name && Array.isArray(newPushNotification.data.name)) {
        newPushNotification.data.name = newPushNotification.data.name[0];
      }
      showPushNotification(newPushNotification);
    }
  };

  const showPushNotification = newPushNotification => {
    setShowPushNotifications(prevState => [...prevState, newPushNotification]);
  };

  const hidePushNotification = oldPushNotificationId => {
    setShowPushNotifications(prevState => prevState.filter(notification => notification.id !== oldPushNotificationId));
  };

  const { children } = props;
  return (
    <PushNotificationContext.Provider
      value={{
        showPushNotifications,
        pushNotification,
        showPushNotification,
        hidePushNotification,
      }}
    >
      <Frame overModal>
        <div className="d-flex flex-row p-5 mt-5 z-index-over-modal">
          <div className="flex-grow-1 z-index-over-modal" />
          <TransitionGroup key={showPushNotifications.length} className="min-width-rem-20">
            {showPushNotifications.map(notification => (
              <CSSTransition key={notification.id} timeout={notification.timeout || 1500} classNames="item">
                <PushNotification
                  className="z-index-over-modal"
                  key={notification.id}
                  {...notification}
                  onCloseNotification={() => hidePushNotification(notification.id)}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </Frame>
      {children}
    </PushNotificationContext.Provider>
  );
};

PushNotificationProvider.propTypes = { children: PropTypes.node };

export default withViewContext(PushNotificationProvider);
export { PushNotificationContext };
