import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Form from '../../../utils/form/form';
import cloneObject from '../../../utils/cloneObject';
import NewRangedInput from '../../UI/NewRangedInput';
import axios from '../../../axiosInstance';
import { AuthContext } from '../../contexts/AuthContext';

const AddPoolModal = ({ modalOpen, toggleModal, addPool }) => {
  const [isInput, setIsInput] = useState(false);
  const [resources, setResources] = useState(null);
  const [poolPerNodeCost, setPoolPerNodeCost] = useState(0);
  const [data, setData] = useState(
    new Form({
      workerPoolName: 'Worker pool',
      workerNodeAmount: 1,
      workerNodeCpu: 2,
      workerNodeRam: 4,
      workerNodeDisk: 50,
      workerNodeIsStorage: false,
      workerNodeExtraDisk: 50,
    })
  );
  const { tenant } = useContext(AuthContext);

  const fetchBillingPlan = async tenant => {
    const billing = await axios.get(`api/user/${tenant}/billing-resources`);
    if (billing.data) {
      setResources(billing.data.resources.reduce((accum, el) => ({ ...accum, [el.resource_id]: { ...el } }), {}));
    }
  };

  useMemo(() => {
    fetchBillingPlan(tenant);
  }, []);

  const findPriceBySlug = slugToFind => {
    for (const key in resources) {
      if (resources[key].slug === slugToFind) {
        return resources[key].price;
      }
    }
    return null;
  };

  useEffect(() => {
    if (resources) {
      let price =
        findPriceBySlug('cpu') * data.originalData.workerNodeCpu +
        findPriceBySlug('ram') * data.originalData.workerNodeRam +
        findPriceBySlug('storage') * data.originalData.workerNodeDisk;

      if (data.originalData.workerNodeIsStorage) {
        price += findPriceBySlug('storage') * data.originalData.workerNodeExtraDisk;
      }

      setPoolPerNodeCost(price);
    }
  }, [resources, data]);

  const handleDataChange = (key, value) => {
    const dataClone = cloneObject(data);
    if (key === 'workerPoolName' || key === 'workerNodeIsStorage') {
      dataClone.originalData[key] = value;
    } else {
      dataClone.originalData[key] = parseInt(value);
    }
    setData(dataClone);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (data.originalData.workerNodeIsStorage === false) {
      data.originalData.workerNodeExtraDisk = 0;
    }
    addPool(data.originalData);
    toggleModal();
  };

  return (
    <Modal isOpen={modalOpen} style={{ maxWidth: '680px' }}>
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggleModal}>Add Pool</ModalHeader>
        <ModalBody>
          <div className="row no-gutters flex-row mb-3">
            <div className="col-6 pr-2 d-flex flex-column">
              {!isInput && (
                <div className="d-flex align-items-center ml-2">
                  <h4 className="mb-0" onClick={() => setIsInput(true)}>
                    {data.originalData.workerPoolName}
                  </h4>
                  <button data-cy="talos-edit-pool" className="talos-edit-pool" onClick={() => setIsInput(true)}>
                    <i className="fa fa-pen text-dark" />
                  </button>
                </div>
              )}
              {isInput && (
                <textarea
                  data-cy="pool-name-textarea"
                  className="talos-pool-name-input border rounded px-2 w-100 mb-3"
                  rows="1"
                  onBlur={() => setIsInput(false)}
                  onChange={e => handleDataChange('workerPoolName', e.target.value)}
                  value={data.originalData.workerPoolName}
                />
              )}
            </div>
            <div className="col-6">
              <p className="mt-2 mb-0 text-right mr-3">
                {data.originalData.workerNodeAmount} Nodes * CHF {poolPerNodeCost.toFixed(2)} ={' '}
                {(data.originalData.workerNodeAmount * poolPerNodeCost).toFixed(2)} CHF/hour
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3 d-flex flex-column">
              <p className="bold mb-2 mt-0 text-center">Nodes</p>
              <NewRangedInput
                classes="mx-auto"
                onChange={e => handleDataChange('workerNodeAmount', e)}
                value={data.originalData.workerNodeAmount}
                step={1}
                max={20}
                min={1}
                name="node"
                dataCy="worker-node-amount"
              />
            </div>
            <div className="col-3 d-flex flex-column">
              <p className="bold mb-2 mt-0 text-center">CPU, Cores</p>
              <NewRangedInput
                classes="mx-auto"
                onChange={e => handleDataChange('workerNodeCpu', e)}
                value={data.originalData.workerNodeCpu}
                step={1}
                max={20}
                min={2}
                name="worker-cpu-amount"
                dataCy="worker-cpu-amount"
              />
            </div>
            <div className="col-3 d-flex flex-column">
              <p className="bold mb-2 mt-0 text-center">RAM, GB</p>
              <NewRangedInput
                classes="mx-auto"
                onChange={e => handleDataChange('workerNodeRam', e)}
                value={data.originalData.workerNodeRam}
                step={1}
                max={20}
                min={2}
                name="node"
                dataCy="worker-ram-amount"
              />
            </div>
            <div className="col-3 d-flex flex-column">
              <p className="bold mb-2 mt-0 text-center">Storage, GB</p>
              <NewRangedInput
                classes="mx-auto"
                onChange={e => handleDataChange('workerNodeDisk', e)}
                value={data.originalData.workerNodeDisk}
                step={1}
                min={10}
                name="node"
                dataCy="worker-storage-amount"
              />
            </div>
          </div>
          <div className="row no-gutters flex-row pt-3 border-top">
            <div className="d-flex align-items-center col-6">
              <div className="onoffswitch mr-2">
                <input
                  data-cy="extra-storage-toggle"
                  type="checkbox"
                  className="onoffswitch-checkbox"
                  id="extra-storage-toggle"
                  name="extra-storage-toggle"
                  checked={data.originalData.workerNodeIsStorage === true}
                  onChange={e => handleDataChange('workerNodeIsStorage', e.target.checked)}
                />
                <label
                  className="onoffswitch-label mb-0"
                  htmlFor="extra-storage-toggle"
                  data-cy="extra-storage-toggle-label"
                >
                  <span className="onoffswitch-inner" />
                  <span className="onoffswitch-switch" />
                </label>
              </div>
              <div>
                <p className="bold mb-0">Storage pool</p>
                <span>(add extra empty disk to every node)</span>
              </div>
            </div>
            <div className="d-flex col-6 flex-column">
              <p className="bold mb-2 mt-0 text-center">Extra storage, GB</p>
              <NewRangedInput
                classes="mx-auto"
                onChange={e => handleDataChange('workerNodeExtraDisk', e)}
                value={data.originalData.workerNodeExtraDisk}
                step={1}
                min={0}
                name="extra-storage"
                dataCy="worker-extra-storage-amount"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex flex-row bg-gray pt-3">
          <Button type="button" className="ml-auto btn-light" onClick={toggleModal}>
            Cancel
          </Button>
          <Button type="submit" color="secondary">
            Create
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddPoolModal;
