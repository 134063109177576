import React from 'react';
import { Card } from 'reactstrap';
import clsx from 'clsx';

const HeaderedCard = ({ headerLabel, className, children, containerClassName }) => {
  return (
    <Card className={clsx('d-flex flex-column', containerClassName)}>
      <h1 className="bg-gray d-flex justify-content-center align-items-center p-3">{headerLabel}</h1>
      <div className={className}>{children}</div>
    </Card>
  );
};

export default HeaderedCard;
