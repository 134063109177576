import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const ClusterResources = ({ clusterResources, fetchingClusterResources }) => {
  return (
    <Card className="mb-4">
      <CardBody className="d-flex flex-row justify-content-between card-header-low-border">
        <h3 className="mb-0" style={{ fontSize: '1.125rem' }}>
          <i className="fas fa-briefcase mr-2" />
          Cluster Resources
        </h3>
      </CardBody>
      <CardBody className="px-0">
        {!fetchingClusterResources && clusterResources ? (
          <Table borderless size="sm" className="mb-0">
            <tbody className="table-left-columns">
              <tr>
                <td>Total</td>
                <td>{clusterResources.nodes} Devices</td>
              </tr>
              <tr>
                <td>Total CPU</td>
                <td>{clusterResources.cpu} Cores</td>
              </tr>
              <tr>
                <td>Total RAM</td>
                <td>{clusterResources.ram} Gb</td>
              </tr>
              <tr>
                <td>Total Storage</td>
                <td>{clusterResources.disk} Gb</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <div className="pl-2 pr-2">
            <Skeleton height={24} count={4} />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default ClusterResources;
