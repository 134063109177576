import React from 'react';

import AddTalosProvider from '../../contexts/TalosCreationContext';
import TalosEditPage from './TalosEditPage';

const TalosEditPageWithContext = props => {
  return (
    <AddTalosProvider>
      <TalosEditPage {...props} />
    </AddTalosProvider>
  );
};

export default TalosEditPageWithContext;
