export default {
  org_info: {
    tenantname: {
      val: '',
      title: 'Name',
    },
    type: {
      val: '',
      title: 'Type',
    },
    created_at: {
      val: '',
      title: 'Created on',
    },
    status: {
      val: '',
      title: 'Account Status',
    },
  },
  billing_info: {
    type: {
      val: undefined,
      title: 'State',
    },
    name: {
      val: undefined,
      title: 'Billing Plan',
    },
    resource_calculation_name: {
      val: undefined,
      title: 'Resource Calculation',
    },
    billing_frequency_name: {
      val: undefined,
      title: 'Billing Frequency',
    },
    currency_code: {
      val: undefined,
      title: 'Currency',
    },
  },
  billing_plan_state: {
    trial: {
      val: undefined,
    },
    state: {
      val: undefined,
    },
  },
};
