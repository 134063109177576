import React, { createContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import { handleDateConversion } from '../../utils/timeConversion';
import axios from '../../axiosInstance';
import { initDates, DEFAULT_SIZE_PER_PAGE } from '../../utils/constants';

const OrganizationContext = createContext({});

const OrganizationProvider = ({ children, location }) => {
  const [resourcesFetching, setResourcesFetching] = useState(false);
  const [connectedServices, setConnectedServices] = useState(null);
  const [hvs, setHvs] = useState(null);
  const [tenants, setTenants] = useState(null);
  const [devices, setDevices] = useState(null);
  const [tenantId, setTenantId] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [tenantName, setTenantName] = useState(null);
  const [importableDevices, setImportableDevices] = useState(null);
  const [networks, setNetworks] = useState(null);
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false);
  const [modalOpen, toggleModalItem] = useState({
    addNetwork: false,
    addDevice: false,
  });
  const [subnets, setSubnets] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [fullList, setFullList] = useState(null);
  const [availDevices, setAvailDevices] = useState(null);
  const [dateRange, setDateRange] = useState(initDates);
  const [reports, setReports] = useState(null);
  const [reportsFetching, setReportsFetching] = useState(false);
  const [devicesPaginationData, setDevicesPaginationData] = useState([0, DEFAULT_SIZE_PER_PAGE, 1]);
  const [searchData, setSearchData] = useState(null);
  const [fetchingDevices, setFetchingDevices] = useState(false);
  const [customerType, setCustomerType] = useState(2);
  const [isOrgDeleted, setIsOrgDeleted] = useState(null);

  const [reportDeviceList, setReportDeviceList] = useState([]);
  const [reportOrganizationList, setReportOrganizationList] = useState([]);
  const [usedNetworks, setUsedNetworks] = useState(null);
  const [fetchingNetworks, setFetchingNetworks] = useState(false);

  useEffect(() => {
    const modalObj = queryString.parse(location.search);
    toggleModalItem({
      addNetwork: false,
      addDevice: false,
    });
    if (modalObj && Object.keys(modalObj).length) {
      toggleModalItem({
        ...modalOpen,
        [modalObj.modal]: true,
      });
    }
  }, [location]);

  useEffect(() => {
    if (tenantId) {
      fetchDevicesBackEndPagination(5, 1);
    }
  }, [searchData]);

  useEffect(() => {
    if (usedNetworks && reportOrganizationList && reportOrganizationList.ressourceData) {
      const resData = reportOrganizationList.ressourceData;

      resData.map(tenant => {
        tenant.networkTotalPrice = usedNetworks.network.filter(net => net.tenantId === tenant.tenantId)[0].totalPrice;

        tenant.networkUpgradePrice = usedNetworks.network.filter(
          net => net.tenantId === tenant.tenantId
        )[0].upgradePrice;

        return tenant;
      });
    }
  }, [reportOrganizationList, usedNetworks]);

  const changeTenantId = id => {
    setTenantId(id);
    setConnectedServices(false);
    setDevices(null);
    setHvs(null);
    setNetworks(null);
    setTenants(null);
    setSubnets(null);
    setTemplates(null);
    setFullList(null);
    setAvailDevices(null);
    setTenantData(null);
    setTenantName(null);
  };

  const fetchCloudList = async () => {
    if (!tenantId) {
      return;
    }
    const listResponse = await axios.get(`/api/user/hv/list/${tenantId}`);
    const data = listResponse ? listResponse.data : {};
    setResourcesFetching(true);
    for (let i = 0; i < data.length; i++) {
      const hvType = data[i].hv_type;
      const resourcesResponce = await axios.get(`api/user/cloud/resources`, {
        params: {
          cloudId: data[i].id,
          tenantIdentifier: tenantId,
        },
      });
      if (!resourcesResponce || !resourcesResponce.data) {
        continue;
      }
      const { hdd = {}, ssd = {}, storage = {}, cpu = {}, ram = {}, status = {} } = resourcesResponce.data;
      data[i] = {
        ...data[i],
        storage:
          hvType !== 'private'
            ? { hdd: hdd ? hdd.count : 'N/A', ssd: ssd ? ssd.count : 'N/A' }
            : { storage: storage ? storage.count : 'N/A' },
        cpu: cpu.count,
        ram: ram.count,
        status: status === 'green' ? 'Healthy' : 'Unhealthy',
      };
      if (i === data.length - 1) {
        setResourcesFetching(false);
      }
    }
    setResourcesFetching(false);
    setConnectedServices(data);
  };

  const fetchHvs = async () => {
    if (!tenantId) {
      return;
    }
    const res = await axios.get(`/api/user/hv/list/${tenantId}`);
    if (res && res.data) {
      setHvs(res.data || []);
      return res.data || [];
    }
    return [];
  };

  const fetchTenants = () => {
    axios
      .get('api/user/tenants/list?with=sharedNetworks')
      .then(res => {
        if (res && res.data) {
          const tenants = res.data.map(tenant => ({
            name: tenant.name,
            tid: tenant.tid,
            shareNetworks: tenant.shared_networks,
          }));
          setTenants(tenants);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const fetchTenantData = tenantId => {
    axios.get('api/user/tenant/' + tenantId).then(res => {
      if (res && res.data) {
        setTenantData(res.data);
      }
    });
  };

  const fetchTenantName = tenantId => {
    axios.get(`api/user/tenant/name/${tenantId}`).then(res => {
      if (res && res.data) {
        setTenantName(res.data);
      }
    });
  };

  const fetchDevicesPagination = () => {
    if (!tenantId) {
      return;
    }
    const sizePerPage = devicesPaginationData[1];
    axios
      .get(`api/user/devices/${tenantId}?per_page=${sizePerPage}`)
      .then(res => {
        if (res && res.data) {
          const devicesDateConverted = handleDateConversion(res.data.data);
          setDevices(devicesDateConverted);
          setDevicesPaginationData([res.data.meta.total, sizePerPage, 1]);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const fetchDevicesBackEndPagination = (devicesPerPage, page, sortType, sortOrder) => {
    setFetchingDevices(true);
    axios
      .get(`api/user/devices/${tenantId}`, {
        params: {
          per_page: devicesPerPage,
          page,
          search: searchData,
          sort_by: sortType,
          order: sortOrder,
        },
      })
      .then(res => {
        if (res && res.data) {
          const devicesDateConverted = handleDateConversion(res.data.data);
          setDevices(devicesDateConverted);
          setDevicesPaginationData([res.data.meta.total, devicesPerPage, page]);
          setFetchingDevices(false);
        }
      })
      .catch(e => {
        console.warn(e.message);
        setFetchingDevices(false);
      });
  };

  const fetchNetworks = () => {
    if (!tenantId) {
      return;
    }
    axios
      .get('api/user/' + tenantId + '/networks')
      .then(res => {
        if (res && res.data) {
          if ('networks' in res.data) setNetworks(res.data.networks || []);
          if ('subnets' in res.data) setSubnets(res.data.subnets);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const onDeviceImport = ({ hvId, vms }) => {
    axios
      .post('/api/user/vmlist/foreign', {
        hvId,
        vms,
      })
      .then(res => {
        if (res) {
          // if new device was imported we need to update data
          // so we make a request
          fetchTenants();
        }
      });
  };

  const onImportableDevice = id => {
    axios
      .get('/api/user/vmlist/foreign/' + id)
      .then(res => res && res.data && setImportableDevices(Object.values(res.data)));
  };

  const onNetworksImport = callback => networks => {
    const networksUpdate = networks.networks;
    axios.post('/api/user/networks/import', { networks: networksUpdate }).then(res => {
      callback();
    });
  };

  const onMultiSelectAction = (action, selected, password) => {
    axios.post('/api/user/vmlist/multiple/' + action, {
      vmIds: selected,
      password,
    });
  };

  const toggleUpgradeModal = () => {
    setUpgradeModalIsOpen(!upgradeModalIsOpen);
  };

  const fetchTemplates = () => {
    if (!tenantId) {
      return;
    }
    axios
      .get('/api/user/get-templates/' + tenantId)
      .then(res => {
        if (res) {
          setTemplates(res.data ? res.data : []);
          setFullList([
            ...res.data.templates_custom.map(el => ({
              ...el,
              subtype: 'templates_custom',
            })),
            ...res.data.iso_custom.map(el => ({
              ...el,
              subtype: 'iso_custom',
            })),
            ...res.data.scripts_custom.map(el => ({
              ...el,
              subtype: 'scripts_custom',
            })),
          ]);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const fetchAvailDevices = (tenantId = null) => {
    axios
      .get('/api/user/devices-by-tenant' + (tenantId ? `/${tenantId}` : ''))
      .then(res => {
        if (res && res.data) {
          const availDev = res.data.map(el => ({
            value: el.localVmId,
            label: el.vmDisplayName,
          }));
          setAvailDevices(availDev);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const onDeleteTemplate = (subtype, id) => {
    setFullList(fullList.filter(el => el.id !== id));
  };

  const onDateChange = date => {
    setDateRange(date);
  };

  const fetchReports = childTenantId => {
    if (!tenantId) {
      return;
    }
    setReportsFetching(true);

    const id = childTenantId || tenantId;

    const payload = {
      startDate: dateRange[0].toDateString(),
      endDate: dateRange[1].toDateString(),
      customerType,
    };
    fetchReportsCall(id, payload, setReports);
  };

  const getNetworkHandler = () => {
    setFetchingNetworks(true);
    const payload = {
      startDate: dateRange[0].toDateString(),
      endDate: dateRange[1].toDateString(),
    };
    axios
      .post('/api/user/billing/reports/networks/all', payload)
      .then(res => {
        if (res && res.data) {
          setUsedNetworks(res.data);
          setFetchingNetworks(false);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const fetchReportsByType = (childTenantId, entityType) => {
    if (!tenantId) {
      return;
    }
    setReportsFetching(true);
    entityType === 'devices' ? setReportDeviceList([]) : setReportOrganizationList([]);

    const id = childTenantId || tenantId;

    const payload = {
      startDate: dateRange[0].toDateString(),
      endDate: dateRange[1].toDateString(),
      customerType,
      entity: entityType,
      isRegularReport: true,
    };
    fetchReportsCall(id, payload, entityType === 'devices' ? setReportDeviceList : setReportOrganizationList);
  };

  const fetchReportsCall = (id, payload, setResult) => {
    axios
      .post('/api/user/billing/reports/' + id, payload)
      .then(res => {
        if (res && res.data) {
          setResult({
            pricing_overview: res.data.pricingOverview,
            ressourceData: res.data.resourceData,
            totalcosts: res.data.totalCosts,
            discount: res.data.totalDiscount,
            partnerDiscount: res.data.totalPartnerDiscount,
            subtotal: res.data.subtotal,
            vat: res.data.vat || 0,
          });
          setReportsFetching(false);
        }
      })
      .catch(e => console.warn(e.message));
  };

  return (
    <OrganizationContext.Provider
      value={{
        devices,
        networks,
        subnets,
        templates,
        fullList,
        importableDevices,
        availDevices,
        connectedServices,
        resourcesFetching,
        tenantId,
        tenantData,
        tenantName,
        tenants,
        customerType,
        setCustomerType,
        upgradeModalIsOpen,
        hvs,
        modalOpen,
        dateRange,
        reports,
        reportsFetching,
        reportDeviceList,
        reportOrganizationList,
        fetchCloudList,
        fetchHvs,
        fetchDevicesPagination,
        fetchDevicesBackEndPagination,
        devicesPaginationData,
        searchData,
        setSearchData,
        setDevices,
        fetchTenants,
        fetchNetworks,
        fetchTenantData,
        fetchTenantName,
        fetchTemplates,
        fetchReports,
        getNetworkHandler,
        fetchingNetworks,
        fetchReportsByType,
        fetchAvailDevices,
        onDateChange,
        changeTenantId,
        onDeviceImport,
        onImportableDevice,
        onNetworksImport,
        onMultiSelectAction,
        onDeleteTemplate,
        toggleUpgradeModal,
        fetchingDevices,
        isOrgDeleted,
        setIsOrgDeleted,
        setDevicesPaginationData,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export default withRouter(OrganizationProvider);
export { OrganizationContext };
