import React from 'react';
import { FormGroup, FormFeedback } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';

import countries from '../../utils/countries';
import countriesWithVat from '../Onboarding/TrialSelect/CountriesWithVat';
import BillingPlan from '../BillingPlan';

const BillingAddress = ({ billingAddress, setBillingAddress, vatInputVisible, setVatInputVisible }) => {
  const handleInputChange = (key, value) => {
    setBillingAddress({ ...billingAddress, [key]: value });
  };
  let countriesArray = [];
  for (const country in countries) {
    countriesArray = countriesArray.concat({
      label: countries[country] || 'Select country',
      value: countries[country],
    });
  }

  return (
    <>
      <p style={{ fontSize: 16, fontWeight: 'normal', color: '#000' }}>Billing Address</p>

      <div className="row">
        <FormGroup className="col-6">
          <label htmlFor="address-line-1">
            <span className="bold">Address Line 1 *</span>
          </label>
          <input
            value={billingAddress.address}
            onChange={e => handleInputChange('address', e.target.value)}
            type="text"
            id="address-line-1"
            className="form-control"
            required
          />
        </FormGroup>
        <FormGroup className="col-6">
          <label htmlFor="address-line-2">
            <span className="bold">Address Line 2</span>
          </label>
          <input
            value={billingAddress.address2}
            onChange={e => handleInputChange('address2', e.target.value)}
            type="text"
            id="address-line-2"
            className="form-control"
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-3">
          <label htmlFor="city">
            <span className="bold">City *</span>
          </label>
          <input
            value={billingAddress.city}
            onChange={e => handleInputChange('city', e.target.value)}
            type="text"
            id="city"
            className="form-control"
            required
          />
        </FormGroup>
        <FormGroup className="col-3">
          <label htmlFor="postcode">
            <span className="bold">Postcode *</span>
          </label>
          <input
            value={billingAddress.postcode}
            onChange={e => handleInputChange('postcode', e.target.value)}
            type="text"
            id="postcode"
            className="form-control"
            required
          />
        </FormGroup>
        <FormGroup className="col-3">
          <label htmlFor="country">
            <span className="bold">Country *</span>
          </label>
          <Select
            data-cy="billing-address-modal-country"
            name="country"
            styles={{
              menu: provided => ({
                ...provided,
                zIndex: 100,
              }),
            }}
            defaultValue={{
              label: 'Select country',
              value: undefined,
            }}
            value={
              billingAddress.country
                ? {
                    label: billingAddress.country,
                    value: billingAddress.country,
                  }
                : undefined
            }
            options={countriesArray}
            onChange={val => {
              setVatInputVisible(countriesWithVat.indexOf(val.value) !== -1);
              handleInputChange('country', val.value);
            }}
          />
          <FormFeedback>This field is required</FormFeedback>
        </FormGroup>
        <FormGroup className="col-3" style={!vatInputVisible ? { display: 'none' } : {}}>
          <label htmlFor="postcode">
            <span className="bold">VAT</span>
          </label>
          <input
            value={billingAddress.vat}
            onChange={e => handleInputChange('vat', e.target.value)}
            type="text"
            id="vat"
            className="form-control"
            required
          />
        </FormGroup>
      </div>
    </>
  );
};

BillingPlan.propTypes = {
  billingAddress: PropTypes.object,
  setBillingAddress: PropTypes.func,
};

export default BillingAddress;
