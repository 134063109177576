import React from 'react';
import Select from 'react-select';
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import Creatable from 'react-select/creatable';

const SelectRequired = ({
  required,
  stopPropagation,
  value,
  options,
  onChange,
  onInputChange,
  id,
  className,
  label,
  key,
  isMulti,
  placeholder,
  pattern,
  wrapperClassName,
  dataCy,
  defaultValue,
  portalized,
  addOnEnter,
  validationMessage,
  ...props
}) => {
  const handleEnterKeyDown = e => {
    const enter_keys = ['Enter', 'Tab'];

    if (enter_keys.includes(e.key)) {
      e.preventDefault();
      if (!e.target.value.match(pattern)) {
        return;
      }

      onChange({ value: e.target.value, label: e.target.value }, { action: 'select-option' });
      if (e.key === 'Enter') {
        e.target.blur();
      }
    }
  };
  const inputValue = isMulti ? (value && value.length > 0 ? true : '') : value && value.value;

  return (
    <FormGroup onClick={e => stopPropagation && e.stopPropagation()} check className={wrapperClassName}>
      {label && <label htmlFor={id}>{label}</label>}
      <Select
        id={id}
        key={key}
        className={className}
        required={required}
        defaultValue={defaultValue}
        value={value ? value : !isMulti && !placeholder ? { value: '', label: 'Select entity' } : ''}
        onBlur={
          addOnEnter
            ? e => {
                const value = e.target.value;
                if (value && value.length > 0 && (!pattern || value.match(pattern))) {
                  onChange({ value, label: value }, { action: 'select-option' });
                }
              }
            : null
        }
        onChange={onChange}
        onInputChange={onInputChange}
        onKeyDown={addOnEnter ? e => handleEnterKeyDown(e) : null}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        data-cy={`select-${dataCy}`}
        aria-label={`select-${dataCy}`}
        {...(portalized
          ? {
              // necessary to expand over modal
              styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
              menuPortalTarget: document.body,
              // to correctly apply nightmode to portaled menus we use classNamePrefix
              classNamePrefix: 'react-select-portaled',
            }
          : null)}
        {...props}
      />
      <Input
        data-cy={`input-${dataCy}`}
        tabIndex={-1}
        autoComplete="off"
        pattern={isMulti ? undefined : pattern}
        style={{ opacity: 0, height: 0, padding: 0, border: 0 }}
        value={inputValue || ''}
        required={required}
        readOnly
      />
      {validationMessage && <FormFeedback className="d-block input__danger-message">{validationMessage}</FormFeedback>}
    </FormGroup>
  );
};

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: '1000',
  }),
};

const CreatableRequired = ({
  required,
  value,
  options,
  stopPropagation,
  onChange,
  id,
  className,
  label,
  key,
  isMulti,
  pattern,
  wrapperClassName,
  dataCy,
  portalized,
  validationMessage,
  ...props
}) => (
  <FormGroup className={wrapperClassName} check onClick={e => stopPropagation && e.stopPropagation()}>
    {label && <label htmlFor={id}>{label}</label>}
    <Creatable
      id={id}
      isClearable
      styles={customStyles}
      className={className}
      value={value}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      formatCreateLabel={inputValue => 'Submit "' + inputValue + '"'}
      {...(portalized
        ? {
            // necessary to expand over modal
            styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
            menuPortalTarget: document.body,
            // to correctly apply nightmode to portaled menus we use classNamePrefix
            classNamePrefix: 'react-select-portaled',
          }
        : null)}
      {...props}
    />
    <Input
      data-cy={dataCy ? dataCy : 'not-set'}
      tabIndex={-1}
      autoComplete="off"
      onChange={e => onChange({ value: e.target.value, label: e.target.value }, { action: 'new-option' })}
      pattern={pattern ? (isMulti ? (value ? `\\b(${pattern} ){${value.length}}$` : undefined) : pattern) : null}
      style={{ opacity: 0, height: 0, padding: 0, border: 0 }}
      value={
        isMulti
          ? value && value.length && value.reduce((accum, el) => accum + el.value + ' ', '')
          : value && value.value
      }
      required={required}
    />
    {validationMessage && <FormFeedback className="d-block input__danger-message">{validationMessage}</FormFeedback>}
  </FormGroup>
);

export { CreatableRequired };

export default SelectRequired;
