import React from 'react';
import { Button, Card } from 'reactstrap';

const Error = ({ title, validationError, setStep, step, buttonText }) => {
  return (
    <Card className="mt">
      <div className="d-flex justify-content-center">
        <h3 className="p-4">{title}</h3>
      </div>
      <div className="d-flex justify-content-center p-3 badge-danger mb-3">
        <p className="m-0 text-center">
          ERROR! <br />
          {validationError}
        </p>
      </div>
      <div className="p-4">
        <div className="d-flex justify-content-md-between">
          <Button className="btn-danger w-40" type="button" onClick={() => setStep(step)}>
            {buttonText}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default Error;
