import { getInitialValue } from './utils';

export const OSs = [
  {
    value: 'templates_linux',
    icon: 'fab fa-linux',
    name: 'Linux',
  },
  {
    value: 'templates_windows',
    icon: 'fab fa-windows',
    name: 'Windows',
  },
  {
    value: 'template_free_bsd',
    icon: 'fab fa-freebsd',
    name: 'FreeBSD',
  },
  {
    value: 'template_vm_ware',
    icon: 'fas fa-cube',
    name: 'VMWare',
  },
];

export const osOptions = {
  templates_linux: [
    { label: 'CentOS 7 x64' },
    { label: 'CentOS 6 x64' },
    { label: 'Debian 9 x64' },
    { label: 'Debian 10 x64' },
    { label: 'Ubuntu 16 x64' },
    { label: 'Ubuntu 18 x64' },
    { label: 'Fedora 31 x64' },
    { label: 'Citrix Hypervisor (XenServer 8)' },
  ],
  template_free_bsd: [{ label: 'FreeBSD 12 x64' }, { label: 'FreeBSD 11 x64' }],
  templates_windows: [
    { label: 'Windows Server 2016 Standard ', price: 30 },
    { label: 'Windows Server 2016 Datacenter ', price: 120 },
    { label: 'Windows Server 2019 R2 Standard ', price: 30 },
    { label: 'Windows Server 2019 R2 Datacenter ', price: 120 },
  ],
  template_vm_ware: [{ label: 'VMware ESXi 6.5 ' }, { label: 'VMware ESXi 6.7 ' }],
};

export const serverControlPanelOptions = [
  { label: 'Operating System only ' },
  { label: 'Plesk Obsidian Web Host Edition, unl. Domains ', price: 39.0 },
  { label: 'Plesk Obsidian Web Pro Edition, 30 Domains ', price: 15.0 },
  { label: 'Plesk Obsidian Web Admin Edition, 10 Domains ', price: 9.9 },
];

export const billing_frequencies = [
  {
    label: '129.00 CHF Monthly (200.00 CHF Setup Fee)',
    price: 129,
    setupPrice: 200,
    month: 1,
    adverb: 'Monthly',
  },
  {
    label: '387.00 CHF Quarterly (No Setup Fee)',
    price: 387,
    month: 3,
    adverb: 'Quarterly',
    setupPrice: 0,
  },
  {
    label: '699.00 CHF Semi-Annually (No Setup Fee)',
    price: 699,
    month: 6,
    adverb: 'Semi-Annually',
    setupPrice: 0,
  },
];

export const cpuOptions = [
  {
    label: 'Single Intel Xeon E3 Quad Core CPU',
    price: 0,
    dual: false,
    cores: 4,
  },
  {
    label: 'Single Intel Xeon Silver Eight Core CPU',
    price: 60,
    dual: false,
    cores: 8,
  },
  {
    label: 'Dual Intel Xeon Silver Eight Core CPU ',
    price: 160,
    dual: true,
    cores: 8,
  },
  {
    label: 'Single Intel Xeon Silver Ten Core CPU ',
    price: 100,
    dual: false,
    cores: 10,
  },
  {
    label: 'Dual Intel Xeon Silver Ten Core CPU ',
    price: 240,
    dual: true,
    cores: 10,
  },
  {
    label: 'Single Intel Xeon Silver Twelve Core CPU ',
    price: 180,
    dual: false,
    cores: 12,
  },
  {
    label: 'Dual Intel Xeon Silver Twelve Core CPU ',
    price: 390,
    dual: true,
    cores: 12,
  },
];

export const ipmiOptions = [{ label: 'included' }];
export const powerSuppliesOptions = [{ label: '1 PSU' }, { label: '2 PSUs', price: 30 }];

export const memoryOptions = [
  { value: 8 },
  { value: 16, price: 15 },
  { value: 32, price: 50 },
  { value: 64, price: 100 },
  { value: 96, price: 150 },
  { value: 128, price: 200 },
  { value: 192, price: 300 },
  { value: 256, price: 400 },
  { value: 384, price: 600 },
  { value: 512, price: 800 },
  { value: 768, price: 1200 },
  { value: 1024, price: 1600 },
];

export const raidOptions = [
  { label: 'No RAID' },
  { label: 'Software RAID 1' },
  {
    label: 'RAID Controller max. 4 Hard drives (RAID 0,1,5,6,10)',
    price: 55,
  },
  {
    label: 'RAID Controller max. 8 Hard drives (RAID 0,1,5,6,10)',
    price: 85,
  },
];

export const mainHddOptions = [
  { label: '1TB SATA' },
  { label: '2TB SATA', price: 10 },
  { label: '6TB SATA', price: 30 },
  { label: '8TB SATA', price: 50 },
  { label: '10TB SATA', price: 70 },
  { label: '240GB Enterprise SSD', price: 10 },
  { label: '480GB Enterprise SSD', price: 25 },
  { label: '960GB Enterprise SSD', price: 50 },
  { label: '1.92TB Enterprise SSD', price: 100 },
  { label: '3.84TB Enterprise SSD', price: 160 },
  { label: '7.68 Enterprise SSD', price: 260 },
];

export const additionalHddOptions = [
  { label: 'Empty' },
  { label: '1TB SATA', price: 15 },
  { label: '2TB SATA', price: 25 },
  { label: '6TB SATA', price: 40 },
  { label: '8TB SATA', price: 60 },
  { label: '10TB SATA', price: 80 },
  { label: '240GB Enterprise SSD', price: 25 },
  { label: '480GB Enterprise SSD', price: 40 },
  { label: '960GB Enterprise SSD', price: 70 },
  { label: '1.92TB Enterprise SSD', price: 120 },
  { label: '3.84TB Enterprise SSD', price: 180 },
  { label: '7.68 Enterprise SSD', price: 260 },
];

export const ipsOptions = {
  max: 3,
  min: 1,
};

export const uplinksOptions = [{ label: '1 Uplink' }, { label: '2 Uplinks', price: 20 }];

export const uplinkOptions = [
  {
    label: 'Fair use traffic limit',
    value: 'Fair use traffic limit',
  },
  { label: 'Burstable (95th % CDR)', value: 'Burstable (95th % CDR)' },
];

export const burstableOptions = [
  { label: '1Gbit Uplink, 200 Mbps 95% CDR', price: 150 },
  { label: '1Gbit Uplink, 300 Mbps 95% CDR', price: 250 },
  { label: '1Gbit Uplink, 400 Mbps 95% CDR', price: 350 },
  { label: '1Gbit Uplink, 600 Mbps 95% CDR', price: 450 },
  { label: '1Gbit Uplink, 800 Mbps 95% CDR', price: 550 },
  { label: '10Gbit Uplink, 400 Mbps 95% CDR', price: 450 },
  { label: '10Gbit Uplink, 600 Mbps 95% CDR', price: 550 },
  { label: '10Gbit Uplink, 800 Mbps 95% CDR', price: 650 },
  { label: '10Gbit Uplink, 1000 Mbps 95% CDR', price: 750 },
  { label: '10Gbit Uplink, 2000 Mbps 95% CDR', price: 1400 },
  { label: '10Gbit Uplink, 3000 Mbps 95% CDR', price: 2100 },
  { label: '10Gbit Uplink, 4000 Mbps 95% CDR', price: 2800 },
  { label: '10Gbit Uplink, 5000 Mbps 95% CDR', price: 3500 },
];

export const class1Options = [
  { label: '100 Mbps Uplink, No traffic limit ' },
  { label: '1000 Mbps Uplink, Fair use traffic limit', price: 20 },
];

export const firewallOptions = [
  { label: 'None' },
  {
    label: 'Managed Firewall Service 3, 1-3 Servers (200.00 CHF Setup Fee)',
    price: 100,
    setupPrice: 200,
  },
  {
    label: 'Managed Firewall Service 10, 4-10 Servers (300.00 CHF Setup Fee)',
    price: 200,
    setupPrice: 300,
  },
];

export const backupsOptions = [
  { label: 'No Backupspace' },
  { label: '50 GB ', price: 10 },
  { label: '100 GB ', price: 20 },
  { label: '200 GB ', price: 30 },
  { label: '400 GB ', price: 50 },
  { label: '600 GB ', price: 70 },
  { label: '1000 GB ', price: 100 },
  { label: '3000 GB ', price: 300 },
  { label: '5000 GB ', price: 500 },
];

export const templates = {
  template_vm_ware: [
    { id: 1, name: 'server1' },
    { id: 2, name: 'server2' },
  ],
  templates_linux: [
    { id: 1, name: 'server1' },
    { id: 2, name: 'server2' },
  ],
  templates_windows: [
    { id: 1, name: 'server1' },
    { id: 2, name: 'server2' },
  ],
  template_free_bsd: [
    { id: 1, name: 'server1' },
    { id: 2, name: 'server2' },
  ],
};

export const ipPrice = 9;

export const initialState = {
  cpu_options: getInitialValue(cpuOptions[0]),
  ipmi: getInitialValue(ipmiOptions[0]),
  power_supplies: getInitialValue(powerSuppliesOptions[0]),
  server_control_panel: getInitialValue(serverControlPanelOptions[0]),
  ram: getInitialValue({
    label: memoryOptions[0].value + 'GB',
    value: memoryOptions[0],
  }),
  raid: getInitialValue(raidOptions[0]),
  uplink: getInitialValue(uplinkOptions[0]),
  uplink_amount: getInitialValue(uplinksOptions[0]),
  ips: ipsOptions.min,
  hdd: {
    'HardDisk 1 (Main)': {
      label: mainHddOptions[0].label,
      value: mainHddOptions[0],
    },
  },
  firewall: getInitialValue(firewallOptions[0]),
  backups: getInitialValue(backupsOptions[0]),
};

export const valueToLabel = {
  'Billing Period': 'Billing Period / Base Fee',
  billing_frequency: 'Billing Period / Base Fee',
  cpu_options: 'CPU Options',
  ipmi: 'IMPI',
  power_supplies: 'Power Supply',
  os: 'OS',
  server_control_panel: 'Server Control Panel',
  ram: 'RAM',
  raid: 'RAID',
  hdd: 'Hard Disks',
  ips: 'IPs',
  uplink: 'Uplink',
  uplink_amount: 'Uplink Amount',
  class_1_traffic: 'Traffic',
  burstable: 'Burstable (95th % CDR)',
  firewall: 'Security',
  backups: 'Backup',
};
