import React, { useContext, useEffect } from 'react';

import { AuthContext } from '../contexts/AuthContext';

const ThirdPartyAuthSuccess = () => {
  const { isAuth } = useContext(AuthContext);
  useEffect(() => {
    const url = `/${isAuth ? 'user-account' : 'login'}${window.location.search}&authsuccess=true`;
    if (window.opener) {
      window.opener.open(url, '_self');
      window.opener.focus();
      window.close();
    } else window.open(url, '_self');
  }, []);

  return <div>Authorization successful!</div>;
};

export default ThirdPartyAuthSuccess;
