import React, { useEffect, useState, useContext } from 'react';
import { Card } from 'reactstrap';
import DropIn from 'braintree-web-drop-in-react';
import { v4 as uuidv4 } from 'uuid';

import CustomizedSkeletonComponent from '../UI/CustomizedSkeletonComponent';
import BillingAddress from './BillingAddress';
import { AuthContext } from '../contexts/AuthContext';
import DemoOptionsContext from '../contexts/DemoOptionsContext';
import { PushNotificationContext } from '../contexts/PushNotificationContext';
import axios from '../../axiosInstance';

const PaymentMethodSelectCard = props => {
  const [clientToken, setClientToken] = useState(null);
  const [nonce, setNonce] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [billingAddressVisible, setBillingAddressVisible] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(props.requireBillingAddress);
  const [vatInputVisible, setVatInputVisible] = useState(false);
  const { user, setUserInfo } = useContext(AuthContext);
  const { billingAddress, setBillingAddress, upgradeToFullTrial } = useContext(DemoOptionsContext);
  const { pushNotification } = useContext(PushNotificationContext);
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    initClientToken();
    if (props.requireBillingAddress) {
      (async () => {
        await setUserInfo();
        initBillingAddress();
      })();
    }
  }, []);

  useEffect(() => {
    if (props.requireBillingAddress) {
      initBillingAddress();
    }
  }, [user]);

  useEffect(() => {
    const enabled = checkBillingAddress(billingAddress);
    setButtonDisabled(!enabled);
  }, [billingAddress]);

  const checkBillingAddress = address => {
    let res = true;
    for (const key in address) {
      if (key !== 'address2' && key !== 'vat' && !address[key]) {
        res = false;
        break;
      }
    }
    return res;
  };

  const initClientToken = () => {
    axios
      .get(`api/user/payment?withSave=1`)
      .then(res => {
        if (res && res.data) {
          setClientToken(res.data);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const initBillingAddress = () => {
    const { address, address2, city, postcode, country, vat } = user.tenant;
    const data = { address, address2, city, postcode, country, vat };
    setBillingAddress(data);
    setBillingAddressVisible(requireBillingAddress && !checkBillingAddress(data));
  };

  const addPayment = async () => {
    try {
      const response = await instance.requestPaymentMethod();
      setNonce(response.nonce);
    } catch (e) {
      console.warn(e.message);
    }
  };

  const addPaymentWithUpgrade = async () => {
    if (billingAddressVisible) {
      const check = checkBillingAddress(billingAddress);
      if (!check) {
        pushNotification({
          data: {
            type: 'danger',
            name: 'Billing address is required',
          },
          id: uuidv4(),
        });
        return;
      }
      const { vat, country } = billingAddress;
      try {
        await axios.post('api/user/validateVatNumber', { vat, country });
      } catch (e) {
        console.warn(e.message);
      }
    }

    await addPayment();

    if (billingAddressVisible) {
      await upgradeToFullTrial(false);
      setBillingAddressVisible(false);
    }
  };

  const { boxClass, requireBillingAddress } = props;
  return (
    <Card className={`card-sm min-height-200 ${boxClass}`}>
      {!clientToken ? (
        <div>
          <CustomizedSkeletonComponent containerClassName="px-3 py-4" height={100} />
        </div>
      ) : (
        <div className="my-3">
          {requireBillingAddress && billingAddressVisible ? (
            <div className="mb-3">
              <BillingAddress
                billingAddress={billingAddress}
                setBillingAddress={billingAddress => setBillingAddress(billingAddress)}
                vatInputVisible={vatInputVisible}
                setVatInputVisible={setVatInputVisible}
              />
            </div>
          ) : null}

          <DropIn
            key={props.dropInReload}
            preselectVaultedPaymentMethod={false}
            onNoPaymentMethodRequestable={args => {
              setNonce(false);
              setShowButton(true);
            }}
            onPaymentMethodRequestable={args => {
              setShowButton(true);
              if (args.paymentMethodIsSelected) {
                addPayment();
                if (props.onPaymentMethodSelected) {
                  props.onPaymentMethodSelected();
                }
              }
            }}
            options={{
              vaultManager: true,
              authorization: clientToken,
              paypal: {
                flow: 'vault',
                buttonStyle: {
                  color: 'blue',
                  shape: 'rect',
                  size: 'medium',
                },
              },
            }}
            onInstance={instance => {
              setInstance(instance);
              return instance;
            }}
          />
          {showButton &&
            (nonce ? (
              <div />
            ) : (
              <button type="button" className="btn btn-blue-dark w-100 my-3" onClick={addPaymentWithUpgrade}>
                Add Payment
              </button>
            ))}
        </div>
      )}
    </Card>
  );
};

export default PaymentMethodSelectCard;
