// user permissions constants

export const userPermissions = {
  allowViewVirtualMachines: false,
  allowCreateVirtualMachine: false,
  allowViewVirtualMachine: false,
  allowViewVirtualMachineReports: false,
  allowManageVirtualMachineStatus: false,
  allowDeleteVirtualMachine: false,
  allowManageVirtualMachineBackups: false,
  allowManageVirtualMachineReplications: false,
  allowManageVirtualMachineResources: false,
  allowManageVirtualMachineSnapshots: false,
  allowManageVirtualMachineScripts: false,
  allowManageVirtualMachineMonitoring: false,
  allowManageVirtualMachineInfo: false,
  allowManageVirtualMachineAutoshutdown: false,
  allowImportVirtualMachine: false,
  allowViewNetworking: false,
  allowViewNetwork: false,
  allowImportNetwork: false,
  allowCreateNetwork: false,
  allowCreateStretchedNetwork: false,
  allowManageNetwork: false,
  allowViewFirewalls: false,
  allowCreateFirewall: false,
  allowViewFirewall: false,
  allowManageFirewall: false,
  allowViewLoadBalancers: false,
  allowCreateLoadBalancer: false,
  allowViewLoadBalancer: false,
  allowManageLoadBalancer: false,
  allowViewWebApplicationFirewalls: false,
  allowCreateWebApplicationFirewall: false,
  allowViewWebApplicationFirewall: false,
  allowManageWebApplicationFirewall: false,
  allowViewTemplates: false,
  allowManageTemplates: false,
  allowCreateTemplates: false,
  allowViewOrganizations: false,
  allowCreateOrganizations: false,
  allowCreateUsers: false,
  allowDeleteUsers: false,
  allowManageUsers: false,
  allowResetUsersTotpSecrets: false,
  allowManageUsersPermissions: false,
  allowManageOrganizationSecurity: false,
  allowDeleteOrganizations: false,
  allowDisableOrganizations: false,
  allowManageServiceTokens: false,
  allowManageTrialEndDate: false,
  allowViewUsageOverview: false,
  allowViewUsageReports: false,
  allowViewOrganizationReports: false,
  allowManageHostbillId: false,
  allowManageAccountManager: false,
  allowManageCustomerType: false,
  allowManageBankTransfer: false,
  allowLoginAsOtherUser: false,
  allowManageDemoEndDate: false,
  allowManageInvoices: false,
  allowManageOperations: false,
  allowManageSalesDashboard: false,
  allowCreateTrialOrganization: false,
  allowAccessBetaFeatures: false,
  allowManageOrganizationDiscount: false,
  allowManageOrganizationPartnerStatus: false,
  allowViewOrganizationInvoices: false,
  allowViewInvoiceDetails: false,
  allowViewAdminDeviceInformation: false,
  allowManageBillingPlans: false,
  allowManageDisasterRecovery: false,
  allowViewCreditsInfo: false,
  allowManagePaymentMethods: false,
  allowManageVirtualMachineConsole: false,
  allowManageAnnouncements: false,
  allowViewDocuments: false,
  allowAcceptDocuments: false,
  allowManageKubernetes: false,
};

export const optTypes = [
  {
    value: null,
    label: 'None',
  },
  {
    value: 'sms',
    label: 'SMS',
  },
  {
    value: 'totp',
    label: 'TOTP without SMS',
  },
  {
    value: 'totp_with_sms',
    label: 'TOTP or SMS',
  },
];

// reducer actions types names

export const AUTH_LOG_IN_HANDLER = 'AUTH_LOG_IN_HANDLER';
export const UPDATE_SINGLE_PROPERTY = 'UPDATE_SINGLE_PROPERTY';
