import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import clsx from 'clsx';
import Select from '../UI/SelectRequired';
import Skeleton from 'react-loading-skeleton';

const ImportDeviceInfo = ({
  clouds,
  tenants,
  currentTenant,
  currentCloud,
  handleTenantChange,
  handleCloudChange,
  setDeviceName,
  deviceName,
  currentTemplate,
  templates,
  handleTemplateChange,
}) => {
  return (
    <div className="p-4">
      <div className="row no-gutters">
        <FormGroup className={clsx('col-6 pr-md-2 mb-0 border-right')}>
          <Label>Select Tenant</Label>
          {tenants ? (
            <Select
              required
              isDisabled={tenants && tenants.length === 1}
              value={currentTenant}
              placeholder="Select Tenant"
              options={tenants || []}
              dataCy="upload-import-device-tenant-select"
              onChange={handleTenantChange}
            />
          ) : (
            <Skeleton height={35} />
          )}
        </FormGroup>
        <FormGroup className={clsx('col-6 pl-md-2 mb-0')}>
          <Label>Select Cloud</Label>
          {tenants ? (
            <>
              <Select
                required
                isDisabled={!clouds || clouds.length === 1}
                value={(clouds && currentCloud && clouds.find(el => el.value === currentCloud.value)) || ''}
                placeholder="Select Cloud"
                options={clouds}
                dataCy="upload-import-device-cloud-select"
                onChange={handleCloudChange}
              />
            </>
          ) : (
            <Skeleton height={35} />
          )}
        </FormGroup>
      </div>

      <div className="row no-gutters">
        <FormGroup className="col-6 pr-md-2 mb-0 border-right form-group">
          <Label for="display-name">Display name of imported device</Label>
          <Input
            data-cy="add-device-display-name"
            type="text"
            name="displayname"
            id="display-name"
            value={deviceName || ''}
            onChange={event => setDeviceName(event.target.value)}
            autoComplete="off"
            required
          />
          <FormFeedback />
        </FormGroup>
        <FormGroup className="col-6 pl-md-2 mb-0 form-group">
          <Label>Select device type</Label>
          {templates ? (
            <>
              <Select
                required
                value={currentTemplate ? currentTemplate : ''}
                placeholder="Select type"
                options={templates || []}
                dataCy="upload-import-device-template-select"
                onChange={handleTemplateChange}
              />
            </>
          ) : (
            <Skeleton height={35} />
          )}
        </FormGroup>
      </div>

      {currentTemplate && currentTemplate.label === 'Firewall Generic' && (
        <div className="row no-gutters">
          <div className="mt-3 p-3 badge-danger w-100">
            <p className="m-0 text-center">
              Firewalls does not support installing service user. <br />
              You must manually assign corresponding IP addresses and set up the rules for the Firewall through the HTML
              console.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportDeviceInfo;
