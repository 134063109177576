import React, { useContext, useEffect, useState } from 'react';
import ClusterMain from './ClusterMain';
import ClusterResources from './ClusterResources';
import K8sConfiguration from './K8sConfiguration';
import LinkedLoadBalancers from './LinkedLoadBalancers';
import { HeaderContext } from '../../contexts/HeaderContext';
import axios from '../../../axiosInstance';
import ClusterItems from './ClusterItems';
import LastActivitiesTable from '../../UI/Table/LastActivitiesTable';
import { EchoContext } from '../../contexts/EchoContext';
import { AuthContext } from '../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { CONTROL_PLANE_STATUS_HEALTHY, CONTROL_PLANE_STATUS_UNHEALTHY } from '../TalosConstants';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';

const TalosEditPage = () => {
  const [clusterInfo, setClusterInfo] = useState({
    clusterName: 'Unknown',
  });
  const [isSetClusterInfo, setIsSetClusterInfo] = useState(false);
  const [fetchingClusterInfo, setFetchingClusterInfo] = useState(false);

  const [clusterResources, setClusterResources] = useState([]);
  const [fetchingClusterResources, setFetchingClusterResources] = useState(false);

  const [clusterK8sConfig, setClusterK8sConfig] = useState({
    k8sApiEndpoint: 'Unknown',
  });
  const [fetchingClusterConfig, setFetchingClusterConfig] = useState(false);

  const [clusterLoadBalancers, setClusterLoadBalancers] = useState([]);
  const [fetchingClusterLoadBalancers, setFetchingClusterLoadBalancers] = useState(false);

  const [clusterPoolsNodes, setClusterPoolsNodes] = useState([]);
  const [fetchingClusterPoolsNodes, setFetchingClusterPoolsNodes] = useState(false);
  const [clusterControlPlanes, setClusterControlPlanes] = useState([]);
  const [clusterControlResources, setClusterControlResources] = useState({});
  const [fetchingClusterControlPlanes, setFetchingClusterControlPlanes] = useState(false);

  const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState(false);
  const [isDisabledUpgrade, setIsDisabledUpgrade] = useState(true);
  const [password, setPassword] = useState('');
  const [upgradeCheck, setUpgradeCheck] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);

  const [upgradeK8sVersionModalOpen, setUpgradeK8sVersionModalOpen] = useState(false);
  const [highestAllowedUpgradeK8sVersion, setHighestAllowedUpgradeK8sVersion] = useState('');
  const [upgradeK8sVersion, setUpgradeK8sVersion] = useState('');

  const [upgradeTalosVersionModalOpen, setUpgradeTalosVersionModalOpen] = useState(false);
  const [highestAllowedUpgradeTalosVersion, setHighestAllowedUpgradeTalosVersion] = useState('');
  const [upgradeTalosVersion, setUpgradeTalosVersion] = useState('');

  const { echo } = useContext(EchoContext);
  const { user, isAuth } = useContext(AuthContext);

  const { setHeader } = useContext(HeaderContext);
  useEffect(() => {
    setHeader(`Kubernetes cluster: ${clusterInfo.clusterName}`);
  }, [setHeader, clusterInfo.clusterName]);

  const { talosId } = useParams();

  useEffect(() => {
    if (talosId !== '') {
      fetchClusterInfo();
      fetchClusterResources();
      fetchClusterConfig();
      fetchClusterLoadBalancers();
      fetchClusterControlPlanes();
      fetchClusterPoolsNodes();
      fetchHighestAllowedUpgradeK8sVersion();
    }
  }, [talosId]);

  useEffect(() => {
    if (password && password !== '' && upgradeCheck) {
      setIsDisabledUpgrade(false);
    } else {
      setIsDisabledUpgrade(true);
    }
  }, [password, upgradeCheck]);

  useEffect(() => {
    if (highestAllowedUpgradeK8sVersion !== '') {
      setUpgradeK8sVersion(highestAllowedUpgradeK8sVersion);
    }
  }, [highestAllowedUpgradeK8sVersion]);

  const fetchClusterInfo = () => {
    if (talosId !== '') {
      setFetchingClusterInfo(true);
      axios
        .get(`/api/user/kubernetes-talos/${talosId}/cluster-info`)
        .then(res => {
          if (res?.data) {
            setClusterInfo(res.data);
            setIsSetClusterInfo(true);
            if (res.data.talosClientVersion) {
              setUpgradeTalosVersion(res.data.talosClientVersion);
            }
          }
          setFetchingClusterInfo(false);
        })
        .catch(error => {
          console.error(error.message);
          setFetchingClusterInfo(false);
        });
    }
  };

  const fetchClusterResources = () => {
    setFetchingClusterResources(true);
    axios
      .get(`/api/user/kubernetes-talos/${talosId}/cluster-resources`)
      .then(res => {
        if (res?.data) {
          setClusterResources(res.data);
        }
        setFetchingClusterResources(false);
      })
      .catch(error => {
        console.log(error.message);
        setFetchingClusterResources(false);
      });
  };

  const fetchClusterConfig = () => {
    setFetchingClusterConfig(true);
    axios
      .get(`/api/user/kubernetes-talos/${talosId}/cluster-k8s-config`)
      .then(res => {
        if (res?.data) {
          setClusterK8sConfig(res.data);
        }
        setFetchingClusterConfig(false);
      })
      .catch(error => {
        console.log(error.message);
        setFetchingClusterConfig(false);
      });
  };

  const fetchClusterLoadBalancers = () => {
    setFetchingClusterLoadBalancers(true);
    axios
      .get(`/api/user/kubernetes-talos/${talosId}/cluster-load-balancers`)
      .then(res => {
        if (res?.data) {
          setClusterLoadBalancers(res.data);
        }
        setFetchingClusterLoadBalancers(false);
      })
      .catch(error => {
        console.log(error.message);
        setFetchingClusterLoadBalancers(false);
      });
  };

  const fetchClusterPoolsNodes = () => {
    setFetchingClusterPoolsNodes(true);
    axios
      .get(`/api/user/kubernetes-talos/${talosId}/cluster-pools-nodes`)
      .then(res => {
        if (res?.data) {
          setClusterPoolsNodes(res.data);
        }
        setFetchingClusterPoolsNodes(false);
      })
      .catch(error => {
        console.log(error.message);
        setFetchingClusterPoolsNodes(false);
      });
  };

  const fetchClusterControlPlanes = () => {
    setFetchingClusterControlPlanes(true);
    axios
      .get(`/api/user/kubernetes-talos/${talosId}/cluster-control-planes`)
      .then(res => {
        if (res?.data) {
          setClusterControlPlanes(res.data.nodes);
          setClusterControlResources({
            control_plane_cpu: res.data.control_plane_cpu,
            control_plane_ram: res.data.control_plane_ram,
            control_plane_disk: res.data.control_plane_disk,
          });
        }
        setFetchingClusterControlPlanes(false);
      })
      .catch(error => {
        console.log(error.message);
        setFetchingClusterControlPlanes(false);
      });
  };

  const fetchPoolCpResources = () => {
    fetchClusterPoolsNodes();
    fetchClusterControlPlanes();
    fetchClusterResources();
  };

  const fetchHighestAllowedUpgradeK8sVersion = () => {
    axios
      .get(`/api/user/kubernetes-talos/${talosId}/highest-available-version`)
      .then(res => {
        if (res.data !== 'Cluster not ready yet') {
          setHighestAllowedUpgradeK8sVersion(res.data);
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  const upgradeVersion = (version, type) => {
    console.log(version, type);
  };

  const downloadConfig = type => {
    axios
      .get(`/api/user/kubernetes-talos/${talosId}/cluster-config/${type}`)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${type}config`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  const addPool = data => {
    axios
      .post(`/api/user/kubernetes-talos/${talosId}/add-pool`, data)
      .then(res => {
        console.log(res.data);
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (isAuth && isSetClusterInfo && clusterInfo.controlPlanesHealth && echo && user && user.id) {
      echo.private(`users.${user.id}`).listen('.talosHealthCheckFinished', res => {
        if (res.healthyNodes && Object.keys(res.healthyNodes).length === clusterInfo.controlPlanesHealth.length) {
          const updatedClusterInfo = { ...clusterInfo };
          updatedClusterInfo.controlPlanesHealth.forEach(plane => {
            const controlPlaneNumber = plane.controlPlaneNumber.toString();
            plane.health = res.healthyNodes[controlPlaneNumber]
              ? CONTROL_PLANE_STATUS_HEALTHY
              : CONTROL_PLANE_STATUS_UNHEALTHY;
            plane.lastHealthCheck = res.lastUpdated;
          });
          setClusterInfo(updatedClusterInfo);
        }
      });
    }
  }, [isAuth, isSetClusterInfo, echo, user]);

  useEffect(() => {
    if (isAuth && echo && user && user.id) {
      echo.private(`users.${user.id}`).listen('.K8sPoolChanged', res => {
        if (res.poolIdentifier) {
          const updatedPoolsNodes = [...clusterPoolsNodes];
          const clusterKey = updatedPoolsNodes.findIndex(cluster => cluster.identifier === res.poolIdentifier);
          if (clusterKey !== -1) {
            updatedPoolsNodes[clusterKey].cpu = res.nodeCpu;
            updatedPoolsNodes[clusterKey].memory = res.nodeRam;
            updatedPoolsNodes[clusterKey].disk = res.nodeDisk;
            setClusterPoolsNodes(updatedPoolsNodes);
          }
        }
      });
    }
  }, [isAuth, echo, user]);

  const toggleUpgradeModalItem = event => {
    event.preventDefault();
    setIsOpenUpgradeModal(false);
  };

  const toggleUpgradeVersionModalItem = event => {
    event.preventDefault();
    setUpgradeK8sVersionModalOpen(false);
    setUpgradeTalosVersionModalOpen(false);
  };

  const handlePasswordInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'password') {
      setPassword(value);
    }
    if (name === 'upgrade-check') {
      setUpgradeCheck(value);
    }
  };

  const upgradeCluster = event => {
    event.preventDefault();
    setIsUpgrading(true);
    axios
      .post(`/api/user/kubernetes-talos/${talosId}/upgrade`, { password })
      .then(res => {
        if (res.status === 200) {
          setIsUpgrading(false);
          setIsOpenUpgradeModal(false);
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  const upgradeTalosVersionHandler = event => {
    event.preventDefault();
    axios
      .post(`/api/user/kubernetes-talos/${talosId}/upgrade-talos-version`, { version: upgradeTalosVersion })
      .then(res => {
        setUpgradeTalosVersionModalOpen(false);
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  const upgradeClusterVersionHandler = event => {
    event.preventDefault();
    axios
      .post(`/api/user/kubernetes-talos/${talosId}/upgrade-k8s-version`, { version: upgradeK8sVersion })
      .then(res => {
        setUpgradeK8sVersionModalOpen(false);
      })
      .catch(error => {
        console.log(error.message);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-5 pr-3">
          <ClusterMain
            clusterInfo={clusterInfo}
            fetchingClusterInfo={fetchingClusterInfo}
            clusterIdentifier={talosId}
            upgradeVersion={upgradeVersion}
            downloadConfig={downloadConfig}
            setUpgradeTalosVersionModalOpen={setUpgradeTalosVersionModalOpen}
          />
          <ClusterResources clusterResources={clusterResources} fetchingClusterResources={fetchingClusterResources} />
          <K8sConfiguration
            clusterK8sConfig={clusterK8sConfig}
            clusterIdentifier={talosId}
            fetchingClusterConfig={fetchingClusterConfig}
            downloadConfig={downloadConfig}
            setUpgradeK8sVersionModalOpen={setUpgradeK8sVersionModalOpen}
          />
          <LinkedLoadBalancers
            clusterLoadBalancers={clusterLoadBalancers}
            fetchingClusterLoadBalancers={fetchingClusterLoadBalancers}
          />
        </div>
        <div className="col-7 pl-3">
          <ClusterItems
            addPool={addPool}
            clusterIdentifier={talosId}
            clusterControlPlanes={clusterControlPlanes}
            clusterInfo={clusterInfo}
            fetchingClusterControlPlanes={fetchingClusterControlPlanes}
            clusterPoolsNodes={clusterPoolsNodes}
            clusterResources={clusterResources}
            fetchingClusterPoolsNodes={fetchingClusterPoolsNodes}
            fetchClusterPoolsNodes={fetchClusterPoolsNodes}
            fetchPoolCpResources={fetchPoolCpResources}
            clusterMode={clusterInfo.clusterMode}
            setIsOpenUpgradeModal={setIsOpenUpgradeModal}
            isUpgrading={isUpgrading}
            clusterControlResources={clusterControlResources}
          />
          {talosId && (
            <LastActivitiesTable
              logTitle="Cluster Changes"
              fetchLogsUrl={`api/user/kubernetes-talos/${talosId}/cluster-activity`}
              fetchCategoriesUrl={`api/user/kubernetes-talos/${talosId}/cluster-activity-categories`}
              isAllowHiding={true}
              isAllowDownloading={true}
            />
          )}
        </div>
      </div>
      <Modal isOpen={isOpenUpgradeModal}>
        <ModalHeader toggle={toggleUpgradeModalItem}>Upgrade cluster</ModalHeader>
        <ModalBody>
          <form>
            <div className={'form-check col-xs-8'}>
              <p>
                When you upgrade the cluster, the number of control planes will increase from 1 to 3. This change is
                designed to enable true High Availability (HA) capabilities. In addition, the number of load balancers
                will also increase from 1 to 2. Please note, once these changes are made, they cannot be reverted.
              </p>
              <Input
                type="checkbox"
                name="upgrade-check"
                className="form-check-input"
                id="upgrade-check"
                onChange={e => handlePasswordInputChange(e)}
                data-cy="upgrade-check-input"
              />
              <label htmlFor="upgrade-check" className="form-check-label">
                Ok, I understand.
                <br />
              </label>
              <label className="mt-4">For security reasons, please confirm this task by typing your password:</label>
              <input
                type="password"
                name="password"
                id="password"
                className="form-control"
                placeholder="Enter your Password"
                data-cy="upgrade-password-input"
                onChange={e => handlePasswordInputChange(e)}
              />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 p-0 d-flex justify-content-between">
                  <Button color="danger" onClick={e => toggleUpgradeModalItem(e)} data-cy="upgrade-cancel">
                    <span>Cancel</span>
                  </Button>
                  <Button
                    color="success"
                    disabled={isDisabledUpgrade}
                    onClick={e => upgradeCluster(e)}
                    data-cy="upgrade-confirm"
                  >
                    <span>Upgrade cluster</span>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal isOpen={upgradeK8sVersionModalOpen}>
        <ModalHeader>Upgrade Kubernetes version</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-check">
              {highestAllowedUpgradeK8sVersion === '' ? (
                <p className="text-danger">Cluster is not ready to be upgraded</p>
              ) : (
                <p>{`The highest version you can upgrade your Kubernetes cluster is v${highestAllowedUpgradeK8sVersion}`}</p>
              )}
            </div>
            {highestAllowedUpgradeK8sVersion !== '' && (
              <div>
                <FormGroup>
                  <Label className="mb-0 pl-0">Upgrade Version</Label>
                  <Input
                    data-cy="k8s-version-input"
                    id="k8s-version-input"
                    required
                    value={upgradeK8sVersion}
                    onChange={e => setUpgradeK8sVersion(e.target.value)}
                    autoComplete="off"
                  />
                </FormGroup>
              </div>
            )}
            <div className="container">
              <div className="row">
                <div className="col-12 p-0 d-flex justify-content-between">
                  <Button
                    color="danger"
                    onClick={e => toggleUpgradeVersionModalItem(e)}
                    data-cy="k8s-version-upgrade-cancel"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    color="success"
                    onClick={e => upgradeClusterVersionHandler(e)}
                    data-cy="k8s-version-upgrade-confirm"
                    disabled={highestAllowedUpgradeK8sVersion === ''}
                  >
                    <span>Upgrade</span>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal isOpen={upgradeTalosVersionModalOpen}>
        <ModalHeader>Upgrade Talos OS version</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-check">
              <p>{`The highest version you can upgrade Talos OS is v${clusterInfo.talosClientVersion}`}</p>
            </div>
            <div>
              <FormGroup>
                <Label className="mb-0 pl-0">Upgrade Version</Label>
                <Input
                  data-cy="talos-version-input"
                  id="talos-version-input"
                  required
                  value={upgradeTalosVersion}
                  onChange={e => setUpgradeTalosVersion(e.target.value)}
                  autoComplete="off"
                />
              </FormGroup>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 p-0 d-flex justify-content-between">
                  <Button
                    color="danger"
                    onClick={e => toggleUpgradeVersionModalItem(e)}
                    data-cy="talos-version-upgrade-cancel"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    color="success"
                    onClick={e => upgradeTalosVersionHandler(e)}
                    data-cy="talos-version-upgrade-confirm"
                  >
                    <span>Upgrade</span>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TalosEditPage;
