import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { v4 as uuidv4 } from 'uuid';

const CustomizedSkeletonComponent = ({ containerClassName, height }) => {
  return (
    <div className={containerClassName}>
      <Skeleton height={height} />
    </div>
  );
};

export const convertDataForSkeleton = (columns, numberOfRows, skelHeight = 15, protectedFields = []) => {
  const skeletonColumns = columns.map(el => {
    if (protectedFields.indexOf(el.dataField) >= 0) {
      return { ...el, classes: `pointer-none ${el.classes}` };
    } else
      return {
        ...el,
        headerStyle: {
          ...el.headerStyle,
          background: 'none',
          position: 'static',
        },
        style: { ...el.style, background: 'none', position: 'static' },
        classes: el.classes,
        formatter: () => <Skeleton height={skelHeight} />,
      };
  });
  let convertedSkeletonData = [];
  let i = 0;
  const dummyDataObject = { id: uuidv4() };
  columns.map(el => (dummyDataObject[el.dataField] = uuidv4()));
  const whileClosureFunc = (i, dummyObj) => {
    const newDummyDataObject = { ...dummyObj };
    newDummyDataObject['id'] += i;
    return newDummyDataObject;
  };
  while (i < numberOfRows) {
    const dummyObject = whileClosureFunc(i, dummyDataObject);
    convertedSkeletonData = convertedSkeletonData.concat(dummyObject);
    i++;
  }
  return [skeletonColumns, convertedSkeletonData];
};

export default CustomizedSkeletonComponent;
