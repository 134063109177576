import React, { Component } from 'react';
import { withAuthContext } from '../hoc/withAuthContext';
import * as PropTypes from 'prop-types';
import Echo from 'laravel-echo';
require('pusher-js');

const createEchoInstance = token =>
  new Echo({
    authEndpoint: process.env.REACT_APP_WEBSITE_URL + '/api/broadcasting/auth',
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: 'mt1',
    wsHost: process.env.REACT_APP_PUSHER_URL || process.env.REACT_APP_WEBSITE_URL.replace(/https?:\/\//i, ''),
    wsPort: 6001,
    auth: {
      headers: {
        Authorization: token,
        Accept: 'application/json',
      },
    },
    enabledTransports: ['ws'],
  });

const EchoContext = React.createContext({});

class EchoProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      echoInstance: null,
    };
    this.checkTokenInterval = null;
  }

  componentDidMount() {
    this.checkTokenInterval = setInterval(() => {
      const token = localStorage.getItem('token');
      if (token) {
        this.setState({
          echoInstance: createEchoInstance('Bearer ' + token),
        });
        clearInterval(this.checkTokenInterval);
      }
    }, 1000);
  }

  render() {
    const { children } = this.props;

    return (
      <EchoContext.Provider
        value={{
          echo: this.state.echoInstance,
        }}
      >
        {children}
      </EchoContext.Provider>
    );
  }
}

EchoProvider.propTypes = { children: PropTypes.node };

export default withAuthContext(EchoProvider);
export { EchoContext };
