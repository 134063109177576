import React, { useState, useContext, useEffect, useRef } from 'react';
import { Card, CardBody, FormGroup, Input, Button, FormFeedback } from 'reactstrap';

import { AuthContext } from '../contexts/AuthContext';
import Form from '../UI/Form';

const SmsTwoFactorAuth = props => {
  const { twoFactorLogin } = useContext(AuthContext);
  const [otp, setOtp] = useState('');

  const inputChangeHandler = event => {
    setOtp(event.target.value);
  };

  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then(otp => {
          setOtp(otp.code);
          ac.abort();
        })
        .catch(error => {
          console.error(error);
          ac.abort();
        });
    }
  }, []);

  return (
    <Card className="login">
      <CardBody>
        <Form submit={() => twoFactorLogin({ code: otp, type: 'sms' }, props, props.setNotificationConnection)}>
          <p>OTP has been sent to your Phone</p>
          <FormGroup>
            <Input
              innerRef={input => (input ? input.focus() : null)}
              placeholder="Your OTP"
              name="otp"
              value={otp}
              onChange={inputChangeHandler}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              required
            />
            <FormFeedback />
          </FormGroup>
          <Button color="primary" className="w-100">
            Login
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};

export default SmsTwoFactorAuth;
