import React, { Component } from 'react';
import clsx from 'clsx';

import axios from '../../axiosInstance';
import { getOffset } from '../../utils/getOffset';
import { isValidPhoneNumber } from 'react-phone-number-input';

class Form extends Component {
  state = {
    isValidated: false,
  };

  validate = () =>
    new Promise((resolve, reject) => {
      const formEl = this.formEl;
      const formLength = formEl.length;

      let password = '';
      let phone = '';
      let password_confirmation = '';
      let first_name = '';
      let last_name = '';
      let tenantname = '';
      let ipAddress = '';

      const emails = [];

      for (let i = 0; i < formLength; i++) {
        if (formEl[i].name === 'password') {
          password = formEl[i];
        }
        if (formEl[i].name === 'first_name') {
          first_name = formEl[i];
        }
        if (formEl[i].name === 'last_name') {
          last_name = formEl[i];
        }
        if (formEl[i].name === 'tenantname') {
          tenantname = formEl[i];
        }
        if (formEl[i].name === 'password_confirmation') {
          password_confirmation = formEl[i];
        }
        if (formEl[i].type === 'email') {
          emails.push(formEl[i]);
        }
        if (formEl[i].type === 'tel') {
          phone = formEl[i];
        }
        if (formEl[i].name === 'ipAddress') {
          ipAddress = formEl[i];
        }
      }

      if (this.props.customValidation && this.props.customValidation()) reject(false);
      if (password && password_confirmation) {
        if (this.checkPasswordMatch(password, password_confirmation)) {
          if (password.hasAttribute('data-check-blacklisting')) {
            this.validatePassword(password)
              .then(res => {
                resolve(this.validateFields(formEl, formLength));
              })
              .catch(() => {
                reject(false);
              });
          } else {
            resolve(this.validateFields(formEl, formLength));
          }
        } else {
          resolve(this.validateFields(formEl, formLength));
        }
      }
      if (emails.length > 0) {
        emails.forEach(email => this.validateEmail(email));
        resolve(this.validateFields(formEl, formLength));
      }
      if (first_name) {
        const result = this.validateName(first_name);
        resolve(this.validateFields(formEl, formLength));
      }
      if (last_name) {
        const result = this.validateName(last_name);
        resolve(this.validateFields(formEl, formLength));
      }
      if (tenantname) {
        const result = this.validateName(tenantname);
        resolve(this.validateFields(formEl, formLength));
      }
      if (phone) {
        const result = this.validatePhone(phone);
        if (result) resolve(this.validateFields(formEl, formLength));
        else reject(false);
      }
      if (ipAddress) {
        const result = this.validateIpAddress(ipAddress);
        resolve(result);
      } else {
        resolve(this.validateFields(formEl, formLength));
      }
    });

  validatePhone = phone => {
    if (isValidPhoneNumber(phone.value)) {
      return true;
    } else {
      const errorLabel = phone.parentNode.parentNode.querySelector('.invalid-feedback');
      errorLabel.textContent = phone.validationMessage;
      return false;
    }
  };

  validateFields = (formEl, formLength) => {
    if (formEl.checkValidity() === false) {
      let scrollOffset = undefined;
      for (let i = 0; i < formLength; i++) {
        const elem = formEl[i];
        const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
        if (!elem.validity.valid && (scrollOffset === undefined || scrollOffset > getOffset(elem).top)) {
          scrollOffset = getOffset(elem).top - elem.parentNode.offsetHeight - 50;
        }
        if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
          if (!elem.validity.valid) {
            errorLabel.textContent = elem.getAttribute('validationMessage') || elem.validationMessage;
          } else {
            errorLabel.textContent = '';
          }
        }
      }
      if (this.props.scrollToInvalid) window.scroll(0, scrollOffset);
      return false;
    } else {
      for (let i = 0; i < formLength; i++) {
        const elem = formEl[i];
        const errorLabel = elem.parentNode.querySelector('.invalid-feedback');
        if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
          errorLabel.textContent = '';
        }
      }
      return true;
    }
  };

  checkPasswordMatch = (password, password_confirmation) => {
    if (password.value !== password_confirmation.value) {
      password_confirmation.setCustomValidity("Passwords Don't Match");
    } else {
      password_confirmation.setCustomValidity('');
    }
    return password.value === password_confirmation.value;
  };

  validateEmail = email => {
    // symbols that are validated as well !#$%&'*+/=?^_`{|}~.-
    const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = email.value.match(mailformat);
    if (!result) {
      email.setCustomValidity('Please insert valid email');
    } else {
      email.setCustomValidity('');
    }
    return result;
  };

  validateName = field => {
    const nameformat = /^[ A-Za-z]+$/;
    let result = field.value.length >= 3 && field.value.length <= 30;
    let message = 'Name between 3 and 30 symbols';
    if (field.name === 'first_name' || field.name === 'last_name') {
      result = result && nameformat.test(field.value);
      message += ', only letters and spaces';
    }
    if (!result) {
      field.setCustomValidity(message);
    } else {
      field.setCustomValidity('');
    }
    return result;
  };

  validatePassword = password =>
    axios
      .post('api/user/blacklisted/check', { password: password.value })
      .then(res => {
        if (res === undefined) {
          password.setCustomValidity('Please create a strong password!');
        } else {
          password.setCustomValidity('');
        }
      })
      .catch(() => {
        password.setCustomValidity('Please create a strong password!');
      });

  validateIpAddress = ipAddress => {
    // regex for the IPV4 standard

    const ipAddressformat = /^(([1-9]?\d|1\d\d|2[0-5][0-5]|2[0-4]\d)\.){3}([1-9]?\d|1\d\d|2[0-5][0-5]|2[0-4]\d)$/;
    const result = ipAddressformat.test(ipAddress.value);
    if (!result) {
      ipAddress.setCustomValidity('Please enter a valid IP address');
    } else {
      ipAddress.setCustomValidity('');
    }
    return result;
  };

  submitHandler = event => {
    event.preventDefault();
    this.validate()
      .then(res => {
        if (res) {
          this.props.submit(event);
        } else {
          this.props.handleInvalidForm && this.props.handleInvalidForm();
        }
      })
      .catch(e => console.warn(e.message));
    this.setState({ isValidated: true });
  };

  render() {
    const classNames = [];
    if (this.state.isValidated) {
      classNames.push('was-validated');
    }

    return (
      <form
        className={clsx(classNames, this.props.className)}
        noValidate
        onClick={this.props.onClick}
        autoComplete={this.props.autocomplete}
        ref={form => (this.formEl = form)}
        onSubmit={this.submitHandler}
      >
        {this.props.children}
      </form>
    );
  }
}

export default Form;
