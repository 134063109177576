import React from 'react';

const Documentation = () => {
  const handleButtonClick = () => {
    window.open('https://docs.xelon.ch/docs/getting-started', '_blank');
  };

  return (
    <div
      data-cy="header-documentation-button"
      className="user-panel-square btn btn-default d-flex align-items-center justify-content-center cursor-pointer"
      color="default"
      onClick={handleButtonClick}
    >
      <i className="fas fa-question-circle mr-3 my-auto" />
    </div>
  );
};

export default Documentation;
