import React from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('root');

class Frame extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    this.el.className = 'position-fixed w-100 h-100 pointer-none';
    this.el.style.cssText = `top: 0; left: 0; z-index: ${this.props.overModal ? 1090 : 1000}`;
    modalRoot.insertBefore(this.el, modalRoot.firstChild);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Frame;
