import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';

const LinkedLoadBalancers = ({ clusterLoadBalancers, fetchingClusterLoadBalancers }) => {
  return (
    <Card className="mb-4">
      <CardBody className="d-flex flex-row justify-content-between card-header-low-border">
        <h3 className="mb-0" style={{ fontSize: '1.125rem' }}>
          <i className="fas fa-arrows-alt-h mr-2" />
          Linked Load Balancers
        </h3>
      </CardBody>
      <CardBody className="px-0">
        <Table borderless size="sm" className="mb-0">
          <tbody className="table-left-columns">
            {clusterLoadBalancers.map((clusterLoadBalancer, index) => (
              <tr key={clusterLoadBalancer.clusterName + '-' + index}>
                <td>{clusterLoadBalancer.clusterName}</td>
                <td>
                  {clusterLoadBalancer.loadBalancers.map((loadBalancer, lbIndex) => (
                    <div key={loadBalancer.loadBalancerName + '-' + index + '-' + lbIndex}>
                      {loadBalancer.loadBalancerName} (IP: {loadBalancer.loadBalancerIp})
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default LinkedLoadBalancers;
