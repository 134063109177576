import React, { useContext, useState, useEffect } from 'react';
import { Card, FormFeedback } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import ServerIcon from '../../images/server-icon.svg';
import Input from '../UI/Input';
import Select from '../UI/SelectRequired';
import HeaderedCard from '../UI/HeaderedCard';
import SoftwareCard from './SoftwareCard';
import DisksCard from './DisksCard';
import NetworkCard from './NetworkCard';
import axios from '../../axiosInstance';
import { getInitialValue } from './utils';
import {
  ipmiOptions,
  powerSuppliesOptions,
  memoryOptions,
  backupsOptions,
  firewallOptions,
  billing_frequencies,
  OSs,
  cpuOptions,
  uplinkOptions,
  uplinksOptions,
  ipsOptions,
  mainHddOptions,
  raidOptions,
  serverControlPanelOptions,
} from './initData';
import CpuCards from './CpuCards';
import Form from '../../utils/form/form';
import BillCard from './BillCard';
import { ViewContext } from '../contexts/ViewContext';
import cloneObject from '../../utils/cloneObject';

const DedicatedForm = () => {
  const history = useHistory();
  const view = useContext(ViewContext);
  const [os, setOs] = useState(OSs[0].value);

  const [data, setData] = useState(
    new Form({
      email: '',
      backups: getInitialValue(backupsOptions[0]),
      billing_frequency: getInitialValue(billing_frequencies[0]),
      burstable: '',
      class_1_traffic: '',
      cpu_options: getInitialValue(cpuOptions[0]),
      firewall: getInitialValue(firewallOptions[0]),
      hdd: {
        'HardDisk 1 (Main)': {
          label: mainHddOptions[0].label,
          value: mainHddOptions[0],
        },
      },
      hostname: '',
      ipmi: getInitialValue(ipmiOptions[0]),
      os: OSs[0].value,
      power_supplies: getInitialValue(powerSuppliesOptions[0]),
      raid: getInitialValue(raidOptions[0]),
      ram: getInitialValue({
        label: memoryOptions[0].value + 'GB',
        value: memoryOptions[0].value,
      }),
      server_control_panel: getInitialValue(serverControlPanelOptions[0]),
      summary: '',
      uplink: uplinkOptions[0],
      uplink_amount: getInitialValue(uplinksOptions[0]),
      ips: { label: `${ipsOptions.min} IPs`, value: ipsOptions.min },
    })
  );

  const onInputChange = (name, value) => {
    const newData = cloneObject(data);
    newData[name] = value;
    if (name === 'burstable' || name === 'class_1_traffic' || name === 'uplink') {
      newData.clearResults('burstable');
      newData.clearResults('class_1_traffic');
      if (name === 'uplink') {
        newData.clearResults('uplink');
        newData.class_1_traffic = '';
        newData.burstable = '';
      }
    } else {
      newData.clearResults(name);
    }
    setData(newData);
  };

  const getPayload = () => {
    const payload = {};
    Object.keys(data.data()).map(key => {
      switch (key) {
        case 'backups':
        case 'billing_frequency':
        case 'cpu_options':
        case 'firewall':
        case 'ipmi':
        case 'power_supplies':
        case 'raid':
        case 'server_control_panel':
        case 'uplink':
        case 'uplink_amount':
        case 'burstable':
        case 'class_1_traffic':
        case 'os':
          payload[key] = data[key].value;
          break;
        case 'hdd':
          payload[key] = [];
          Object.keys(data[key]).map(childKey => {
            payload[key].push(data[key][childKey].value);
          });
          break;
        case 'ips':
          payload[key] = {
            label: data[key].value,
            price: (data[key].value - 1) * 9,
          };
          break;
        case 'ram':
          payload[key] = {
            label: data[key].label,
            price: data[key].value.price,
          };
          break;
        default:
          payload[key] = data[key];
          break;
      }
    });
    return payload;
  };

  const onDownload = async () => {
    try {
      await data.submitUsing(async () => {
        const result = await axios.post('/api/dedicated/create-by-download-button', getPayload());

        if (result && result.data && result.data.config && result.data.config.uid) {
          const downloadUrl = `${process.env.REACT_APP_WEBSITE_URL}/api/dedicated/download-pdf/${result.data.config.uid}`;
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(downloadUrl);
        }
      });
    } catch (e) {
      // for rerender component
      setData(cloneObject(data));
    }
  };

  const onSave = async () => {
    try {
      await data.submitUsing(async () => {
        const result = await axios.post('api/dedicated/configuration', getPayload());
        if (result && result.status === 200) {
          history.push('/checkYourEmail');
        }
      });
    } catch (e) {
      // for rerender component
      setData(cloneObject(data));
    }
  };

  return (
    <form className="py-5 row no-gutters justify-content-around">
      <div className="col-1" />
      <div className="col-md-6 d-flex flex-column dedicated">
        <Card className="row no-gutters flex-column mb-3">
          <div className="row no-gutters flex-row border-bottom p-4 no-wrap">
            <img src={ServerIcon} alt="server" className="col-auto" />
            <div className="d-flex flex-column pl-4 col-8 col-md-auto">
              <h1 className="pl-0 line-height-1 mb-1">Dedicated Server Configuration</h1>
              <h2>Configuration tailored just to your need</h2>
            </div>
          </div>
          <div className="row flex-row no-gutters p-4">
            <div className="col-12 col-md-6 pr-md-2 d-flex flex-column mb-3 mb-md-0">
              <h3>Hostname</h3>
              <Input name="hostname" value={data.hostname} onChange={(name, value) => onInputChange(name, value)} />
              <FormFeedback className="d-block input__danger-message">{data.errors.first('hostname')}</FormFeedback>
            </div>
            <div className="col-12 col-md-6 pl-md-2 d-flex flex-column">
              <h3>Billing Frequency</h3>
              <Select
                value={data.billing_frequency}
                onChange={value => onInputChange('billing_frequency', value)}
                options={billing_frequencies.map(el => ({
                  label: el.label,
                  value: el,
                }))}
              />
              <FormFeedback className="d-block input__danger-message">
                {data.errors.first('billing_frequency')}
              </FormFeedback>
            </div>
          </div>
        </Card>
        <CpuCards
          currentCpu={data.cpu_options}
          billingPeriod={data.billing_frequency}
          error={data.errors.first('cpu_options')}
          onCpuChanges={value => {
            onInputChange('cpu_options', value);
          }}
        />
        <HeaderedCard className="row no-gutters p-4" containerClassName="mb-3" headerLabel="Chassis">
          <div className="col-12 mb-md-3 col-md-6 pr-md-2 d-flex flex-column">
            <h3>IPMI Management Interface</h3>
            <Select
              value={data.ipmi}
              options={ipmiOptions.map(el => ({
                value: el,
                label: el.label,
              }))}
              onChange={value => onInputChange('ipmi', value)}
            />
            <FormFeedback className="d-block input__danger-message">{data.errors.first('ipmi')}</FormFeedback>
          </div>
          <div className="col-12 col-md-6 pl-md-2 d-flex flex-column">
            <h3>Amount of power supplies</h3>
            <Select
              value={data.power_supplies}
              options={powerSuppliesOptions.map(el => ({
                value: el,
                label: el.label,
              }))}
              onChange={value => onInputChange('power_supplies', value)}
            />
            <FormFeedback className="d-block input__danger-message">{data.errors.first('power_supplies')}</FormFeedback>
          </div>
        </HeaderedCard>
        <SoftwareCard
          currentValue={os}
          currentTemplate={data.os}
          onChangeOs={setOs}
          distributive={data.distributive}
          onDistributiveChange={value => onInputChange('os', value)}
          serverControlPanel={data.server_control_panel}
          onServerControlPanelChange={value => onInputChange('server_control_panel', value)}
          onCurrentTemplateChange={value => onInputChange('os', value)}
          errors={data.errors}
        />
        <HeaderedCard className="d-flex flex-column p-4" containerClassName="mb-3" headerLabel="Memory">
          <h3>RAM Amount</h3>
          <Select
            value={data.ram}
            onChange={value => onInputChange('ram', value)}
            options={memoryOptions.map(el => ({
              label: el.value + 'GB',
              value: el,
            }))}
          />
          <FormFeedback className="d-block input__danger-message">{data.errors.first('ram')}</FormFeedback>
        </HeaderedCard>
        <DisksCard
          onDiskChange={value => onInputChange('hdd', value)}
          onRaidChange={value => onInputChange('raid', value)}
          disks={data.hdd}
          raid={data.raid}
          errors={data.errors}
        />
        <NetworkCard
          ips={data.ips}
          uplink={data.uplink}
          burstable={data.burstable}
          traffic={data['class_1_traffic']}
          uplinkAmount={data['uplink_amount']}
          onIpsChange={value => onInputChange('ips', value)}
          onBurstableChange={value => onInputChange('burstable', value)}
          onTrafficChange={value => onInputChange('class_1_traffic', value)}
          onUplinkAmountChange={value => onInputChange('uplink_amount', value)}
          onUplinkChange={value => onInputChange('uplink', value)}
          errors={data.errors}
        />
        <HeaderedCard className="d-flex flex-row flex-wrap p-3" containerClassName="mb-3" headerLabel="Security">
          <div className="d-flex flex-column col-12 col-md-6">
            <h3>Firewall</h3>
            <Select
              value={data.firewall}
              onChange={value => onInputChange('firewall', value)}
              options={firewallOptions.map(el => ({
                label: el.label,
                value: el,
              }))}
            />
            <FormFeedback className="d-block input__danger-message">{data.errors.first('firewall')}</FormFeedback>
          </div>
          <div className="d-flex flex-column col-12 col-md-6">
            <h3>Backup</h3>
            <Select
              value={data.backups}
              onChange={value => onInputChange('backups', value)}
              options={backupsOptions.map(el => ({
                label: el.label,
                value: el,
              }))}
            />
            <FormFeedback className="d-block input__danger-message">{data.errors.first('backups')}</FormFeedback>
          </div>
        </HeaderedCard>
        {view === 'xs' && (
          <BillCard
            formData={data}
            billingPeriod={data.billing_frequency}
            email={data.email}
            onEmailChange={value => onInputChange('email', value)}
            onDownload={onDownload}
            onSave={onSave}
            error={data.errors.first('email')}
          />
        )}
      </div>
      <div className="col-4" />
      {view !== 'xs' && (
        <BillCard
          formData={data}
          billingPeriod={data.billing_frequency}
          email={data.email}
          onEmailChange={value => onInputChange('email', value)}
          onDownload={onDownload}
          onSave={onSave}
          error={data.errors.first('email')}
        />
      )}
    </form>
  );
};

export default DedicatedForm;
