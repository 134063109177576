import React from 'react';
import { FormFeedback } from 'reactstrap';

import HeaderedCard from '../UI/HeaderedCard';
import Select from '../UI/SelectRequired';
import OSSelector from '../shared/OSSelector';

import { osOptions, OSs, serverControlPanelOptions } from './initData';

const SoftwareCard = ({
  currentValue,
  onChangeOs,
  currentTemplate,
  onCurrentTemplateChange,
  onServerControlPanelChange,
  serverControlPanel,
  distributive,
  onDistributiveChange,
  errors,
}) => {
  return (
    <HeaderedCard className="d-flex flex-column p-4" headerLabel="Software" containerClassName="mb-3">
      <OSSelector
        OSs={OSs}
        distributives={osOptions}
        currentOS={currentValue}
        currentDistributive={distributive}
        onOSChange={onChangeOs}
        onDistributiveChange={onDistributiveChange}
        onCurrentTemplateChange={onCurrentTemplateChange}
        isValidationError={errors.has('os')}
        getDistributiveItem={el => ({
          key: el.label,
          label: el.label,
          value: {
            label: el.label,
            value: el,
          },
        })}
        className="mt-4"
      />
      <FormFeedback className="d-block input__danger-message mb-3">{errors.first('os')}</FormFeedback>
      <div className="d-flex flex-column">
        <h3>Server Control Panel</h3>
        <Select
          onChange={onServerControlPanelChange}
          value={serverControlPanel}
          required
          options={serverControlPanelOptions.map(el => ({
            label: el.label,
            value: el,
          }))}
        />
        <FormFeedback className="d-block input__danger-message">{errors.first('server_control_panel')}</FormFeedback>
      </div>
    </HeaderedCard>
  );
};

export default SoftwareCard;
