import React from 'react';
import { FormGroup, FormFeedback } from 'reactstrap';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';

const OSSelector = props => {
  const {
    OSs,
    distributives,
    currentOS,
    currentDistributive,
    onOSChange,
    onDistributiveChange,
    onCurrentTemplateChange,
    getDistributiveItem,
    className,
    defaultMenuNotShown,
    isValidationError,
  } = props;

  const handleDistributiveChange = selectedOption => {
    onDistributiveChange(selectedOption);
    onCurrentTemplateChange(selectedOption.value, currentOS);
  };

  return distributives && Object.keys(distributives).length > 0 ? (
    <div className={clsx('form-check-btn-wrp mt-4 mb-3', className)}>
      {OSs.map(template => (
        <FormGroup check inline key={template.value} className={isValidationError && 'danger'}>
          <label
            htmlFor={template.value}
            className={clsx(
              template.value === currentOS ? 'form-check-label-btn-selected' : 'form-check-label-btn',
              distributives[template.value] && distributives[template.value].length > 0 ? '' : 'pointer-none'
            )}
          >
            <i className={clsx(template.icon, 'mt-auto')} />
            <span className="mb-auto">{template.name}</span>
            {distributives &&
            distributives[template.value] &&
            distributives[template.value].length &&
            template.value === currentOS ? (
              <Select
                data-cy="os-selector-select"
                defaultMenuIsOpen={!defaultMenuNotShown}
                value={currentDistributive}
                onChange={handleDistributiveChange}
                options={distributives[currentOS].map(getDistributiveItem)}
              />
            ) : null}

            <input
              data-cy="os-selector-radio"
              type="radio"
              name="category"
              className="form-check-input"
              id={template.value}
              required
              value={template.value}
              checked={template.value === currentOS}
              onChange={event => {
                const newOS = event.target.value;
                if (!distributives[newOS] || !distributives[newOS].length) {
                  event.preventDefault();
                  return;
                }
                onOSChange(newOS);
                if (distributives[newOS].length === 1) {
                  const el = distributives[newOS][0];
                  onDistributiveChange(getDistributiveItem(el));
                  onCurrentTemplateChange(el.id, newOS);
                }
              }}
            />
            <FormFeedback />
          </label>
        </FormGroup>
      ))}
    </div>
  ) : (
    <div className={clsx('form-check-btn-wrp mt-4 mb-3 justify-content-center', className)}>
      {[...Array(5).keys()].map(() => (
        <FormGroup className="width-rem-12 ml-2" key={uuidv4()}>
          <Skeleton height={100} />
        </FormGroup>
      ))}
    </div>
  );
};

export default OSSelector;
