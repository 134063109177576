import React, { createContext, useEffect, useState } from 'react';
import axios from '../../axiosInstance';
import { capitalize } from '../helpers/helperFunc';

export const CloudContext = createContext({});

export const CloudProvider = ({ children }) => {
  const [clouds, setClouds] = useState([]);
  const [cloudOptions, setCloudOptions] = useState(null);

  useEffect(() => {
    const cloudsList = clouds
      ? clouds.map(cloud => ({
          label: cloud.display_name,
          typeAndId: capitalize(cloud.hv_type) + ' Cloud #' + cloud.id,
          value: cloud.id,
        }))
      : [];

    setCloudOptions(cloudsList);
  }, [clouds]);

  const getClouds = tenant => {
    axios
      .get('/api/user/hv/list/' + tenant)
      .then(res => {
        if (res && res.data) {
          setClouds(res.data);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const value = { clouds, cloudOptions, getClouds };

  return <CloudContext.Provider value={value}>{children}</CloudContext.Provider>;
};
