import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from '../../UI/SelectRequired';

const UpgradeK8sVersionModal = ({
  itemName,
  modalOpen,
  setModalOpen,
  toggleModal,
  currentVersion,
  versionOptions,
  upgradeK8Version,
}) => {
  const [k8sNewVersion, setK8sNewVersion] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    if (k8sNewVersion) {
      upgradeK8Version(k8sNewVersion.value);
      setModalOpen(false);
    }
  };

  return (
    <Modal isOpen={modalOpen} size="sm">
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggleModal}>{itemName} Version Upgrade</ModalHeader>
        <ModalBody>
          <p className="text-center">
            A new {itemName} version is available, you are currently running {currentVersion}.
          </p>
          <div className="row no-gutters flex-row justify-content-center">
            <div className="col-9">
              <Select
                required
                value={k8sNewVersion}
                onChange={e => setK8sNewVersion({ label: e.value, value: e.value })}
                placeholder="Select version"
                options={versionOptions}
                dataCy="select-k8s-for-talos"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex flex-row bg-gray pt-3">
          <Button type="button" className="ml-auto btn-light" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button type="submit" color="secondary">
            Create
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UpgradeK8sVersionModal;
