import React from 'react';
import PropTypes from 'prop-types';
import { Disable } from 'react-disable';

const BluringOverlay = ({ enabled, forwardComponent, children }) => (
  <div className="bluring-overlay-container">
    {enabled && forwardComponent && forwardComponent}
    <div className={enabled ? 'bluring-overlay' : ''}>
      <Disable disabled={enabled}>{children}</Disable>
    </div>
  </div>
);

BluringOverlay.propTypes = {
  enabled: PropTypes.bool,
  forwardComponent: PropTypes.element,
  children: PropTypes.element,
};

export default BluringOverlay;
