import React, { useState, useEffect, useContext } from 'react';
import * as PropTypes from 'prop-types';

import axios from '../../axiosInstance';
import { withEcho } from '../hoc/withEcho';
import withPushNotification from '../hoc/withPushNotification';
import { AuthContext } from './AuthContext';
import { EchoContext } from './EchoContext';

const NotificationContext = React.createContext({});

const NotificationProvider = props => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const { user } = useContext(AuthContext);
  const { echo } = useContext(EchoContext);
  const authContextUser = user;
  const welcomeNotification = numberOfNewNotifications => ({
    data: {
      name: `You have ${numberOfNewNotifications} new ${
        +numberOfNewNotifications > 1 ? 'notifications' : 'notification'
      }`,
      description: 'visit your account page',
      // link: '/user-account',
    },
    id: 'missed_notifications',
    created_at: new Date(),
  });

  useEffect(() => {
    setNotifications([]);
    setUnreadNotifications(false);
    setConnection();
  }, [authContextUser.id]);

  useEffect(() => {
    if (notifications.length > 0) {
      checkNotifications();
    }
  }, [notifications]);

  useEffect(() => {
    if (echo && user && user.id) {
      echo.private(`users.${user.id}`).notification(notification => {
        handleNewNotification(notification);
      });
    }
  }, [echo, user]);

  const setConnection = () => {
    if (user.id !== undefined) {
      axios
        .get('api/user/notifications')
        .then((res, rej) => {
          if (res && res.data) {
            return res;
          } else if (res.error) {
            throw res.error;
          }
          return res;
        })
        .catch(res => console.info(res))
        .then(res => {
          if (res && res.data) {
            const numberOfNewNotifications = res.data.reduce(
              (accum, notification) => (notification.read_at ? accum : accum + 1),
              0
            );
            if (numberOfNewNotifications !== 0) {
              props.showPushNotification(welcomeNotification(numberOfNewNotifications));
              setUnreadNotifications(true);
            }
            setNotifications(res.data);
          }
        });
    }
  };

  const checkNotifications = () => {
    const numberOfNewNotifications = notifications.reduce(
      (accum, notification) => (notification.read_at ? accum : accum + 1),
      0
    );
    if (numberOfNewNotifications !== 0) {
      setUnreadNotifications(true);
    } else {
      setUnreadNotifications(false);
    }
  };

  const handleNewNotification = newNotification => {
    props.showPushNotification({
      data: { link: '/user-account', ...newNotification.data },
      ...newNotification,
    });
    setNotifications(prevState => [newNotification, ...prevState]);
  };

  const handleReadNotification = id => {
    setNotifications(prevState =>
      prevState.map(notification => (notification.id === id ? { ...notification, read_at: true } : notification))
    );
    axios.put('api/user/notifications/' + id);
  };

  const { children } = props;
  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadNotifications,
        onReadNotification: handleReadNotification,
        setNotificationConnection: setConnection,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = { children: PropTypes.node };

export default withEcho(withPushNotification(NotificationProvider));
export { NotificationContext };
