import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import clsx from 'clsx';

import CardWithHeader from '../UI/CardWithHeader';
import DemoOptionsContext from '../contexts/DemoOptionsContext';
import { AuthContext } from '../contexts/AuthContext';

const CreditsCard = ({ className, balance, total, period, children, header, containerClassName, footerClassName }) => {
  const { handleCreditsButtonClicked } = useContext(DemoOptionsContext);
  const { userPermissions } = useContext(AuthContext);

  return (
    <CardWithHeader header={header} className={clsx(className)} containerClassName={containerClassName}>
      {userPermissions.allowViewCreditsInfo && userPermissions.allowViewOrganizationInvoices && (
        <div className="p-3 border-bottom d-flex justify-content-between">
          <div className="d-flex flex-row">
            <i className="fas fa-coins fa-2x my-auto font-warning" />
            <h1 className="my-auto">{balance + ' credits'}</h1>
          </div>
          <Button
            color="success"
            className="btn border-0"
            data-cy="credits-card-add-credits-btn"
            onClick={handleCreditsButtonClicked}
          >
            Buy Credits
          </Button>
        </div>
      )}
      {children}
      {userPermissions.allowViewCreditsInfo && (
        <div className={clsx('p-3 row no-gutters justify-content-between', footerClassName)}>
          <div className="col-md-auto col-12 d-flex flex-row mb-3 mb-md-0">
            <div className="font-weight-bold">{total + ' credits monthly'}</div>
          </div>
          <div className="col-md-auto col-12 d-flex d-flex flex-row mb-3 mb-md-0">
            <div>Your balance is enough for </div>
            <div className="font-weight-bold ml-1">{period + ' months'}</div>
          </div>
        </div>
      )}
    </CardWithHeader>
  );
};

export default CreditsCard;
