export default [
  {
    name: 'compRes',
    path: '',
    title: 'Computer Resources',
    id: 'computer-resources',
    infoMessage: 'Computer resources are calculated on an hourly basis.',
  },
  {
    name: 'networkRes',
    path: '/network-resources',
    title: 'Network Resources',
    id: 'network-resources',
    infoMessage:
      'Networks are calculated on a hourly basis. Networks included in your billing plan are only available to the main tenant.',
  },
  {
    name: 'networkUpgrades',
    path: '/network-upgrades',
    title: 'Network Upgrades',
    id: 'network-upgrades',
    infoMessage:
      'Network upgrades are calculated on a hourly basis and summed with the base network price. Can be charged only in the Public Cloud.',
  },
  {
    name: 'licenses',
    path: '/licenses',
    title: 'Microsoft Licenses',
    id: 'licenses',
    infoMessage: 'Licenses are calculated on an hourly basis regardless of VM status.',
  },
  {
    name: 'setupFees',
    path: '/setup-fees',
    title: 'Setup fees',
    id: 'setup-fees',
    infoMessage: 'Setup fee for creating resource.',
  },
  {
    name: 'monthFees',
    path: '/month-fees',
    title: 'Monthly fees',
    id: 'month-fees',
    infoMessage: 'Monthly fees are charged on monthly basis.',
  },
];
