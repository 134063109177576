import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Input = props => {
  const handleChange = event => {
    // this is going to call setFieldValue and manually update values
    props.onChange(props.name, event.target.value);
  };
  const handleBlur = () => {
    // this is going to call setFieldTouched
    if (props.onBlur) props.onBlur(props.name, true);
  };

  return (
    <input
      type={props.type ? props.type : 'text'}
      className={props.className ? clsx('form-control', props.className) : 'form-control'}
      name={props.name}
      pattern={props.pattern}
      disabled={props.disabled}
      checked={props.checked}
      placeholder={props.placeholder ? props.placeholder : ''}
      onBlur={handleBlur}
      value={props.value ? props.value : ''}
      onChange={handleChange}
      required={props.required}
    />
  );
};

Input.propTypes = {
  className: PropTypes.string,
};

export default Input;
