import React from 'react';

export function isInt(value) {
  return !isNaN(value) && (parseInt(Number(value)) || parseInt(Number(value)) === 0) && !isNaN(parseInt(value, 10));
}

export function objToArray(obj) {
  return Object.keys(obj).map(key => obj[key]);
}

export const RawHTML = ({ children, className = '' }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: children.replace(/\n/g, '<br />') }} />
);

export const capitalize = str => (str && str.length ? str.charAt(0).toUpperCase() + str.slice(1) : '');

export const camelCaseToTitleCase = str => {
  const result = str.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export function once(fn, context) {
  let result;

  return function(...args) {
    if (fn) {
      result = fn.apply(context || this, args);
      fn = null;
    }

    return result;
  };
}

function decimalAdjust(type, value, exp) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

export function ceil10(value, exp) {
  return decimalAdjust('ceil', value, exp);
}

export function titleCase(str, separatorChar = ' ', joinChar = ' ') {
  const splitStr = str.toLowerCase().split(separatorChar);
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(joinChar);
}

export function usernameTruncate(name, maxLength = 30) {
  return !name || name.length <= maxLength ? name : name.substr(0, maxLength) + '...';
}
