import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import LoginForm from './LoginForm';
import PasswordReset from './PasswordReset';
import SmsTwoFactorAuth from './SmsTwoFactorAuth';
import NotFound from '../NotFound';
import TotpTwoFactorAuth from './TotpTwoFactorAuth';
import TotpLostDevice from './TotpLostDevice';

const Login = () => {
  const htmlParser = ReactHtmlParser;
  return (
    <div className="auth-block">
      <h1>
        {process.env.REACT_APP_WEBSITE_NAME_HTML ? (
          <span>{htmlParser(process.env.REACT_APP_WEBSITE_NAME_HTML)}</span>
        ) : (
          <span className="bold">{process.env.REACT_APP_WEBSITE_NAME}</span>
        )}
      </h1>

      <Switch>
        <Route path="/login" exact component={LoginForm} />
        <Route path="/login/password-reset" component={PasswordReset} />
        <Route path="/login/code/sms" component={SmsTwoFactorAuth} />
        <Route path="/login/code/totp" component={TotpTwoFactorAuth} />
        <Route path="/login/code/totp-lost-device" component={TotpLostDevice} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default Login;
