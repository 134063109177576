import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FFC107"
        d="M1.875 3.75h16.25C19.16 3.75 20 4.59 20 5.625v8.75c0 1.036-.84 1.875-1.875 1.875H1.875A1.875 1.875 0 010 14.375v-8.75C0 4.589.84 3.75 1.875 3.75zM4.141 0h.468c.13 0 .235.105.235.234v.704h.234c.13 0 .234.104.234.234v1.64H3.438v-1.64c0-.13.105-.234.235-.234h.234V.234c0-.13.105-.234.235-.234zm3.75 0h.468c.13 0 .235.105.235.234v.704h.234c.13 0 .235.104.235.234v1.64H7.187v-1.64c0-.13.105-.234.235-.234h.234V.234c0-.13.105-.234.235-.234zm3.75 0h.468c.13 0 .235.105.235.234v.704h.234c.13 0 .235.104.235.234v1.64h-1.876v-1.64c0-.13.105-.234.235-.234h.234V.234c0-.13.105-.234.235-.234zm3.75 0h.468c.13 0 .235.105.235.234v.704h.234c.13 0 .235.104.235.234v1.64h-1.875v-1.64c0-.13.104-.234.234-.234h.234V.234c0-.13.105-.234.235-.234zm-.704 18.828v-1.64h1.876v1.64c0 .13-.105.235-.235.235h-.234v.703c0 .13-.105.234-.235.234h-.468a.234.234 0 01-.235-.234v-.703h-.234a.234.234 0 01-.235-.235zm-3.75 0v-1.64h1.876v1.64c0 .13-.105.235-.235.235h-.234v.703c0 .13-.105.234-.235.234h-.468a.234.234 0 01-.235-.234v-.703h-.234a.234.234 0 01-.235-.235zm-3.75 0v-1.64h1.875v1.64c0 .13-.104.235-.234.235h-.234v.703c0 .13-.105.234-.235.234h-.468a.234.234 0 01-.235-.234v-.703h-.234a.234.234 0 01-.234-.235zm-3.75 0v-1.64h1.876v1.64c0 .13-.105.235-.235.235h-.234v.703c0 .13-.105.234-.235.234h-.468a.234.234 0 01-.235-.234v-.703h-.234a.234.234 0 01-.235-.235z"
      ></path>
    </svg>
  );
}

export default Icon;
