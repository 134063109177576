import React, { useContext } from 'react';

import DemoOptionsContext from '../contexts/DemoOptionsContext';

const CreditsItem = ({ user }) => {
  const { handleCreditsButtonClicked } = useContext(DemoOptionsContext);

  const sidebarOpen = document.body.classList.contains('sidebar-open');

  return sidebarOpen ? (
    <li className="nav-item credits-item position-relative">
      <div className="py-2">
        <div className="credits-item-icon-div">
          <i className="fas fa-coins" />
        </div>
        <div className="d-inline-block">
          <span className="credits-item-credits-span">{user.credits} Credits</span>
          <br />
          <span className="credits-item-days-span">{user.demo_days} days left</span>
        </div>
        {/*<div className="credits-item-upgrade-button">
          <button
            onClick={handleCreditsButtonClicked}
            className="btn btn-sm btn-warning"
          >
            Upgrade
          </button>
        </div>*/}
      </div>
    </li>
  ) : null;
};

export default CreditsItem;
