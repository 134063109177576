import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ConfirmationModal = ({
  message,
  open,
  toggleModal,
  onSubmit,
  label,
  dataCy,
  modalCancelText,
  modalConfirmText,
  passwordConfirmation,
}) => {
  let btnRef = useRef(null);
  const inputRef = useRef(null);
  const [btnInnerRef, setBtnInnerRef] = useState(false);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    if (btnInnerRef) {
      const confirmButton = btnRef;
      confirmButton.focus();
    }
  }, [btnInnerRef]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <Modal isOpen={open}>
      <ModalHeader toggle={() => toggleModal(false)}>{label}</ModalHeader>
      <form>
        <ModalBody>
          {passwordConfirmation && (
            <>
              <label className="mt-4">For security reasons, please confirm this task by typing your password:</label>
              <input
                type="password"
                name="password"
                id="password"
                onChange={e => setPassword(e.target.value)}
                value={password || ''}
                className="form-control"
                placeholder="Enter your Password"
                ref={inputRef}
              />
            </>
          )}
          <p className="mt-4">{message}</p>
        </ModalBody>
        <ModalFooter className="d-flex flex-row">
          <Button data-cy={`cancel-${dataCy}`} className="ml-auto btn-light" onClick={() => toggleModal(false)}>
            {modalCancelText ? modalCancelText : 'Cancel'}
          </Button>
          <Button
            type="submit"
            innerRef={el => {
              btnRef = el;
              setBtnInnerRef(!!el);
            }}
            data-cy={`confirm-${dataCy}`}
            color="secondary"
            onClick={event => {
              event.preventDefault();
              onSubmit(password);
              toggleModal(false);
            }}
          >
            {modalConfirmText ? modalConfirmText : 'Confirm'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ConfirmationModal;
