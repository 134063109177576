import React, { Fragment } from 'react';
import clsx from 'clsx';
import { FormGroup, Input, Label } from 'reactstrap';

const ServiceUser = ({ rootUser, setRootUser, rootPassword, setRootPassword }) => {
  return (
    <Fragment>
      <div className="d-flex justify-content-center">
        <h3 className="p-4">Service User</h3>
      </div>
      <div className="pl-4 pr-4 pb-3">
        <p className="m-0">
          The Virtual Datacenter is using a Service User on your Windows or Linux to execute certain tasks directly on
          your system, such as Partition resizes, network changes, etc. You can install a new service user below.
        </p>
      </div>
      <div className="pl-4 pr-4 pb-3">
        <div className="d-flex justify-content-center p-3 badge-danger">
          <p className="m-0">
            If you choose to SKIP current step, you will not be able to use VDC on its full potential and will have to
            do all the tasks manually via console.
          </p>
        </div>
      </div>
      <div className="pl-4 pr-4 pb-3">
        <p className="m-0">
          Please provide us with the current Administrator (Windows) or Root Credentials (Linux). We do not save these
          credentials, and use it only to add a new service user.
        </p>
      </div>
      <div className="pl-4 pr-4 pb-4">
        <div className="row no-gutters">
          <FormGroup className={clsx('col-12 pr-md-2 mb-0 border-right pt-4')}>
            <Label>Root / Administrator login</Label>
            <Input
              data-cy="import-root-user"
              type="text"
              name="import_root_user"
              id="import-root-user"
              value={rootUser}
              autoComplete="off"
              onChange={event => setRootUser(event.target.value)}
              placeholder="Enter the Administrator or Root username"
            />
          </FormGroup>
        </div>
        <div className="row no-gutters">
          <FormGroup className={clsx('col-12 pr-md-2 mb-0 border-right pt-4')}>
            <Label>Root / Administrator password</Label>
            <Input
              data-cy="import-root-password"
              type="password"
              name="import_root_password"
              id="import-root-password"
              value={rootPassword}
              autoComplete="off"
              onChange={event => setRootPassword(event.target.value)}
              placeholder="Enter the Administrator or Root Password"
            />
          </FormGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceUser;
