import React from 'react';
import { Card } from 'reactstrap';
import FoldableStepCard from './FoldableStepCard';
import clsx from 'clsx';

const StepCard = ({ className, containerClassName, title, foldable, children, sideTitle, props }) =>
  foldable ? (
    <FoldableStepCard {...{ title, children, className, ...props }} />
  ) : (
    <div>
      <Card className={className}>
        {(title || sideTitle) && (
          <div className="d-flex cockpit align-items-center border-bottom">
            {title && <h3 className="p-4 my-auto">{title}</h3>}
            {sideTitle && <h3 className="p-4 my-auto ml-auto font-gray">{sideTitle}</h3>}
          </div>
        )}

        <div className={clsx('p-4', containerClassName)}>{children}</div>
      </Card>
    </div>
  );

export default StepCard;
