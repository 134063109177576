import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { withEcho } from '../hoc/withEcho';

import Navigation from '../Navigation';
import Header from '../Header';
import SidebarRoutes from '../Routs/Sidebar';
import Backdrop from '../UI/Backdrop';
import * as globVars from '../helpers/variables';
import ChangePasswordAuthUserModal from '../Header/ChangePasswordAuthUserModal';
import SSHKeysModal from '../Header/SSHKeysModal';
import { AuthContext } from '../contexts/AuthContext';
import { ViewContext } from '../contexts/ViewContext';
import { EchoContext } from '../contexts/EchoContext';
import { v4 as uuidv4 } from 'uuid';
import { PushNotificationContext } from '../contexts/PushNotificationContext';

const Main = props => {
  const initialModalOpen = {
    // myAccount: false,
    passwordChange: false,
    sshKeys: false,
  };
  const { user, paidTenant } = useContext(AuthContext);
  const { echo } = useContext(EchoContext);
  const screenSize = useContext(ViewContext);
  const { pushNotification } = useContext(PushNotificationContext);
  const showMenuToggleInHeader = screenSize === 'md' || screenSize === 'xs';
  const [menuShown, setMenuShown] = useState(false);
  const [modalOpen, setModalOpen] = useState(initialModalOpen);

  useEffect(() => {
    if (globVars.xlScreen) {
      if (localStorage['menu-open'] !== 'false') {
        localStorage['menu-open'] = 'true';
        document.body.classList.add('sidebar-open');
        setMenuShown(true);
      }
    }

    checkModalState();
  }, []);

  useEffect(() => {
    if (echo) {
      echo.private(`tenant.${user.tenant.tenant_identifier}`).listen('.totp_enabled', res => {
        pushNotification({
          data: {
            type: 'success',
            name: res.message,
          },
          id: uuidv4(),
          timeout: 50000,
        });
      });
    }
  }, [echo]);

  useEffect(() => {
    checkModalState();
  }, [props.location.search]);

  useEffect(() => {
    localStorage['menu-open'] = menuShown;
  }, [menuShown]);

  // This method will be sent to the children (Header, Backdrop) components
  const toggleMenu = () => {
    document.body.classList.toggle('sidebar-open');
    setMenuShown(prevState => !prevState);
  };

  const closeMenu = () => {
    document.body.classList.remove('sidebar-open');
    setMenuShown(false);
  };

  const toggleModal = () => {
    props.history.push(props.pathname);
  };

  const checkModalState = () => {
    let modalName = '';
    const mOpen = queryString.parse(props.location.search);
    if (mOpen.modal && Object.keys(modalOpen).includes(mOpen.modal)) {
      modalName = mOpen.modal;
      if (Object.keys(modalOpen).includes(modalName)) {
        const modalOpenCopy = { ...modalOpen };
        modalOpenCopy[modalName] = true;
        setModalOpen(modalOpenCopy);
      }
    } else setModalOpen(initialModalOpen);
  };

  const someModalIsOpen = Object.keys(modalOpen).some(el => modalOpen[el] === true);

  return (
    <div key={props.rerenderPage} className="main-wrapper">
      <Navigation
        closeMenu={closeMenu}
        toggleMenu={toggleMenu}
        menu={props.menu}
        menuTogglerVisible={!showMenuToggleInHeader}
        menuShown={menuShown}
        paidTenant={paidTenant}
        user={user}
      />
      <div className="wrapper">
        <div className="rs-wrapper">
          <Header toggleMenu={toggleMenu} toggleModal={toggleModal} menuTogglerVisible={showMenuToggleInHeader} />
          <div className="container-fluid content-wrapper">
            <div className="row position-relative">
              <SidebarRoutes />
            </div>
          </div>
        </div>
        <Backdrop show={menuShown} clicked={toggleMenu} />
      </div>
      {someModalIsOpen ? (
        <>
          <ChangePasswordAuthUserModal
            changePasswordModalIsOpen={modalOpen.passwordChange}
            setChangePasswordModalIsOpen={toggleModal}
          />
          <SSHKeysModal isOpen={modalOpen.sshKeys} toggleModal={toggleModal} />
        </>
      ) : null}
    </div>
  );
};

export default withEcho(withRouter(Main));
