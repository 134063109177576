import React, { Fragment, useContext, useEffect, useState } from 'react';
import axiosApp from '../../axiosInstance';
import { HeaderContext } from '../contexts/HeaderContext';
import ImportDeviceInfo from './ImportDeviceInfo';
import { CloudContext } from '../contexts/CloudContext';
import ServerSvg from '../../images/server-icon.svg';
import { Button, Card, Form } from 'reactstrap';
import FileUpload from '../UI/FileUpload';
import NetworkAssignment from './NetworkAssignment';
import ConfirmModal from './ConfirmModal';
import { ProgressBar } from 'react-bootstrap';
import ServiceUser from './ServiceUser';
import Error from './Error';
import { AuthContext } from '../contexts/AuthContext';

const UploadImportDevice = x => {
  const { setHeader } = useContext(HeaderContext);
  const { user } = useContext(AuthContext);
  const { cloudOptions, getClouds } = useContext(CloudContext);
  const [currentTenant, setCurrentTenant] = useState(null);
  const [currentCloud, setCurrentCloud] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorStep, setErrorStep] = useState('');
  const [errorButtonText, setErrorButtonText] = useState('');
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [canAssignNetwork, setCanAssignNetwork] = useState(false);
  const [canUpload, setCanUpload] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  const [step, setStep] = useState('info');
  const formData = new FormData();
  const [networks, setNetworks] = useState([]);
  const [networksFromImport, setNetworksFromImport] = useState([]);
  const [networksToImport, setNetworksToImport] = useState([]);
  const [ipsToImport, setIpsToImport] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [importId, setImportId] = useState(null);
  const [uploadUrls, setUploadUrls] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const [uploadDone, setUploadDone] = useState(false);
  const [rootUser, setRootUser] = useState('');
  const [rootPassword, setRootPassword] = useState('');
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [recentImports, setRecentImports] = useState([]);

  useEffect(() => {
    if (user.isFirstTenant) {
      axiosApp.post('/api/user/import-device/recent-imports', { amount: 10 }).then(res => {
        if (res && res.data) {
          setRecentImports(res.data.data);
        }
      });
    }
  }, []);
  const retryImport = importId => {
    if (user.isFirstTenant) {
      axiosApp.post('/api/user/import-device/start-import', { importId });
    }
  };

  useEffect(() => {
    setHeader('Upload and Import device');
  }, [setHeader]);

  useEffect(() => {
    if (!rootUser || rootUser === '') {
      setIpsToImport([]);
    }
  }, [rootUser]);

  useEffect(() => {
    setCanAssignNetwork(false);
    if (currentTenant && currentCloud && deviceName && filesUploaded) {
      setCanAssignNetwork(true);
    }
  }, [currentTenant, currentCloud, deviceName, filesUploaded]);

  useEffect(() => {
    setFilesUploaded(false);
    if (files.length > 0) {
      const ovfExists = files.filter(el => el.name.split('.').pop() === 'ovf').length > 0;
      setFilesUploaded(ovfExists);
      if (!ovfExists) {
        setError('File descriptor (.ovf) must be uploaded');
      }
    }
  }, [files]);

  useEffect(() => {
    axiosApp
      .get('api/user/tenants/list')
      .then(res => {
        if (res && res.data) {
          const tenantOptions = res.data.map(tenant => ({
            label: tenant.name,
            value: tenant.tid,
          }));
          setTenants(tenantOptions);
          if (tenantOptions.length === 1) {
            setCurrentTenant(tenantOptions[0]);
            getClouds(tenantOptions[0].value);
          }
        }
      })
      .catch(e => console.warn(e.message));
  }, []);

  useEffect(() => {
    if (cloudOptions && cloudOptions.length === 1 && !currentCloud) {
      handleCloudChange(cloudOptions[0]);
    } else if (cloudOptions && cloudOptions.length > 1 && !currentCloud) {
      const cloudToSet = cloudOptions.map(el => el.typeAndId.split(' ')[0]).indexOf('Private');
      cloudToSet >= 0 ? handleCloudChange(cloudOptions[cloudToSet]) : handleCloudChange(cloudOptions[0]);
    }
  }, [cloudOptions]);

  useEffect(() => {
    if (step === 'service-user') {
      formData.append('descriptor', files.filter(el => el.name.split('.').pop() === 'ovf')[0]);
      files.forEach((el, index) => {
        formData.append(`files[${index}]`, el.name);
      });
      axiosApp
        .post('/api/user/import-device/parse-descriptor-files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          if (res && res.data) {
            if (res.data.length > 0) {
              const message = 'Please, upload following file(s): ' + res.data.toString();
              setValidationError(message);
              setStep('error');
              setErrorTitle('File existence validation');
              setErrorStep('info');
              setErrorButtonText('Prev step: Initial setup');
            }
          }
        });
    }

    if (step === 'network') {
      formData.append('descriptor', files.filter(el => el.name.split('.').pop() === 'ovf')[0]);
      formData.append('hvSystemId', currentCloud.value);
      axiosApp
        .post('/api/user/import-device/parse-descriptor-network', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          if (res && res.data) {
            setNetworksFromImport(res.data);
          }
        });
    }
  }, [step]);

  useEffect(() => {
    if (currentCloud && currentTenant) {
      const payload = {
        hv_system_id: currentCloud.value,
        tenantIdentifier: currentTenant.value,
      };
      axiosApp.post(`/api/user/simple-networks`, payload).then(res => {
        if (res && res.data) {
          const networksOptions = res.data.map(network => ({
            label: `[${network.type}] ${network.name} (${network.network})`,
            value: network.id,
          }));
          setNetworks(networksOptions);
        }
      });
    }
  }, [currentTenant, currentCloud]);

  useEffect(() => {
    if (networksToImport.length > 0 && networksToImport.length === networksFromImport.length) {
      setCanUpload(true);
    }
  }, [networksToImport, ipsToImport, networksFromImport]);

  useEffect(() => {
    if (importId) {
      const payload = {
        files: files.map(file => file.name),
        ttl: 2880, // 2 days
        type: 'PutObject',
        method: 'PUT',
      };
      axiosApp.post(`/api/user/import-device/presigned-urls`, payload).then(res => {
        if (res && res.data) {
          setUploadUrls(res.data);
        }
      });
    }
  }, [importId]);

  useEffect(() => {
    if (uploadUrls.length > 0) {
      files.forEach(async file => {
        const fileUrl = uploadUrls.filter(f => f.fileName === file.name)[0].url;

        await axiosApp.post(`/api/user/import-device/store-file-upload`, {
          fileName: file.name,
          fileUrl,
          importId,
        });

        const formData = new FormData();
        formData.append('file', file);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', fileUrl, true);
        xhr.upload.onprogress = e => {
          if (e.lengthComputable) {
            setUploadProgress(prevState => ({
              ...prevState,
              [file.name]: Math.round((e.loaded / e.total) * 100),
            }));
          }
        };
        // this is a monkeypatch to prevent payload from wrapping in headers
        // https://stackoverflow.com/questions/38781909/webkitformboundary-included-in-file-payload-on-direct-upload-to-s3
        const _send = xhr.send;
        xhr.send = () => {
          _send.call(xhr, file);
        };
        xhr.send(formData);

        xhr.onerror = () => {
          alert(file.name + ' upload  failed');
          updateFileUploadStatus(file.name, importId, 'error', error.response.data.error);
          setUploadError(error.response.data.error);
        };

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            updateFileUploadStatus(file.name, importId, 'uploaded');
          }
        };
      });
    }
  }, [uploadUrls]);

  useEffect(() => {
    const uploading = Object.entries(uploadProgress).filter(([key, value]) => value !== 100);
    if (JSON.stringify(uploadProgress) !== '{}' && uploading.length === 0) {
      setTimeout(() => {
        setUploadDone(true);
      }, 5000);
    }
  }, [uploadProgress]);

  useEffect(() => {
    if (uploadDone) {
      const payload = { importId, rootUser, rootPassword };
      axiosApp.post(`/api/user/import-device/start-import`, payload);
    }
  }, [uploadDone]);

  useEffect(() => {
    axiosApp
      .get('api/user/import-device/templates')
      .then(res => {
        if (res && res.data) {
          const templatesOptions = res.data.map(template => ({
            label: template.name,
            value: template.id,
          }));
          setTemplates(templatesOptions);
        }
      })
      .catch(e => console.warn(e.message));
  }, []);

  const handleTenantChange = tenant => {
    setCurrentTenant(tenant);
    setCurrentCloud(null);
    getClouds(tenant.value);
  };

  const handleCloudChange = cloud => {
    setCurrentCloud(cloud);
  };

  const removeAll = () => {
    setFiles([]);
  };

  const handleNetworkAssignmentChange = (network, index) => {
    const newArray = Array.from(networksToImport);
    newArray[index] = network;
    setNetworksToImport(newArray);
  };

  const handleIPAssignmentChange = (ip, index) => {
    const newArray = Array.from(ipsToImport);
    newArray[index] = ip;
    setIpsToImport(newArray);
  };

  const handleModalOpen = () => {
    setOpenConfirmModal(!openConfirmModal);
  };

  const handleImportSubmit = () => {
    setOpenConfirmModal(false);
    // save import data to DB
    const payload = {
      tenantIdentifier: currentTenant.value,
      hvSystemId: currentCloud.value,
      displayName: deviceName,
      templateId: currentTemplate.value,
      networks: networksFromImport.map((network, index) => ({
        name: network.name,
        tenantNetwork: networksToImport[index],
        tenantIp: ipsToImport[index],
      })),
      files: files.map(file => file.name),
    };

    axiosApp
      .post(`/api/user/import-device/store`, payload)
      .then(res => {
        if (res && res.status === 200) {
          const progress = {};
          files.forEach(file => {
            progress[file.name] = 0;
          });
          setUploadProgress(progress);

          setImportId(res.data.importId);
          setStep('upload');
        }
      })
      .catch(error => {
        setValidationError(error.response.data.errors.networks[0]);
        setStep('error');
        setErrorTitle('Network assignment');
        setErrorStep('network');
        setErrorButtonText('Prev step: Network assignment');
      });
  };

  const updateFileUploadStatus = (fileName, importId, status, message) => {
    const payload = {
      fileName,
      importId,
      status,
      message,
    };
    axiosApp.post(`/api/user/import-device/update-file-upload-status`, payload).then(res => {
      if (res && res.status === 200) {
        console.info(fileName + ' upload status updated!');
      }
    });
  };

  const handleTemplateChange = templateId => {
    setCurrentTemplate(templateId);
  };

  return (
    <Fragment>
      <Form className="container max-width-rem-70">
        <Card className="p-0 w-100">
          <div className="header-container">
            <img src={ServerSvg} alt="server" className="header-icon" />
            <div className="col ml-5 d-flex flex-column justify-content-center">
              <h1 className="pl-0">Upload and Import new Device</h1>
            </div>
          </div>
        </Card>
        {step === 'info' && (
          <>
            <Card className="mt">
              <ImportDeviceInfo
                tenants={tenants}
                clouds={cloudOptions}
                currentCloud={currentCloud}
                currentTenant={currentTenant}
                handleTenantChange={handleTenantChange}
                handleCloudChange={handleCloudChange}
                deviceName={deviceName}
                setDeviceName={setDeviceName}
                currentTemplate={currentTemplate}
                templates={templates}
                handleTemplateChange={handleTemplateChange}
              />
              <div className="p-4">
                <FileUpload
                  files={files}
                  setFiles={setFiles}
                  setError={setError}
                  message="<span>Upload all the necessary files <br>(.ovf, .vmdk, .nvram etc.)</span>"
                />
                {files.length > 0 && (
                  <button className="btn btn-pink" onClick={removeAll} data-cy="remove-all-uploaded-files">
                    Remove all files
                  </button>
                )}
                <p style={{ color: error ? 'red' : 'rgb(51,51,51)' }} className="mt-2">
                  {error ? error : ''}
                </p>
                <div className="d-flex justify-content-md-end">
                  <Button
                    className="btn-success w-40"
                    type="button"
                    onClick={() =>
                      setStep(
                        currentTemplate && currentTemplate.label === 'Firewall Generic' ? 'network' : 'service-user'
                      )
                    }
                    disabled={!canAssignNetwork}
                  >
                    {currentTemplate && currentTemplate.label === 'Firewall Generic' ? (
                      <span>Next step: Network assignment</span>
                    ) : (
                      <span>Next step: Create service user</span>
                    )}
                  </Button>
                </div>
              </div>
            </Card>
            {user.isFirstTenant && (
              <Card className="p-0 w-100 mt">
                <div className="header-container">
                  <div className="col ml-5 d-flex flex-column justify-content-center">
                    <h1 className="pl-0">Re-import recent devices</h1>
                  </div>
                </div>
                <div className="p-4">
                  {recentImports.length > 0 &&
                    recentImports.map(recentImport => (
                      <div className="row no-gutters pb-3" key={recentImport.id}>
                        <div className="w-100 d-flex">
                          <div className="w-50">
                            Name: {recentImport.vm_display_name}, Cloud:
                            {recentImport.hv_system_id}, Date: {recentImport.created_at}{' '}
                          </div>
                          <div className="w-50">
                            <Button
                              className="btn-sm btn-danger w-40"
                              type="button"
                              onClick={() => retryImport(recentImport.id)}
                            >
                              <span>Retry</span>
                            </Button>
                          </div>
                        </div>
                        <div className="pl-4">
                          {recentImport.files.map(file => (
                            <div key={file.id}>{file.filename}</div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </Card>
            )}
          </>
        )}
        {step === 'service-user' && (
          <Card className="mt">
            <ServiceUser
              setRootUser={setRootUser}
              setRootPassword={setRootPassword}
              rootUser={rootUser}
              rootPassword={rootPassword}
            />

            <div className="p-4">
              <div className="d-flex justify-content-md-between">
                <Button className="btn-danger w-40" type="button" onClick={() => setStep('info')}>
                  Prev step: Upload files
                </Button>
                <Button
                  className="btn-success w-40"
                  type="button"
                  onClick={() => setStep('network')}
                  disabled={!canAssignNetwork}
                >
                  Next step: Network assignment
                </Button>
              </div>
            </div>
          </Card>
        )}
        {step === 'network' && (
          <Card className="mt">
            <NetworkAssignment
              networksFromImport={networksFromImport}
              networks={networks}
              networksToImport={networksToImport}
              ipsToImport={ipsToImport}
              handleNetworkAssignmentChange={handleNetworkAssignmentChange}
              handleIPAssignmentChange={handleIPAssignmentChange}
              rootUser={rootUser}
              rootPassword={rootPassword}
              currentTemplate={currentTemplate}
            />

            <div className="p-4">
              <div className="d-flex justify-content-md-between">
                <Button
                  className="btn-danger w-40"
                  type="button"
                  onClick={() =>
                    setStep(currentTemplate && currentTemplate.label === 'Firewall Generic' ? 'info' : 'service-user')
                  }
                >
                  {currentTemplate && currentTemplate.label === 'Firewall Generic' ? (
                    <span>Prev step: Upload files</span>
                  ) : (
                    <span>Prev step: Create service user</span>
                  )}
                </Button>
                <Button
                  className="btn-success w-40"
                  type="button"
                  onClick={() => handleModalOpen(true)}
                  disabled={!canUpload}
                >
                  Import Device
                </Button>
              </div>
            </div>
          </Card>
        )}
        {step === 'error' && (
          <Error
            title={errorTitle}
            validationError={validationError}
            setStep={setStep}
            step={errorStep}
            buttonText={errorButtonText}
          />
        )}
        {step === 'upload' && (
          <Fragment>
            <Card className="mt">
              {uploadError && !uploadDone && (
                <div className="d-flex justify-content-center p-3 badge-danger mb-3">
                  <p className="m-0 text-center">
                    Upload ERROR! <br />
                    {uploadError}
                  </p>
                </div>
              )}
              {!uploadError && !uploadDone && (
                <div className="d-flex justify-content-center p-3 badge-danger">
                  <p className="m-0 text-center">DO NOT close the import tab until the process is completed.</p>
                </div>
              )}
              {uploadDone && (
                <div className="d-flex justify-content-center p-3 badge-success">
                  <p className="m-0 text-center">
                    Files were uploaded successfully. The import will start soon. <br />
                    You can close this tab — we'll notify you upon completion.
                  </p>
                </div>
              )}
            </Card>
            <Card className="mt">
              <div className="p-4">
                <div className="d-flex justify-content-center">
                  <h3>Files upload progress</h3>
                </div>
                <div className="file-block">
                  {files.map((file, index) => (
                    <div key={`upload-file-${index}`} className="pt-3 pb-3 border-bottom mt-3">
                      <div className="mb-2">{file.name}</div>
                      {uploadProgress && (
                        <ProgressBar
                          active={true}
                          striped={true}
                          now={uploadProgress[file.name]}
                          label={`${uploadProgress[file.name]}%`}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          </Fragment>
        )}
      </Form>

      {openConfirmModal && (
        <ConfirmModal
          handleModalOpen={handleModalOpen}
          deviceName={deviceName}
          currentCloud={currentCloud}
          currentTenant={currentTenant}
          networksFromImport={networksFromImport}
          networksToImport={networksToImport}
          networks={networks}
          ipsToImport={ipsToImport}
          files={files}
          handleImportSubmit={handleImportSubmit}
        />
      )}
    </Fragment>
  );
};

export default UploadImportDevice;
