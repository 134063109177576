import React from 'react';
import { Card, Table } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

import NoDataMsg from '../UI/NoDataMsg';
import HeaderCard1 from '../UI/Headers/HeaderCard1';

const BillingInfo = props => {
  const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || '';
  const content =
    props.data && Object.keys(props.data).length ? (
      <Table borderless size="sm">
        <tbody className="table-left-columns">
          {Object.keys(props.data).map(el => (
            <tr key={el}>
              <td className="md valign-top">
                {props.data[el].title[0].toUpperCase() + props.data[el].title.slice(1).toLowerCase()}
              </td>
              <td>
                {el === 'type' ? (
                  typeof props.billingPlanState.state.val === 'undefined' ? (
                    <Skeleton />
                  ) : (
                    props.billingPlanState.state.val
                  )
                ) : typeof props.data[el].val === 'undefined' ? (
                  <Skeleton />
                ) : (
                  props.data[el].val
                )}
              </td>
              {el === 'type' && props.billingPlanState.trial.val ? <td>TRIAL</td> : null}
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <NoDataMsg noCard />
    );

  return (
    <Card className="mt">
      <HeaderCard1 title="Billing" />
      <div className="pt-3">{content}</div>
    </Card>
  );
};
export default BillingInfo;
