import React from 'react';

import { PushNotificationContext } from '../contexts/PushNotificationContext';

const withPushNotifications = Component => props => (
  <PushNotificationContext.Consumer>
    {contexts => <Component {...props} {...contexts} />}
  </PushNotificationContext.Consumer>
);
export default withPushNotifications;
