export const updateToken = token => {
  const appState = JSON.parse(localStorage.getItem('appState'));
  appState.token = appState.user.apiToken = token;
  localStorage.setItem('appState', JSON.stringify(appState));
  localStorage.setItem('token', token);
};

export const removeToken = () => {
  localStorage.removeItem('appState');
  localStorage.removeItem('token');
};
