import Skeleton from 'react-loading-skeleton';
import React from 'react';

const SimpleGridSkeleton = ({ rows, columns, height = 30 }) => {
  return (
    <div className="p-4">
      {[...Array(rows).keys()].map(r => (
        <div key={`row-` + r} className="row no-gutters">
          {[...Array(columns).keys()].map(c => (
            <div key={`col-` + c} className="col-4 p-1 md-2">
              <Skeleton height={height} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SimpleGridSkeleton;
