const resourcesToId = {
  windows: 9,
  cpucores: 5,
  cpu: 5,
  memory: 6,
  ram: 6,
  disksize: 7,
  ssd: 7,
  swapdisksize: 7,
  snapshot: 8,
  hdd: 39,
  backup: 40,
};

export default resourcesToId;
