import { FormGroup, Input, Label } from 'reactstrap';
import clsx from 'clsx';
import Select from '../UI/SelectRequired';
import Skeleton from 'react-loading-skeleton';
import React, { Fragment } from 'react';
import SimpleGridSkeleton from '../UI/SimpleGridSkeleton';
import { IPv4Pattern } from '../../utils/constants';

const NetworkAssignment = ({
  networksFromImport,
  networks,
  networksToImport,
  ipsToImport,
  handleNetworkAssignmentChange,
  handleIPAssignmentChange,
  rootUser,
  rootPassword,
  currentTemplate,
}) => {
  return (
    <Fragment>
      <div className="d-flex justify-content-center">
        <h3 className="p-4">Network assignment</h3>
      </div>
      {networksFromImport.length > 0 ? (
        <div className="pl-4 pr-4 pb-4">
          {networksFromImport.map((network, index) => (
            <div key={network.name} className="row no-gutters">
              <FormGroup className={clsx('col-4 pr-md-2 mb-0 border-right pt-4')}>
                <Label>Network from .ovf descriptor</Label>
                <Input
                  data-cy="import-network"
                  type="text"
                  name="import_network"
                  id={`import-network-${index}`}
                  value={network.name}
                  autoComplete="off"
                  required
                  readOnly
                />
              </FormGroup>
              <FormGroup className={clsx('col-4 p-2 mb-0 border-right pt-4')}>
                <Label>Select Network to map</Label>
                {networks ? (
                  <>
                    <Select
                      required
                      placeholder="Select Network"
                      options={networks}
                      value={networks.filter(el => el.value === networksToImport[index])}
                      dataCy="upload-import-device-network-select"
                      onChange={event => handleNetworkAssignmentChange(event.value, index)}
                    />
                  </>
                ) : (
                  <Skeleton height={35} />
                )}
              </FormGroup>
              <FormGroup className={clsx('col-4 pl-md-2 mb-0 border-right pt-4')}>
                <Label>Assign IP</Label>
                {currentTemplate.label.toLowerCase().includes('windows') ? (
                  <div className="no-service-user">
                    Windows device's IP address should be set manually after import.
                  </div>
                ) : rootUser && rootPassword ? (
                  <Input
                    data-cy="import-network-ip"
                    type="text"
                    name="import_network_ip"
                    autoComplete="off"
                    required
                    onChange={event => handleIPAssignmentChange(event.target.value, index)}
                    value={ipsToImport[index] !== undefined ? ipsToImport[index] : ''}
                  />
                ) : (
                  <div className="no-service-user">IP can not be assigned without service user</div>
                )}

                <div className="form-error-message">
                  {ipsToImport[index] !== undefined
                    ? ipsToImport[index].match(IPv4Pattern)
                      ? ''
                      : 'Please, provide valid IP address'
                    : ''}
                </div>
              </FormGroup>
            </div>
          ))}
        </div>
      ) : (
        <SimpleGridSkeleton rows={3} columns={3} />
      )}
    </Fragment>
  );
};

export default NetworkAssignment;
