import React, { useState, useEffect } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import MultiInput from '../../UI/MultiInput';
import { updateAllowedIPs } from '../../../utils/kubernetes';
import clsx from 'clsx';

const K8sConfiguration = ({
  clusterK8sConfig,
  fetchingClusterConfig,
  clusterIdentifier,
  downloadConfig,
  setUpgradeK8sVersionModalOpen,
}) => {
  const [initialIps, setInitialIps] = useState([]);
  const [isSetInitialIps, setIsSetInitialIps] = useState(false);
  const [ips, setIps] = useState(clusterK8sConfig.k8sApiAllowedIps);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isMultiInputChanged, setIsMultiInputChanged] = useState(false);

  useEffect(() => {
    setIps(clusterK8sConfig.k8sApiAllowedIps);

    if (clusterK8sConfig.k8sApiAllowedIps && !isSetInitialIps) {
      setInitialIps(clusterK8sConfig.k8sApiAllowedIps);
      setIsSetInitialIps(true);
    }
  }, [clusterK8sConfig.k8sApiAllowedIps]);

  useEffect(() => {
    if (!isMultiInputChanged) {
      setIsDisabled(JSON.stringify(ips) === JSON.stringify(initialIps));
    } else {
      setIsDisabled(false);
    }
  }, [ips, isMultiInputChanged]);

  return (
    <Card className="mb-4">
      <CardBody className="d-flex flex-row justify-content-between card-header-low-border">
        <h3 className="mb-0" style={{ fontSize: '1.125rem' }}>
          <i className="fas fa-cog mr-2" />
          Kubernetes Configuration
        </h3>
      </CardBody>
      <CardBody className="px-0">
        {!fetchingClusterConfig && clusterK8sConfig.k8sApiEndpoint !== 'Unknown' ? (
          <>
            <Table borderless size="sm" className="mb-0">
              <tbody className="table-left-columns wide">
                <tr>
                  <td>Kube Config</td>
                  <td>
                    <button className="btn btn-link p-0 text-blue-dark cursor-pointer">
                      <u onClick={() => downloadConfig('kube')}>Download config</u>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>K8S API Endpoint</td>
                  <td>{clusterK8sConfig.k8sApiEndpoint}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Cluster Domain</td>
                  <td>{clusterK8sConfig.clusterDomain}</td>
                </tr>
                <tr>
                  <td>Kubernetes Version</td>
                  <td>{clusterK8sConfig.k8sVersion}</td>
                  <td className="d-flex justify-content-end">
                    <button
                      className="btn btn-sm btn-success mr-3 update-button"
                      onClick={() => setUpgradeK8sVersionModalOpen(true)}
                    >
                      Upgrade
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Pod Subnet</td>
                  <td>{clusterK8sConfig.podSubnet}</td>
                </tr>
                <tr>
                  <td>Service Subnet</td>
                  <td>{clusterK8sConfig.serviceSubnet}</td>
                  <td></td>
                </tr>
                <tr className="tr-full-width">
                  <td>K8S API Allowed IPs</td>
                  <td></td>
                  <td className="d-flex justify-content-end">
                    <button
                      className={clsx(
                        'btn btn-sm btn-success mr-3 update-button',
                        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                      )}
                      onClick={() => updateAllowedIPs(clusterIdentifier, 'k8s', ips, setIsDisabled)}
                      disabled={isDisabled}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="pt-2 pl-4 pr-4">
              <MultiInput
                wrapperClassName="mb-0"
                id="k8s-allowed-ip1"
                dataCy="k8s-allowed-ip"
                value={ips}
                onChange={setIps}
                placeholder="Leave empty for access from any IP or enter a single IP address or a range"
                setIsMultiInputChanged={setIsMultiInputChanged}
              />
            </div>
          </>
        ) : (
          <div className="pl-2 pr-2">
            <Skeleton height={24} count={10} />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default K8sConfiguration;
