import React, { useContext, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';

import * as globalVars from '../helpers/variables';
import { AuthContext } from '../contexts/AuthContext';

const MenuLinks = props => {
  const [menuOpen, setMenuOpen] = useState({});
  const { user } = useContext(AuthContext);

  const closeMenu = () => {
    if (!globalVars.xlScreen) {
      props.closeMenu();
    }
  };

  const sidebarOpen = document.body.classList.contains('sidebar-open');
  return (
    props.menu &&
    props.menu.map(el => (
      <React.Fragment key={uuidv4()}>
        {el.title && (
          <li
            onClick={() =>
              setMenuOpen({
                ...menuOpen,
                [el.title]: !menuOpen[el.title],
              })
            }
            key={uuidv4()}
            className="nav-item heading position-relative"
          >
            <span>{el.title}</span>
            {el.foldable && (
              <div
                style={{
                  right: 10,
                  top: 0,
                }}
                className="position-absolute h-100 d-flex justify-content-center align-items-center"
              >
                <i className={clsx('fas', !menuOpen[el.title] ? 'fa-chevron-up' : 'fa-chevron-down')} />
              </div>
            )}
          </li>
        )}
        {el.links &&
          (!el.foldable || (el.foldable && !menuOpen[el.title])) &&
          el.links.map(link => (
            <li key={uuidv4()} className={'nav-item ' + (link.className ? link.className : '')}>
              {
                <>
                  <NavLink
                    exact={link.path === '/'}
                    to={link.path}
                    className={clsx('nav-link', props.disabled ? 'disabled' : '')}
                    id={link.id}
                    onClick={closeMenu}
                  >
                    <i className={link.icons} />
                    <span>{link.title}</span>
                    {(link.title === 'Kubernetes' ||
                      link.title === 'Persistent Storage' ||
                      link.title === 'Databases') && (
                      <span className="sm badge badge-danger badge-pill ml-3">Beta</span>
                    )}
                    {link.title === 'Legal docs' && user.hasDocumentsToAccept && !user.createdAfterDocumentsDeadline && (
                      <span
                        className={clsx(
                          'sm',
                          'badge',
                          'badge-pill',
                          'ml-3',
                          user.acceptDocumentsDaysLeft < 0 ? 'badge-danger' : 'badge-success'
                        )}
                      >
                        {Math.abs(user.acceptDocumentsDaysLeft)} day
                        {Math.abs(user.acceptDocumentsDaysLeft) !== 1 ? 's' : ''}{' '}
                        {user.acceptDocumentsDaysLeft < 0 ? 'overdue' : 'left'}
                      </span>
                    )}
                  </NavLink>
                  {!sidebarOpen ? (
                    <UncontrolledTooltip
                      placement="left"
                      target={link.id}
                      delay={{ show: 0, hide: 0 }}
                      boundariesElement="window"
                    >
                      <div className="d-flex align-items-center">
                        {link.title}
                        {(link.title === 'Kubernetes' ||
                          link.title === 'Persistent Storage' ||
                          link.title === 'Databases') && (
                          <span className="sm badge badge-danger badge-pill ml-3">Beta</span>
                        )}
                      </div>
                    </UncontrolledTooltip>
                  ) : null}
                </>
              }
            </li>
          ))}
      </React.Fragment>
    ))
  );
};

export default MenuLinks;
