import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#4664E9"
        d="M16.667 10.833H3.333c-.92 0-1.666.747-1.666 1.667v3.333c0 .92.746 1.667 1.666 1.667h13.334c.92 0 1.666-.747 1.666-1.667V12.5c0-.92-.746-1.667-1.666-1.667zM13.333 15h-1.666v-1.667h1.666V15zm3.334 0H15v-1.667h1.667V15zm.645-5.833h.188l-1.377-6.195a1.666 1.666 0 00-1.626-1.305H5.503c-.78 0-1.457.542-1.626 1.305L2.5 9.167h14.812z"
      ></path>
    </svg>
  );
}

export default Icon;
