import axios from '../axiosInstance';

export const updateAllowedIPs = (clusterIdentifier, type, ips, setIsDisabled) => {
  setIsDisabled(true);
  const payload = {
    type,
    ips: ips.join(','),
  };
  axios
    .patch(`/api/user/kubernetes-talos/${clusterIdentifier}/update-allowed-ips`, payload)
    .then(res => {
      if (res.status === 200) {
        setIsDisabled(false);
      }
    })
    .catch(error => {
      console.log(error.message);
      setIsDisabled(false);
    });
};
