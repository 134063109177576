import React, { useEffect, useState } from 'react';
import { Card, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Skeleton from 'react-loading-skeleton';

import axios from '../../axiosInstance';
import { createdAtColumn } from '../../utils/constants';

const columns = openInvoice => {
  return [
    {
      text: 'Description',
      dataField: 'description',
      formatter: cell => cell,
    },
    { ...createdAtColumn(true, 'createdAt') },
    {
      text: 'Amount',
      dataField: 'amount',
      formatter: cell => {
        if (cell <= 0) {
          return <p style={{ color: 'red' }}>{cell}</p>;
        } else {
          return <p style={{ color: 'green' }}>{cell}</p>;
        }
      },
    },
    {
      text: 'Balance',
      dataField: 'balance',
      formatter: cell => <p>{cell}</p>,
    },
    {
      text: 'Invoice',
      dataField: 'invoiceId',
      formatter: cell => {
        if (cell) {
          return (
            <Button className="btn-table-secondary" onClick={() => openInvoice(cell)}>
              Open
            </Button>
          );
        } else {
          return <p className="mb-0 font-gray">No info</p>;
        }
      },
    },
  ];
};

const Transactions = ({ tenantId }) => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getTransactions = () => {
    setIsLoading(true);

    let url = '/api/user/transactions';

    if (tenantId) {
      url += `/${tenantId}`;
    }

    axios
      .get(url)
      .then(res => {
        setTransactions(res.data);
      })
      .finally(() => setIsLoading(false));
  };

  const openInvoice = invoiceId => {
    axios(`/api/user/invoice/${invoiceId}`, {
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  };

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    getTransactions();
  }, [tenantId]);

  return (
    <Card className="border-0">
      {!isLoading ? (
        <>
          {transactions.length ? (
            <BootstrapTable
              columns={columns(openInvoice)}
              data={transactions}
              keyField="id"
              classes="entity-block-table"
              wrapperClasses="text-left"
            />
          ) : (
            <p className="p-3">Transaction list is empty</p>
          )}
        </>
      ) : (
        <Skeleton height={100} />
      )}
    </Card>
  );
};

export default Transactions;
