import React from 'react';
import clsx from 'clsx';
// show invalid inputs to the user

export const handleObserveInvalidInputs = () => {
  const invalidInputs = document.querySelectorAll('.invalid-feedback');
  let i = 0;
  while (i < invalidInputs.length) {
    if (invalidInputs[i].textContent.length > 0) {
      invalidInputs[i].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      break;
    }
    i++;
  }
};

// find a property of an object
// correctly works only for unique names

export const findKey = (obj, key) => {
  for (const property in obj) {
    if (typeof obj[property] !== 'object') {
      const result = obj[key];
      if (result) {
        return result;
      }
    } else {
      const result = findKey(obj[property], key);
      if (result) {
        return result;
      }
    }
  }
  return undefined;
};

// show updates widget

export const showUpdatesWidget = () => {
  const head = document.getElementsByTagName('head')[0];
  const js = document.createElement('script');
  js.innerHTML = `
    (function (w,d,s,o,f,js,fjs) { w['ReleaseNotesWidget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs); }
    (window, document, 'script', 'rnw', 'https://s3.amazonaws.com/cdn.releasenotes.io/v1/bootstrap.js'));

    rnw('init', {
        account: 'changelog.xelon.ch',
        selector: '.rn-badge',
        title: 'Latest Updates from Xelon',
    });
  `;
  head.appendChild(js);
};

// find division remainder

export const divideByEleven = data => {
  if (typeof data !== 'number') {
    return false;
  }
  if (data % 10 === 1 && data % 100 !== 11) {
    return true;
  } else return false;
};

// filter ip addresses when creating devices

export const filterIPs = (networks, results, ipValues) => {
  let ipOptions = networks.map(
    nic =>
      (results['networkid' + nic.nicnumber] &&
        nic.networks
          .find(network => network.value === results['networkid' + nic.nicnumber])
          ?.ips.map(el => ({
            value: el.value,
            label: el.text,
          }))) ||
      []
  );
  const filteredIpsArray = [];
  for (let i = 0; i < ipOptions.length; i++) {
    if (ipOptions[i].length > 0) {
      let updatedIpOptions = ipOptions[i];
      for (let j = 0; j < ipValues.length; j++) {
        updatedIpOptions = updatedIpOptions.filter(el => el.value !== ipValues[j].value);
      }
      filteredIpsArray.push(updatedIpOptions);
    } else filteredIpsArray.push([]);
  }
  ipOptions = [...filteredIpsArray];
  return ipOptions;
};
function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.info('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

// text copy logic
export const handleCopyText = textToCopy => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(textToCopy);
    return;
  }
  navigator.clipboard.writeText(textToCopy).then(
    function() {
      console.info('Copying to clipboard was successful!');
    },
    function(err) {
      console.error('Could not copy text: ', err);
    }
  );
};

// favicon change logic

export const handleFaviconChange = faviconName => {
  const head = document.getElementsByTagName('head')[0];
  const iconLink = head.querySelector('[rel="shortcut icon"]');
  head.removeChild(iconLink);
  const newIconLink = document.createElement('link');
  head.appendChild(newIconLink);
  newIconLink.rel = 'shortcut icon';
  newIconLink.href = faviconName;
};

export const numberField = (number, prefix = '', showZero = false, description = null, textDotted = false) => (
  <>
    <span
      title={number ? prefix + Math.round(number * 1000000) / 1000000 : ''}
      className={clsx(textDotted && 'text-dotted')}
    >
      {number ? prefix + Math.round(number * 100) / 100 : showZero ? 0 : ''}
    </span>
    {description && <span className="ml-2 text-muted"> ({description})</span>}
  </>
);
