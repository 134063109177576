import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';

const ConfirmModal = ({
  handleModalOpen,
  deviceName,
  currentCloud,
  currentTenant,
  networksFromImport,
  networksToImport,
  networks,
  ipsToImport,
  files,
  handleImportSubmit,
}) => {
  return (
    <Modal centered isOpen={true} className="modal-lg">
      <ModalHeader toggle={handleModalOpen}>Confirm the imported device configuration</ModalHeader>
      <ModalBody className="text-center">
        <div className="p-4">
          <div className="d-flex justify-content-center my-3 p-3 badge-danger">
            <p className="m-0">
              Please carefully review the configuration above. <br />
              DO NOT close the import tab until the process is completed.
            </p>
          </div>
        </div>
        <Table>
          <tbody className="text-left">
            <tr>
              <td width="230px">
                <strong>Organization</strong>
              </td>
              <td>{currentTenant.label}</td>
            </tr>
            <tr>
              <td width="230px">
                <strong>Cloud</strong>
              </td>
              <td>{currentCloud.label}</td>
            </tr>
            <tr>
              <td width="230px">
                <strong>Device display name</strong>
              </td>
              <td>{deviceName}</td>
            </tr>
            <tr>
              <td colSpan="2">
                <strong>Networks</strong>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                {networksFromImport.map((network, index) => {
                  return (
                    <div key={`network-${index}`} className="row w-100">
                      <div className="col-4 p-1 pl-4 pr-0">{network.name}</div>
                      <div className="col-4 p-1 pl-2 pr-2">
                        {networks.filter(nw => nw.value === networksToImport[index])[0].label}
                      </div>
                      <div className="col-4 p-1 pl-0">
                        {ipsToImport[index] ? (
                          ipsToImport[index]
                        ) : (
                          <div className="text-danger">No IP address assigned</div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <strong>Files to upload</strong>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                {files.map((file, index) => {
                  return <div key={`file-${index}`}>{file.name}</div>;
                })}
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <div className="p-4 w-100">
          <div className="d-flex justify-content-end">
            <Button className="btn-success w-40" type="button" onClick={() => handleImportSubmit()}>
              Confirm and Import
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
