import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import CustomizedSkeletonComponent from '../UI/CustomizedSkeletonComponent';
import BillingPlanResourcesTable from './BillingPlanResourcesTable';

const SectionRouts = props =>
  props.billing_plan && Object.keys(props.billing_plan).length ? (
    <Switch>
      <Route
        path={props.match.url + '/network-resources'}
        exact
        render={() => (
          <BillingPlanResourcesTable
            keyField="id"
            data={props.billing_plan.resources['2']}
            type_id={props.billing_plan.type_id}
          />
        )}
      />

      <Route
        path={props.match.url + '/network-upgrades'}
        exact
        render={() => (
          <BillingPlanResourcesTable
            keyField="id"
            data={props.billing_plan.resources['4']}
            type_id={props.billing_plan.type_id}
          />
        )}
      />

      <Route
        path={props.match.url + '/licenses'}
        exact
        render={() => (
          <BillingPlanResourcesTable
            keyField="id"
            data={props.billing_plan.resources['3']}
            type_id={props.billing_plan.type_id}
          />
        )}
      />

      <Route
        path={props.match.url + '/setup-fees'}
        exact
        render={() => (
          <BillingPlanResourcesTable
            keyField="id"
            data={props.billing_plan.fees.filter(fee => fee.type === 'setup')}
            type_id={props.billing_plan.type_id}
            isFee={true}
          />
        )}
      />

      <Route
        path={props.match.url + '/month-fees'}
        exact
        render={() => (
          <BillingPlanResourcesTable
            keyField="id"
            data={props.billing_plan.fees.filter(fee => fee.type === 'monthly')}
            type_id={props.billing_plan.type_id}
            isFee={true}
          />
        )}
      />
      <Route
        path={props.match.url + '/'}
        exact
        render={() => (
          <BillingPlanResourcesTable
            keyField="id"
            data={props.billing_plan.resources['1']}
            type_id={props.billing_plan.type_id}
          />
        )}
      />
    </Switch>
  ) : (
    <>
      {[...Array(5).keys()].map(() => (
        <div key={uuidv4()}>
          <CustomizedSkeletonComponent containerClassName="px-3 py-2" height={20} />
        </div>
      ))}
    </>
  );

export default SectionRouts;
