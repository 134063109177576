import React, { useContext } from 'react';
import { Card } from 'reactstrap';
import { valueToLabel } from '../DedicatedForm/initData';
import { ViewContext } from '../contexts/ViewContext';
import clsx from 'clsx';

const RowItem = ({ field, label, value, setup, month }) => (
  <div key={field} className="d-flex flex-column justify-content-between border-bottom">
    <h3>{valueToLabel[field] || field}</h3>
    <div key={field} className="d-flex flex-row justify-content-between ml-3">
      <div>{label}</div>
      <div>{value && value > 0 ? value : null}</div>
    </div>
  </div>
);

const fieldToDom = (setup = false, month, formData) => params => {
  const field = params;
  const label = formData[params] ? formData[params].label : null;
  const value = formData[params] ? formData[params].price : null;

  if (field !== 'hdd') {
    if (label) {
      return (
        <RowItem
          field={field}
          label={label}
          value={value}
          setup={setup}
          month={field !== 'Billing Period' ? month || 1 : 1}
        />
      );
    }
  } else {
    return (
      <div key={field} className="d-flex flex-column justify-content-between border-bottom">
        <h3>Hard Disks</h3>
        {Object.values(formData[params]).map((hdd, index) => (
          <div key={index} className="d-flex flex-row justify-content-between ml-3">
            <div>{hdd.label}</div>
            <div>{hdd.price}</div>
          </div>
        ))}
      </div>
    );
  }
};
const OrderSummaryCard = ({ formData, billingPeriod }) => {
  const calcMounthlyTotal = (formData, billingPeriod) => {
    if (formData) {
      return Object.entries(formData).reduce((accum, [field, data]) => {
        if (field === 'hdd') {
          return (
            accum +
            Object.values(data).reduce((diskAccum, disk) => {
              return diskAccum + (disk.price || 0);
            }, 0)
          );
        } else {
          return (data && data.price ? data.price : 0) + accum;
        }
      }, 0);
    }
  };
  const calcSetupFee = (formData, billingPeriod) => {
    if (formData) {
      return Object.entries(formData).reduce((accum, [field, data]) => {
        const price = data ? (data.setupPrice ? data.setupPrice : 0) : 0;
        return accum + price;
      }, 0);
    }
    return 0;
  };

  const mounthlyTotal = calcMounthlyTotal(formData, billingPeriod);
  const setupFee = calcSetupFee(formData, billingPeriod);
  const view = useContext(ViewContext);
  const mobile = view === 'xs';
  return (
    <Card
      className={clsx(
        mobile ? 'max-height-600' : 'position-fixed col-3 max-height-all overflow-scroll d-block',
        'p-0 dedicated'
      )}
      style={{ top: 0, right: 0 }}
    >
      <div className="d-flex flex-row p-3">
        <i className="my-auto fas fa-shopping-cart" alt="cart" style={{ height: 20 }} />
        <h1>Cart Summary</h1>
      </div>
      <div className={clsx(mobile && 'overflow-scroll d-block')}>
        <div className="d-flex flex-column p-3 border-bottom">
          {formData && Object.keys(valueToLabel).map(fieldToDom(false, billingPeriod, formData))}
        </div>
        <div className="d-flex flex-row p-3 border-bottom justify-content-between">
          <h3>Monthly Excluding VAT</h3>
          <h3>{mounthlyTotal + 'CHF'}</h3>
        </div>
        <div className="d-flex flex-row justify-content-between" style={{ padding: '3px 10px' }}>
          <h3>Setup fees</h3>
          <div>{setupFee}</div>
        </div>
      </div>
      <div className="d-flex flex-row p-3 border-top justify-content-between">
        <h3>First Invoice Total</h3>
        <h3>{setupFee + mounthlyTotal + 'CHF'}</h3>
      </div>
      <div className="d-flex flex-column p-3 border-top">
        <button type="submit" className="btn btn-primary mt-3">
          Checkout
        </button>
      </div>
    </Card>
  );
};

export default OrderSummaryCard;
