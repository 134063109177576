import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import Notification from './Notification';
import { NotificationContext } from '../contexts/NotificationContext';
import { handleFaviconChange } from '../../utils/commonUtils';

const NotificationsDropdown = ({ toggleDropdown, isOpen }) => {
  const { notifications, onReadNotification } = useContext(NotificationContext);
  const [loadNum, setLoadNum] = useState(5);
  const [unreadNotifications, setUnreadNotifications] = useState(notifications.filter(item => !item.read_at));
  const readNotifications = notifications.filter(item => item.read_at);
  const data = [...unreadNotifications, ...readNotifications.slice(0, loadNum)];

  useEffect(() => {
    if (unreadNotifications.length > 0) {
      handleFaviconChange('/favicon.ico');
    } else if (unreadNotifications.length === 0) {
      handleFaviconChange('/favicon.ico');
    }
  }, [unreadNotifications]);

  useEffect(() => {
    setUnreadNotifications(notifications.filter(item => !item.read_at));
  }, [notifications, isOpen]);

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="d-inline-block">
      <DropdownToggle data-cy="header-notification-button" className="nav-link user-panel-square" color="default">
        <i className="fas fa-bell mr-3 my-auto" />
        {unreadNotifications.length > 0 && <div className="user-panel-red-dot" />}
      </DropdownToggle>
      <DropdownMenu right className="notification-dropdown-menu">
        {data.map(item => (
          <div key={item.id} className="notification-dropdown-item-container">
            <Notification key={item.id} {...item} onRead={onReadNotification} />
          </div>
        ))}
        {loadNum < readNotifications.length && (
          <div className="text-center">
            <button
              data-cy="header-notification-load-more-button"
              className="btn btn-link"
              onClick={() => setLoadNum(loadNum + 5)}
            >
              Load more
            </button>
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationsDropdown;
