import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import DeleteConfirmationModal from '../shared/DeleteConfirmationModal';

const ButtonWithDeleteModal = ({
  entity,
  url,
  onDeleteFinished,
  children,
  onModalOpen,
  onModalClose,
  dataCy,
  noUrlAction,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        data-cy={dataCy}
        onClick={e => {
          if (onModalOpen) {
            onModalOpen(e);
          }
          setOpen(true);
        }}
        {...props}
      >
        {children}
      </Button>
      <DeleteConfirmationModal
        modalOpen={open}
        toggleModalItem={() => {
          setOpen(!open);
          if (onModalClose) {
            onModalClose();
          }
        }}
        url={url}
        entity={entity}
        onDeleteFinished={onDeleteFinished}
        dataCy={dataCy}
        name={props.name}
        noUrlAction={noUrlAction}
        successRedirectUrl={props.successredirecturl}
      />
    </>
  );
};

ButtonWithDeleteModal.propTypes = {
  entity: PropTypes.string,
  url: PropTypes.string,
  onDeleteFinished: PropTypes.func,
  children: PropTypes.element,
  onModalOpen: PropTypes.func,
  onModalClose: PropTypes.func,
};

export default ButtonWithDeleteModal;
