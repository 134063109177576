import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthConsumer } from '../contexts/AuthContext';

const GuestRoute = ({ component: Component, key, ...rest }) => (
  <AuthConsumer>
    {({ isAuth, passwordShouldBeChanged, menu }) => (
      <Route
        {...rest}
        render={props =>
          isAuth ? (
            !passwordShouldBeChanged ? (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
              />
            ) : (
              <Redirect to={{ pathname: '/password-change' }} />
            )
          ) : (
            <Component key={key} {...props} menu={menu} />
          )
        }
      />
    )}
  </AuthConsumer>
);

export default GuestRoute;
