import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Input, Label, CardFooter } from 'reactstrap';

import StepCard from '../../AddDevicePage/StepCard';
import NetworkLanOnlySvg from '../../../images/network_lan_only.svg';
import NetworkWanOnlySvg from '../../../images/network_wan_only.svg';
import NetworkFirewallSvg from '../../../images/network_firewall.svg';

const NetworkTypeSelect = ({ sideTitle, networkType, setNetworkType, trialType, history, onAttachButtonClick }) => {
  const types = {
    lan_only: 'LAN Only',
    wan_only: 'WAN Only',
    firewall: 'Firewall',
  };
  //Where to get network pricing
  return (
    <div className="row onboarding-row">
      <div className="col-12">
        <StepCard title="Network" sideTitle={sideTitle}>
          <div className="row">
            <div className="col-4">
              <div
                className={`network-type-select-item form-check form-check-inline w-100 ${
                  networkType === 'lan_only' ? 'checked' : ''
                }`}
              >
                <Input
                  data-cy="onboarding-network-lan-only"
                  type="radio"
                  className="form-check-input"
                  name="networkType"
                  id="network-lan_only"
                  value="lan_only"
                  checked={networkType === 'lan_only'}
                  onChange={() => setNetworkType('lan_only')}
                />
                <Label for="network-lan_only" className="form-check-label-btn">
                  <div className="network-type-select-item-name">LAN Only</div>
                  <img src={NetworkLanOnlySvg} alt="network-lan_only" />
                </Label>
              </div>
            </div>

            <div className="col-4">
              <div
                className={`network-type-select-item form-check form-check-inline w-100 ${
                  networkType === 'wan_only' ? 'checked' : ''
                }`}
              >
                <Input
                  data-cy="onboarding-network-wan-only"
                  type="radio"
                  className="form-check-input"
                  name="networkType"
                  id="network-wan_only"
                  value="wan_only"
                  checked={networkType === 'wan_only'}
                  onChange={() => setNetworkType('wan_only')}
                />
                <Label for="network-wan_only" className="form-check-label-btn">
                  <div className="network-type-select-item-name">WAN Only</div>
                  <img src={NetworkWanOnlySvg} alt="network-wan_only" />
                </Label>
              </div>
            </div>

            <div className="col-4">
              <div
                className={`network-type-select-item form-check form-check-inline w-100 ${
                  networkType === 'firewall' ? 'checked' : ''
                }`}
              >
                <Input
                  data-cy="onboarding-network-firewall"
                  type="radio"
                  className="form-check-input"
                  name="networkType"
                  id="network-firewall"
                  value="firewall"
                  checked={networkType === 'firewall'}
                  onChange={() => setNetworkType('firewall')}
                />
                <Label for="network-firewall" className="form-check-label-btn">
                  <div className="network-type-select-item-name">Firewall</div>
                  <img style={{ width: '72%' }} src={NetworkFirewallSvg} alt="network-firewall" />
                </Label>
              </div>
            </div>
          </div>
          {trialType !== 'full' && networkType !== 'lan_only' ? (
            <CardFooter style={{ padding: '20px 0 0' }}>
              <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-1">
                  <span
                    className="badge d-inline-block mb-3"
                    style={{
                      backgroundColor: '#ffc107',
                      color: '#fff',
                      width: 40,
                      height: 40,
                      padding: 12,
                    }}
                  >
                    <i className="fas fa-exclamation-triangle" />
                  </span>
                </div>
                <div className="col-7">
                  <p className="font-weight-bold font-size-lg">
                    Attach a payment method to proceed with "{types[networkType]}"
                  </p>
                  <p>Without card, device will still be created with "LAN only"</p>
                </div>
                <div className="col-3 offset-1">
                  <button
                    type="button"
                    data-cy="onboarding-network-attach-payment"
                    className="btn btn-success w-100 mb-3"
                    onClick={onAttachButtonClick}
                  >
                    Attach payment method
                  </button>
                </div>
              </div>
            </CardFooter>
          ) : null}
        </StepCard>
      </div>
    </div>
  );
};

NetworkTypeSelect.propTypes = {
  sideTitle: PropTypes.string,
  networkType: PropTypes.string,
  setNetworkType: PropTypes.func,
  trialType: PropTypes.string,
  onAttachButtonClick: PropTypes.func,
};

export default withRouter(NetworkTypeSelect);
