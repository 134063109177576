import React from 'react';
import { Card, Table } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

import NoDataMsg from '../UI/NoDataMsg';
import HeaderCard1 from '../UI/Headers/HeaderCard1';

const OrgInfo = props => {
  const content =
    props.data && Object.keys(props.data).length ? (
      <Table borderless size="sm">
        <tbody className="table-left-columns">
          {Object.keys(props.data).map(el => (
            <tr key={el}>
              <td className="md valign-top">
                {props.data[el].title[0].toUpperCase() + props.data[el].title.slice(1).toLowerCase()}
              </td>
              <td>{props.data[el].val || <Skeleton />}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <NoDataMsg noCard />
    );

  return (
    <Card>
      <HeaderCard1 title="Organization" />
      <div className="pt-3">{content}</div>
    </Card>
  );
};
export default OrgInfo;
