import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

const MultiInput = ({
  value,
  onChange,
  placeholder = 'Enter single or multiple entities',
  setIsMultiInputChanged,
  classname = '',
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isInputActive, setIsInputActive] = useState(false);
  const inputRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(20);

  const setInputActiveHandler = () => {
    setIsInputActive(true);
    setInputWidth(20);
  };

  useEffect(() => {
    if (isInputActive) {
      inputRef.current.focus();
    }
  }, [isInputActive]);

  useEffect(() => {
    if (typeof setIsMultiInputChanged !== 'undefined' && isInputActive) {
      setIsMultiInputChanged(inputValue.length > 0);
    }
  }, [inputValue]);

  const handleKeyPress = event => {
    if (event.key === 'Enter' && inputValue.length > 0) {
      event.preventDefault();
      onChange([...value, inputValue]);
      setInputValue('');
      setInputWidth(20);
      if (typeof setIsMultiInputChanged !== 'undefined') {
        setIsMultiInputChanged(false);
      }
    }
    if (event.key === 'Escape') {
      event.preventDefault();
      setInputValue('');
      setIsInputActive(false);
      if (typeof setIsMultiInputChanged !== 'undefined') {
        setIsMultiInputChanged(false);
      }
    }
  };

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  const handleRemoveItem = (event, indexToRemove) => {
    event.stopPropagation();
    onChange(value.filter((_, index) => index !== indexToRemove));
  };

  const handleRemoveCurrentItem = () => {
    setInputValue('');
    setIsInputActive(false);
    if (typeof setIsMultiInputChanged !== 'undefined') {
      setIsMultiInputChanged(false);
    }
  };

  const handleBlur = event => {
    if (inputValue.length > 0) {
      onChange([...value, inputValue]);
      setInputValue('');
      if (typeof setIsMultiInputChanged !== 'undefined') {
        setIsMultiInputChanged(false);
      }
    }
    setIsInputActive(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.scrollWidth);
    }
  }, [inputValue]);

  return (
    <div className={clsx(classname, 'multi-input')} onClick={setInputActiveHandler}>
      {value.length === 0 && !isInputActive && <div className="multi-input-placeholder">{placeholder}</div>}
      {value.map((item, index) => (
        <div key={index} className="d-flex mr-1">
          <div className="multi-input-item d-flex align-items-center">{item}</div>
          <div
            className="multi-input-button cursor-pointer d-flex align-items-center mx-auto"
            onClick={e => handleRemoveItem(e, index)}
          >
            <i className="fas fa-times" />
          </div>
        </div>
      ))}
      {isInputActive && (
        <div className="d-flex">
          <input
            value={inputValue}
            ref={inputRef}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
            className="multi-input-input-field"
            style={{ width: `${inputWidth}px` }}
            onBlur={e => handleBlur(e)}
          />
          <div
            className="multi-input-button cursor-pointer d-flex align-items-center"
            onMouseDown={handleRemoveCurrentItem}
          >
            <i className="fas fa-times" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiInput;
