import React from 'react';
import { CardBody, Button } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import clsx from 'clsx';

const HeaderCard1 = props => (
  <CardBody className="d-flex flex-row justify-content-between card-header-low-border">
    <h3 className="header-t2">
      {props.titleIcon ? props.titleIcon : null}
      {props.title}
    </h3>
    {props.buttonText &&
      (props.fetchingData ? (
        <Skeleton width={75} height={30} />
      ) : (
        !props.isDeleted && (
          <Button
            className={clsx('btn-sm btn-light', props.buttonClassname && props.buttonClassname)}
            onClick={props.buttonFunction}
            data-cy={props.dataCy}
          >
            {props.buttonIcon ? props.buttonIcon : null}
            {props.buttonText}
          </Button>
        )
      ))}
  </CardBody>
);

export default HeaderCard1;
