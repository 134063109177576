import React, { useState } from 'react';
import { Card } from 'reactstrap';
import clsx from 'clsx';

const FoldableStepCard = ({ title, children, className }) => {
  const [open, setOpen] = useState(false);
  return (
    <Card className={clsx(className)}>
      <div
        className={clsx('d-flex flex-row justify-content-between align-items-center p-4')}
        onClick={() => setOpen(!open)}
      >
        <h2 className="mb-0">{title}</h2>
        <i className={clsx(open ? 'fas fa-angle-up' : 'fas fa-angle-down', 'mx-4')} />
      </div>
      {open && <div className="p-4 border-top">{children}</div>}
    </Card>
  );
};

export default FoldableStepCard;
