import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'reactstrap';

import MenuLinks from './MenuLinks';
import CreditsItem from './CreditsItem';
import Sun from '../../images/sun.svg';
import Moon from '../../images/moon.svg';
import Megaphone from '../../images/megaphone.svg';
import { findKey, showUpdatesWidget } from '../../utils/commonUtils';
import { useHistory } from 'react-router-dom';
import axios from '../../axiosInstance';
import { AuthContext } from '../contexts/AuthContext';

const Navigation = props => {
  const appState = JSON.parse(localStorage.getItem('appState'));
  const companyName = findKey(appState, 'logo') || 'company title';
  const history = useHistory();

  const [nightMode, setNightMode] = useState(document.body.className.includes(' night-mode'));
  const [menuDisabled, setMenuDisabled] = useState(false);
  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    setNightMode(document.body.className.includes(' night-mode'));
  }, [document.body.className]);

  useEffect(() => {
    process.env.REACT_APP_WHITELABELING !== 'true' && showUpdatesWidget();
    document.addEventListener('disableNavMenu', () => setMenuDisabled(true));
    document.addEventListener('enableNavMenu', () => setMenuDisabled(false));
  }, []);

  const handleThemeChange = () => {
    const currentNightMode = document.body.className.includes('night-mode');
    axios
      .put(`api/user/update-dark-mode`, {
        darkMode: !currentNightMode,
      })
      .then(res => {
        if (res && res.status === 200) {
          if (!currentNightMode) {
            document.body.className += ' night-mode';
          } else {
            document.body.className = document.body.className.replace(' night-mode', '');
          }
          localStorage.setItem('night-mode', !currentNightMode);
          setNightMode(!currentNightMode);

          const userClone = { ...user };
          userClone.settings.darkMode = !currentNightMode;
          setUser(userClone);
        }
      })
      .catch(e => console.warn(e.message));
  };

  const { menuTogglerVisible } = props;
  const menuToggle = menuTogglerVisible ? (
    <button
      className="navbar-toggler"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        props.toggleMenu(e);
      }}
    >
      <i className="fas fa-bars" />
    </button>
  ) : null;
  return (
    <aside className="sidebar">
      <div className="sidebar-content-wrapper" style={menuDisabled ? { overflow: 'hidden' } : {}}>
        {menuDisabled && (
          <div className="blocked-menu-block">
            <div className="blocked-menu-sub-block">
              <p style={{ fontSize: 60, paddingTop: '5rem' }}>😝</p>
              <p>Finish the wizard first</p>
            </div>
          </div>
        )}

        <header className="sidebar-header">
          <div className="d-flex navbar-brand">
            {menuToggle}
            {props.menuShown && (
              <span
                onClick={() => {
                  history.go('/');
                }}
                className="header-t4 bold cursor-pointer"
              >
                {companyName}
              </span>
            )}
          </div>
          <div id="theme-widgets-container">
            {process.env.REACT_APP_WHITELABELING !== 'true' && (
              <Button
                className="btn btn-dark width-px-30 height-px-30 p-0 rn-badge"
                onClick={() => {}}
                id="updates-widget"
              >
                <img style={{ marginTop: '-2px' }} src={Megaphone} alt="megaphone" />
              </Button>
            )}
            <Button
              className="btn btn-dark width-px-30 height-px-30 p-0"
              onClick={handleThemeChange}
              id="theme-toggler"
            >
              <img style={{ marginTop: '-2px' }} src={nightMode ? Moon : Sun} alt="sky" />
            </Button>
          </div>
        </header>
        <nav>
          <ul className="navbar-nav">
            {!props.paidTenant && <CreditsItem user={props.user} />}
            <MenuLinks menu={props.menu} disabled={menuDisabled} closeMenu={props.closeMenu} />
          </ul>
        </nav>
      </div>
    </aside>
  );
};
export default Navigation;
