import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { AuthContext } from '../contexts/AuthContext';
import { withEcho } from '../hoc/withEcho';
import { usernameTruncate } from '../helpers/helperFunc';

const HeaderDropdown = props => {
  const { toggleDropdown, isOpen } = props;
  const { user, loggedInAsOtherUser, logout, logoutAsOtherUser } = useContext(AuthContext);

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="d-inline-block">
      <DropdownToggle tag="span" />
      <DropdownMenu className="user-panel-account-menu" right>
        <DropdownItem>
          <Link data-cy="header-my-account-button" to="/user-account">
            My Account
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link data-cy="header-chpassw-button" to={props.location.pathname + '?modal=passwordChange'}>
            Change Password
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link data-cy="header-sshkeys-button" to={props.location.pathname + '?modal=sshKeys'}>
            SSH Keys
          </Link>
        </DropdownItem>
        <DropdownItem data-cy="header-logout-button" onClick={() => logout()}>
          Log out
        </DropdownItem>
        {loggedInAsOtherUser ? (
          <DropdownItem data-cy="header-logout-as-button" onClick={logoutAsOtherUser}>
            Log out as{' '}
            {user ? usernameTruncate(user.firstname + ' ' + (user.surname ? user.surname : ''), 60) : 'Stranger'}
          </DropdownItem>
        ) : null}
      </DropdownMenu>
    </Dropdown>
  );
};

export default withRouter(withEcho(HeaderDropdown));
