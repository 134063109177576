import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import MessageSentSvg from '../images/mirage-message-sent.png';
import { AuthContext } from './contexts/AuthContext';
import axios from '../axiosInstance';

const NewEmailConfirm = ({ match }) => {
  const [status, setStatus] = useState(null);
  const [header, setHeader] = useState('');
  const [description, setDescription] = useState('');
  useEffect(() => {
    const { token } = match.params;
    axios
      .post('/api/user/email/confirm', { token })
      .then(res => {
        if (res && res.data) {
          setStatus('ok');
          setHeader('Email changed successfully');
          setDescription(res.data.message);
        }
      })
      .catch(e => {
        setStatus('error');
        setHeader('Oops! An error occured.');
        setDescription(e.response.data.message ? e.response.data.message : 'Undefined error');
      });
  }, []);
  const { logout } = useContext(AuthContext);
  return (
    <div className="auth-block">
      <Card className="demo login" style={{ marginTop: '10rem' }}>
        <CardBody className="text-center container mt-3">
          {status ? (
            <>
              {status === 'ok' ? <img src={MessageSentSvg} alt="message-sent" /> : null}
              <h2 className="font-weight-bold px-0 mx-0">{header}</h2>
              <p>{description}</p>
              {status === 'ok' ? (
                <Button
                  color="primary"
                  onClick={() => {
                    logout();
                    window.location.href = '/login';
                  }}
                >
                  Go to login page
                </Button>
              ) : null}
            </>
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withRouter(NewEmailConfirm);
