import React, { useState, createContext, useEffect, useContext } from 'react';
import axios from '../../axiosInstance';
import { AuthContext } from './AuthContext';
import { EchoContext } from './EchoContext';

export const AnnouncementsContext = createContext({
  announcements: [],
});

export const AnnouncementsProvider = props => {
  const { user, isAuth } = useContext(AuthContext);
  const { echo } = useContext(EchoContext);
  const [announcements, setAnnouncements] = useState([]);

  const fetchAnnouncements = () => {
    axios
      .get(`/api/user/announcements/announcements`)
      .then(res => res && res.status === 200 && res.data && setAnnouncements(res.data))
      .catch(error => {
        console.error('Error fetching announcements:', error);
      });
  };

  useEffect(() => {
    if (isAuth) {
      fetchAnnouncements();
    }
  }, [isAuth]);

  useEffect(() => {
    if (echo && user && user.id) {
      echo.private(`users.${user.id}`).listen('.announcement-added', res => {
        if (res.message) {
          fetchAnnouncements();
        }
      });
    }
  }, [echo, user]);

  const handleClose = identifier => {
    axios
      .get(`/api/user/announcements/${identifier}/close`)
      .then(res => res && res.status === 200 && fetchAnnouncements());
  };

  const value = { announcements, handleClose };

  return <AnnouncementsContext.Provider value={value}>{props.children}</AnnouncementsContext.Provider>;
};
