import React, { useContext } from 'react';
import pluralize from 'pluralize';
import { Card } from 'reactstrap';
import clsx from 'clsx';

import EmptyListSvg from '../../../images/empty-list.svg';
import { AuthContext } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';

const NoEntitiesMessage = ({ className, entityName, onCreate, onImport, dataCy, allowCreatePermission = true }) => {
  const { userPermissions } = useContext(AuthContext);
  let creationAllowed = true;
  let importAllowed = false;

  if (entityName === 'Organization' && !userPermissions.allowCreateOrganizations) {
    creationAllowed = false;
  }
  if (entityName === 'Device' && !userPermissions.allowCreateVirtualMachine) {
    creationAllowed = false;
  }
  if (entityName === 'Device' && userPermissions.allowImportVirtualMachine) {
    importAllowed = true;
  }
  if (entityName === 'Network' && userPermissions.allowImportNetwork) {
    importAllowed = true;
  }

  let convertedEntityName = entityName;
  if (entityName === 'Web Application Firewall') {
    convertedEntityName = 'WAF';
  }

  let noEntitiesHeader = `No ${convertedEntityName === 'WAF' ? 'WAFs' : pluralize.plural(convertedEntityName)} 
  were added`;
  let noEntitiesBodyText = "Let's change it :)";
  let handleCreateButton = onCreate;
  let creationText = `Create new ${convertedEntityName}`;

  if (entityName === 'Replica') {
    noEntitiesHeader = 'Your resource pool is empty';
    noEntitiesBodyText = 'Please, add replicas on the Cloud Connect portal or contact our team for help';
    creationText = `Contact support`;
    // dealing with a support button for disaster recovery page
    handleCreateButton = () =>
      window.open(
        process.env.REACT_APP_WHITELABELING !== 'true'
          ? 'https://www.xelon.ch/support/'
          : process.env.REACT_APP_SUPPORT_PAGE,
        '_blank'
      );
  }

  if (entityName === 'Invoice') {
    creationAllowed = false;
    noEntitiesHeader = 'Your Invoices list is empty';
  } else if (entityName === 'FailedJob') {
    creationAllowed = false;
    noEntitiesHeader = 'Failed jobs list is empty';
  }

  return (
    <Card className={clsx('d-flex flex-row justify-content-center py-5 px-3', className)}>
      <img src={EmptyListSvg} className="width-px-150 width-md-px-200 mr-5" alt="nothing-was-added" />
      <div className="h-100 my-auto">
        <h1 className="ml-0 p-0 d-flex flex-column justify-content-center">{noEntitiesHeader}</h1>
        {creationAllowed && (
          <div>
            <p className="mt-3 mb-5">{noEntitiesBodyText}</p>
            {allowCreatePermission && (
              <>
                {entityName === 'Network' ? (
                  <Link data-cy="create-new-network" to={onCreate} className="btn btn-primary">
                    {creationText}
                  </Link>
                ) : (
                  <button
                    onClick={handleCreateButton}
                    type="button"
                    className="btn btn-success"
                    data-cy={`create-new-${dataCy}`}
                  >
                    {creationText}
                  </button>
                )}
              </>
            )}
            {entityName === 'Device' && (
              <>
                <span style={{ margin: '0 20px' }}>or</span>
                <Link
                  data-cy="upload-import-device-link"
                  to={'/upload-import-device'}
                  className="btn btn-primary text-ellipsis white-space-nowrap"
                >
                  Import OVF
                </Link>
              </>
            )}
          </div>
        )}
        {onImport && importAllowed && (
          <button data-cy={`${dataCy}-import-button`} className="btn btn-light mt-2" onClick={onImport}>
            Import {pluralize.plural(convertedEntityName)}
          </button>
        )}
      </div>
    </Card>
  );
};

export default NoEntitiesMessage;
