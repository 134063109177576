import { FormGroup, Label } from 'reactstrap';
import React from 'react';
import Select from 'react-select';

const FeatureModalBody = ({ summary, setSummary, setSeverity, description, setDescription }) => {
  const severityOptions = [
    { value: 'blocker', label: 'Blocker' },
    { value: 'future-need', label: 'Future need' },
  ];

  return (
    <>
      <FormGroup>
        <Label>{`Which feature are you missing? *`}</Label>
        <input
          autoComplete="off"
          data-cy="report-name"
          type="text"
          name="summary"
          value={summary}
          className="form-control"
          placeholder="One sentence that summarizes your idea"
          onChange={e => setSummary(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="severity" data-cy="bug-severity-select">
          {`How urgent is it? *`}
        </Label>
        <Select name="severity" options={severityOptions} onChange={e => setSeverity(e.value)} />
      </FormGroup>
      <FormGroup>
        <Label>{`What problem would it solve for you? *`}</Label>
        <textarea
          rows="5"
          data-cy="report-short-description"
          name="description"
          value={description}
          className="form-control"
          placeholder="How would you benefit from this feature?"
          onChange={e => setDescription(e.target.value)}
        />
      </FormGroup>
    </>
  );
};

export default FeatureModalBody;
