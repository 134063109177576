import React, { useState } from 'react';
import { Card, CardBody, FormGroup, Input, Button, FormFeedback } from 'reactstrap';

import axios from '../../axiosInstance.js';
import Form from '../UI/Form';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [sent, setSentStatus] = useState(null);
  const [sending, setSending] = useState(false);

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    setEmail(value);
  };

  const passResetHandler = () => {
    if (!sending) {
      setSending(true);
      axios
        .post('/api/user/reset', { email })
        .then(r => {
          setSending(false);
          if (r && r.status === 200) {
            setSentStatus(r.data.message);
          }
        })
        .catch(e => setSending(false));
    }
  };

  return (
    <Card className="login">
      <CardBody>
        {!sent ? (
          <Form submit={passResetHandler}>
            <p>Reset password</p>
            <FormGroup>
              <Input
                data-cy="reset-passw-input"
                onChange={handleInputChange}
                placeholder="Email"
                type="email"
                name="email"
                value={email}
                required
              />
              <FormFeedback />
            </FormGroup>
            <Button data-cy="sevd-passw-reset-button" disabled={sending} color="primary" className="w-100">
              Send password reset link
            </Button>
          </Form>
        ) : (
          sent
        )}
      </CardBody>
    </Card>
  );
};

export default PasswordReset;
