import React, { useContext, useState } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

import Form from '../utils/form/form';
import cloneObject from '../utils/cloneObject';
import PasswordField from './UI/NewPasswordField';
import { AuthContext } from './contexts/AuthContext';
import axios from '../axiosInstance';

const PasswordChangeRoute = props => {
  const auth = useContext(AuthContext);
  const [data, setData] = useState(
    new Form({
      newPassword: '',
      newPassword_confirmation: '',
    })
  );
  const htmlParser = ReactHtmlParser;

  const onInputChange = e => {
    const newData = cloneObject(data);
    const name = e.name || e.target.name;
    newData[name] = e.value || (e.target ? e.target.value : '');
    newData.clearResults(name);
    setData(newData);
  };

  const submitForm = async e => {
    e.preventDefault();
    try {
      data.submitUsing(async () => {
        const response = await axios.post(`api/user/password/update`, {
          ...data.data(),
        });
        if (response && response.status === 200) {
          auth.setUser({
            passwordShouldBeChanged: false,
          });
          props.history.push('/');
        }
      });
    } catch (e) {
      // for rerender component
      setData(cloneObject(data));
    }
  };

  return (
    <div className="auth-block">
      <h1>
        {process.env.REACT_APP_WEBSITE_NAME_HTML ? (
          <span>{htmlParser(process.env.REACT_APP_WEBSITE_NAME_HTML)}</span>
        ) : (
          <span className="bold">{process.env.REACT_APP_WEBSITE_NAME}</span>
        )}
      </h1>

      <Card style={{ width: '100%', maxWidth: '360px', zIndex: '2' }}>
        <CardBody>
          <form onSubmit={submitForm}>
            <PasswordField
              name="newPassword"
              passwordClassname={data.errors.has('newPassword') && 'is-invalid'}
              validationMessage={data.errors.first('newPassword')}
              value={data.newPassword}
              handleInputChange={onInputChange}
              label="New Password"
              dataCy="set-new-password-0"
            />
            <PasswordField
              name="newPassword_confirmation"
              passwordClassname={data.errors.has('newPassword_confirmation') && 'is-invalid'}
              validationMessage={data.errors.first('newPassword_confirmation')}
              value={data.newPassword_confirmation}
              handleInputChange={onInputChange}
              label="New Password Confirmation"
              dataCy="set-new-password-1"
            />
            <Button type="submit" className="w-100" color="primary">
              Save new password
            </Button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default PasswordChangeRoute;
