import React from 'react';
import { FormFeedback } from 'reactstrap';

import Select from '../UI/SelectRequired';
import HeaderedCard from '../UI/HeaderedCard';
import { mainHddOptions, additionalHddOptions, raidOptions } from './initData';
import { formNewDiskName } from './utils';

const DiskUnit = ({ header, onChange, options, value }) => (
  <>
    <h3>{header}</h3>
    <Select value={value} required onChange={onChange} className="mb-3" options={options} />
  </>
);

const DisksCard = ({ onRaidChange, raid, onDiskChange, disks, errors }) => {
  const { 'HardDisk 1 (Main)': mainDisk, ...additionalDisks } = disks;
  return (
    <HeaderedCard className="d-flex flex-column p-4" headerLabel="Hard Disks" containerClassName="mb-3">
      <h3>RAID</h3>
      <Select
        value={raid}
        required
        className="mb-3"
        options={raidOptions.map(el => ({ label: el.label, value: el }))}
        onChange={onRaidChange}
      />
      <FormFeedback className="d-block input__danger-message">{errors.first('raid')}</FormFeedback>

      <DiskUnit
        options={mainHddOptions.map(el => ({ label: el.label, value: el }))}
        onChange={value => {
          onDiskChange({ ...disks, 'HardDisk 1 (Main)': value });
        }}
        value={mainDisk}
        header="HardDisk 1 (Main)"
      />

      {Object.entries(additionalDisks).map(([name, value]) => (
        <DiskUnit
          key={name}
          options={additionalHddOptions.map(el => ({
            label: el.label,
            value: el,
          }))}
          onChange={value => {
            onDiskChange({ ...disks, [name]: value });
          }}
          value={value}
          header={name}
        />
      ))}
      <FormFeedback className="d-block input__danger-message mb-3">{errors.first('hdd')}</FormFeedback>

      {Object.keys(disks).length < 8 && (
        <button
          className="btn btn-blue-light"
          type="button"
          onClick={() => {
            onDiskChange({
              ...disks,
              [formNewDiskName(Object.keys(disks).pop())]: {
                label: additionalHddOptions[0].label,
                value: additionalHddOptions[0],
              },
            });
          }}
        >
          Add Harddisk
        </button>
      )}
    </HeaderedCard>
  );
};

export default DisksCard;
