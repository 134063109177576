import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import PropTypes from 'prop-types';

// @todo add columns for Pre-Paid plan. Check prop type_id

const prePaidColumns = [
  {
    dataField: 'id',
    text: 'id',
    hidden: true,
  },
  {
    dataField: 'name',
    text: 'Name',
    editable: false,
  },
  {
    dataField: 'price',
    headerFormatter(column, colIndex, components) {
      return (
        <div className="d-flex flex-row justify-content-between align-items-center">
          <p className="m-0">Costs per Month (CHF)</p>
          {components.sortElement}
        </div>
      );
    },
    sortCaret: (order, column) => {
      if (!order) return <i className="fas fa-sort ml-2" />;
      else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
      else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
      return null;
    },
    text: 'Costs per Month (CHF)',
  },
];

const postPaidColumns = isFee => {
  const columns = [
    { dataField: 'id', text: 'id', hidden: true },
    {
      dataField: 'name',
      text: 'Name',
      editable: false,
      classes: 'lg',
    },
    {
      dataField: 'price',
      classes: 'md',
      headerFormatter(column, colIndex, components) {
        return (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <p className="m-0">{isFee ? 'Cost, CHF' : 'Cost per hour, CHF'}</p>
            {components.sortElement}
          </div>
        );
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return Number(b) - Number(a);
        }
        return Number(a) - Number(b); // desc
      },
      validator: (newValue, row, column) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: 'Price should be numeric',
          };
        }
        return true;
      },
      sortCaret: (order, column) => {
        if (!order) return <i className="fas fa-sort ml-2" />;
        else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
        else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
        return null;
      },
      // text: 'Costs per hour (CHF)',
      sort: true,
    },
  ];

  if (!isFee) {
    columns.push({
      dataField: 'month_price',
      text: '',
      classes: 'md',
      headerFormatter(column, colIndex, components) {
        return (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <p className="m-0">Costs per 30 days (CHF)</p>
            {components.sortElement}
          </div>
        );
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return Number(b) - Number(a);
        }
        return Number(a) - Number(b); // desc
      },
      sortCaret: (order, column) => {
        if (!order) return <i className="fas fa-sort ml-2" />;
        else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
        else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
        return null;
      },
      sort: true,
    });
  }

  return columns;
};

const BillingPlanResourcesTable = props => {
  let columns;

  if (props.type_id === 1) {
    columns = [...postPaidColumns(props.isFee)];
  } else if (props.type_id === 2) {
    columns = [...prePaidColumns];
  } else if (props.columns) {
    columns = props.columns;
  } else columns = [];

  const content = ((props.data && props.data.length > 0) || props.data !== null) && (
    <BootstrapTable
      bordered={false}
      keyField="id"
      columns={columns}
      data={props.data}
      classes="entity-block-table"
      wrapperClasses="mt mt-0 border-top-0 text-left rounded-0 rounded-bottom"
    />
  );
  return content;
};

BillingPlanResourcesTable.propTypes = {
  data: PropTypes.array,
  type_id: PropTypes.number,
};

export default BillingPlanResourcesTable;
