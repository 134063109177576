import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { withRouter } from 'react-router';
import clsx from 'clsx';

const PushNotification = ({ className, data, history, onCloseNotification, pushNotificationTimeout }) => {
  const [closeTimer, setCloseTimer] = useState(undefined);

  const handleClose = () => {
    onCloseNotification();
  };

  useEffect(() => {
    setCloseTimer(setTimeout(handleClose, pushNotificationTimeout));
  }, []);
  const handleMouseEnter = () => {
    clearTimeout(closeTimer);
  };

  const handleMouseLeave = () => {
    setCloseTimer(setTimeout(handleClose, pushNotificationTimeout));
  };

  return (
    <Card
      className={clsx('d-flex flex-row justify-content-between w-100 pointer-all push-notification mb-3', className)}
      onClick={event => {
        if (data.link) {
          history.push(data.link);
        }
        handleClose();
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {data.type && (
        <div
          className={clsx(
            'd-flex flex-column p-3 justify-content-center align-items-center',
            data.type === 'success'
              ? 'bg-success-light'
              : data.type === 'warning'
              ? 'bg-warning-light'
              : data.type === 'danger'
              ? 'bg-danger-light'
              : ''
          )}
        >
          {data.type === 'success' ? (
            <i className="fas fa-check success" />
          ) : data.type === 'warning' ? (
            <i className="fas fa-exclamation-triangle font-warning" />
          ) : data.type === 'danger' ? (
            <i className="fas fa-exclamation-circle text-danger" />
          ) : (
            ''
          )}
        </div>
      )}
      <div className="d-flex p-3 w-100 d-flex align-items-center">
        <div>{data.name}</div>
      </div>
      <div
        className="d-flex flex-column justify-content-between p-3 pl-0 cursor-pointer"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
      >
        <img
          src="data:image/svg+xml;charset=UTF-8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M4.35938 3.64062L3.64062 4.35938L7.28125 8L3.64062 11.6406L4.35938 12.3594L8 8.71875L11.6406 12.3594L12.3594 11.6406L8.71875 8L12.3594 4.35938L11.6406 3.64062L8 7.28125L4.35938 3.64062Z' fill='%23979797' /%3E%3C/svg%3E"
          alt="server"
          className="m-auto width-px-20"
        />
      </div>
    </Card>
  );
};

PushNotification.defaultProps = {
  pushNotificationTimeout: 6000,
};

PushNotification.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'warning', 'danger']),
    link: PropTypes.string,
  }),
  created_at: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  onCloseNotification: PropTypes.func,
};

export default withRouter(PushNotification);
