import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';

const Paginator = ({ pager, setSizePerPageHandler, setCurrentPage, fetchingData, sizes = [5, 10, 25] }) => {
  const [isOpenPerPage, setIsOpenPerPage] = useState(false);
  const selectRef = useRef();
  const currentPageRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [pagesRange, setPagesRange] = useState([1]);

  const isActive =
    (pager.currentPage !== 1 && pager.currentPage !== pager.lastPage) ||
    (pager.currentPage !== 1 && pager.currentPage === pager.lastPage && pager.lastPage === 2);

  const style = clsx({
    'page-item': true,
    active: isActive,
  });

  const firstPage =
    pager.currentPage !== 1
      ? pager.currentPage !== pager.lastPage
        ? pager.currentPage - 1
        : pager.currentPage - 2 === 0
        ? pager.currentPage - 1
        : pager.currentPage - 2
      : 1;

  const secondPage =
    pager.currentPage !== 1
      ? pager.currentPage !== pager.lastPage
        ? pager.currentPage
        : pager.currentPage - 1 === 1
        ? pager.currentPage
        : pager.currentPage - 1
      : 2;

  const thirdPage =
    pager.currentPage !== 1
      ? pager.currentPage !== pager.lastPage
        ? pager.currentPage + 1
        : pager.currentPage
      : pager.currentPage + 2;

  const handleChangePageSize = size => {
    setCurrentPage(1);
    setSizePerPageHandler(size);
    setIsOpenPerPage(!isOpenPerPage);
  };

  const handleSetCurrentPage = page => {
    setCurrentPage(page);
  };

  const handleSelectClick = () => {
    const selectRect = currentPageRef.current.getBoundingClientRect();
    setMenuPosition({ top: selectRect.bottom, left: selectRect.left });
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const customStyles = {
    menu: provided => ({
      ...provided,
      position: 'fixed',
      top: menuPosition.top - (pager.lastPage > 3 ? 300 : 100),
      left: menuPosition.left,
      width: 'auto',
      maxHeight: pager.lastPage > 3 ? '300px' : '100px',
      overflowY: 'auto',
      zIndex: 999,
    }),
  };

  const handleGlobalClick = event => {
    if (isMenuOpen && !event.target.classList.contains('current-page-link')) {
      handleCloseMenu();
    }
  };

  const fillPagesRange = () => {
    const range = [...Array((pager.lastPage || 0) + 1).keys()];
    range.shift();
    setPagesRange(range);
  };

  useEffect(() => {
    window.addEventListener('click', handleGlobalClick);
    fillPagesRange();

    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };
  }, [isMenuOpen]);

  return (
    <div className="row react-bootstrap-table-pagination">
      <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
        <span className="react-bs-table-sizePerPage-dropdown dropdown">
          <button
            id="pageDropDown"
            type="button"
            className="btn btn-default btn-secondary dropdown-toggle"
            onClick={() => setIsOpenPerPage(!isOpenPerPage)}
          >
            {pager.perPage}
          </button>
          <ul className={`dropdown-menu ${isOpenPerPage && 'open show'}`}>
            {sizes.map(size => (
              <span key={size} className="dropdown-item" onClick={() => handleChangePageSize(size)}>
                {size}
              </span>
            ))}
          </ul>
        </span>
        {fetchingData ? (
          <Skeleton height={16} width={200} />
        ) : (
          <span className="react-bootstrap-table-pagination-total text-muted" onClick={handleSelectClick}>
            {pager.total > 0 && `Showing ${pager.from} to ${pager.to} of ${pager.total} entries`}
          </span>
        )}
      </div>

      <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
        <ul className="pagination react-bootstrap-table-page-btns-ul">
          {pager.currentPage > 2 && (
            <li className="page-item">
              <span className="page-link test" onClick={() => handleSetCurrentPage(1)}>
                &#60;&#60;
              </span>
            </li>
          )}

          {pager.currentPage !== 1 && pager.total > pager.perPage && (
            <li className="page-item">
              <span className="page-link" onClick={() => handleSetCurrentPage(pager.currentPage - 1)}>
                &#60;
              </span>
            </li>
          )}

          <li
            className={`page-item ${pager.currentPage === 1 && 'active'}`}
            onClick={() => {
              pager.currentPage === 1 ? handleSelectClick() : handleSetCurrentPage(firstPage);
            }}
            ref={currentPageRef}
          >
            <span className={`page-link ${pager.currentPage === 1 && 'current-page-link'}`}>
              {firstPage}
              {pager.currentPage === 1 && (
                <i
                  className={clsx(
                    'fas',
                    'fa-chevron-down',
                    'current-page-link',
                    'ml-2',
                    'pt-1',
                    isMenuOpen && 'fa-rotate-180'
                  )}
                  style={{ color: '#fff' }}
                ></i>
              )}
            </span>
          </li>

          {pager.lastPage > 1 && (
            <li className={style} onClick={() => (isActive ? handleSelectClick() : {})}>
              <span
                className={clsx(['page-link', isActive && 'current-page-link px-3'])}
                onClick={() => (!isActive ? handleSetCurrentPage(secondPage) : {})}
              >
                {secondPage}
                {isActive && (
                  <i
                    className={clsx(
                      'fas',
                      'fa-chevron-down',
                      'current-page-link',
                      'ml-2',
                      'pt-1',
                      isMenuOpen && 'fa-rotate-180'
                    )}
                    style={{ color: '#fff', transition: '0.2s ease-out' }}
                  ></i>
                )}
              </span>
            </li>
          )}

          <div style={{ width: '1px', height: '1px', overflow: 'hidden' }}>
            <Select
              menuIsOpen={isMenuOpen}
              onClose={handleCloseMenu}
              styles={customStyles}
              defaultValue={{
                label: pager.currentPage,
                value: pager.currentPage,
              }}
              value={{
                label: pager.currentPage,
                value: pager.currentPage,
              }}
              options={pagesRange.map(page => ({
                label: page,
                value: page,
              }))}
              ref={selectRef}
              onChange={val => {
                handleSetCurrentPage(val.value);
              }}
            />
          </div>

          {pager.lastPage > 2 && (
            <li
              className={`page-item ${pager.lastPage === pager.currentPage && 'active'}`}
              onClick={() => (pager.lastPage === pager.currentPage ? handleSelectClick() : {})}
            >
              <span
                className={clsx(['page-link', pager.lastPage === pager.currentPage && 'current-page-link'])}
                onClick={() => (pager.lastPage !== pager.currentPage ? handleSetCurrentPage(thirdPage) : {})}
              >
                {thirdPage}
                {pager.lastPage === pager.currentPage && (
                  <i
                    className={clsx(
                      'fas',
                      'fa-chevron-down',
                      'current-page-link',
                      'ml-2',
                      'pt-1',
                      isMenuOpen && 'fa-rotate-180'
                    )}
                    style={{ color: '#fff' }}
                  ></i>
                )}
              </span>
            </li>
          )}

          {pager.currentPage !== pager.lastPage && (
            <li className="page-item">
              <span className="page-link" onClick={() => handleSetCurrentPage(pager.currentPage + 1)}>
                &#62;
              </span>
            </li>
          )}

          {pager.currentPage < pager.lastPage - 1 && (
            <li className="page-item">
              <span className="page-link" onClick={() => handleSetCurrentPage(pager.lastPage)}>
                &#62;&#62;
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

Paginator.propTypes = {
  pager: PropTypes.object,
  setCurrentPageHandler: PropTypes.func,
  setCurrentPage: PropTypes.func,
  fetchingData: PropTypes.bool,
};

export default Paginator;
