import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import Form from '../UI/Form';
import WanImage from '../../images/with-wan.svg';
import PaymentMethodSelectCard from '../Payments/PaymentMethodSelectCard';
import axios from '../../axiosInstance';
import { AuthContext } from '../contexts/AuthContext';

const WithWANUplinkModal = ({ modalIsOpen, billingAddress, onClose, onClickBack, tenant, onSubmit }) => {
  const { user } = useContext(AuthContext);
  const [cards, setCards] = useState([]);
  const customerId = user && user.tenant ? user.tenant.customer_id : null;

  const fetchCards = () => {
    if (customerId) {
      axios
        .get(`/api/user/payment/customer`)
        .then(res => res.data && res.data.paymentMethods && setCards(res.data.paymentMethods));
    }
  };

  useEffect(fetchCards, [customerId]);

  return (
    <Modal
      isOpen={modalIsOpen}
      toggle={() => onClose('demoOptions')}
      style={{ width: '650px' }}
      className="wider-modal"
    >
      <ModalHeader toggle={() => onClose()}>Account Setup</ModalHeader>
      <Form submit={onSubmit}>
        <ModalBody>
          <div className="row">
            <div className="col-2">
              <img src={WanImage} className="padding20" alt="with WAN" />
            </div>
            <div className="col-10">
              <p className="font-size-lg padding20">
                To activate your account, please connect the desired payment method.
                <br />
                {process.env.REACT_APP_WHITELABELING !== 'true' && (
                  <span>
                    Your Xelon HQ account includes CHF 50 credits, which are provided to you free of charge! As soon as
                    these credits run out, we charge the payment method selected below.
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <PaymentMethodSelectCard boxClass="box_B" onPaymentMethodSelected={fetchCards} />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" className="ml-auto btn-light" onClick={onClickBack}>
            Back
          </Button>
          <Button type="submit" color="secondary" disabled={!cards || !cards.length}>
            Submit
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WithWANUplinkModal;
