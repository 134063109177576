import React, { useContext, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from '../../axiosInstance';
import cloneObject from '../../utils/cloneObject';
import { AuthContext } from './AuthContext';

const initialStep = [
  {
    stepNumber: 1,
    name: 'Basics',
    stage: 'empty',
    isActive: true,
  },
  {
    stepNumber: 2,
    name: 'Network Settings',
    stage: 'empty',
    isActive: false,
  },
  {
    stepNumber: 3,
    name: 'Pools and Nodes',
    stage: 'empty',
    isActive: false,
  },
];

const defaultPricesArray = [
  {
    index: 1,
    price: 0,
  },
  {
    index: 2,
    price: 0,
  },
  {
    index: 3,
    price: 0,
  },
];

const initialPayloadState = {
  cluster_name: '',
  tenant_identifier: null,
  tenant_name: '',
  cloud_id: null,
  k8s_version: '',
  talos_version: '',
  control_plane_type: 'production',
  k8s_allowed_ips: [],
  talos_allowed_ips: [],
  cluster_domain: 'cluster.local',
  pod_subnet: '100.111.0.0/16',
  service_subnet: '100.112.0.0/12',
  control_plane_cpu: 2,
  control_plane_ram: 4,
  control_plane_disk: 50,
  worker_pool: [
    {
      worker_pool_index: 1,
      worker_pool_name: 'main',
      worker_node_amount: 3,
      worker_node_cpu: 2,
      worker_node_ram: 4,
      worker_node_disk: 50,
      worker_node_is_storage: false,
      worker_node_extra_disk: 0,
    },
  ],
  load_balancer_type: 'production',
  load_balancer_cpu: 2,
  load_balancer_ram: 4,
  load_balancer_disk: 50,
};

const defaultPool = {
  worker_pool_index: 1,
  worker_pool_name: 'poolName',
  worker_node_amount: 3,
  worker_node_cpu: 2,
  worker_node_ram: 4,
  worker_node_disk: 50,
  worker_node_is_storage: false,
  worker_node_extra_disk: 0,
};

const TalosCreationContext = React.createContext({});

const AddTalosProvider = props => {
  const { tenant } = useContext(AuthContext);
  const [resources, setResources] = useState(null);
  const [loadBalancerCost, setLoadBalancerCost] = useState(0);
  const [controlPlaneCost, setControlPlaneCost] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isShowSummaryPage, setIsShowSummaryPage] = useState(false);
  const [isMainButtonDisabled, setIsMainButtonDisabled] = useState(true);
  const [steps, setSteps] = useState(initialStep);
  const [pricesArr, setPricesArr] = useState(defaultPricesArray);
  const [payload, setPayload] = useState(initialPayloadState);

  const currentStep = steps.find(step => step.isActive).stepNumber;

  const changeStepHandler = number => {
    const updatedSteps = steps.map(step => ({
      ...step,
      isActive: step.stepNumber === number,
    }));

    setSteps(updatedSteps);
  };

  const changeStepStageHandler = (number, stage) => {
    const updatedSteps = steps.map((step, index) => {
      if (index === number - 1) {
        return { ...step, stage };
      }
      return step;
    });

    setSteps(updatedSteps);
  };

  const payloadChangeHandler = (key, value) => {
    if (key === 'cluster_name') {
      value.replace(/\s+/g, '-');
    }
    if (key === 'k8s_allowed_ips' && value === null) {
      value = [];
    }
    if (key === 'talos_allowed_ips' && value === null) {
      value = [];
    }
    if (key === 'talos_version') {
      const payloadClone = cloneObject(payload);
      payloadClone['talos_version'] = value;
      payloadClone['k8s_version'] = '';
      setPayload(payloadClone);

      return;
    }
    const payloadClone = cloneObject(payload);
    payloadClone[key] = value;
    setPayload(payloadClone);
  };

  const workerPoolChangeHandler = (index, key, value) => {
    const payloadClone = cloneObject(payload);
    payloadClone.worker_pool[index][key] = value;
    setPayload(payloadClone);
  };

  const addPool = () => {
    const payloadClone = cloneObject(payload);
    const newPool = defaultPool;
    newPool.worker_pool_index = payloadClone.worker_pool.length + 1;
    payloadClone.worker_pool.push(newPool);
    setPayload(payloadClone);

    const pricesArrClone = [...pricesArr];
    pricesArrClone.push({
      index: payload.worker_pool.length,
      price: 0,
    });

    setPricesArr(pricesArrClone);
  };

  const deletePool = index => {
    const payloadClone = { ...payload };
    payloadClone.worker_pool.splice(index, 1);

    payloadClone.worker_pool.forEach((pool, i) => {
      pool.worker_pool_index = i + 1;
    });
    setPayload(payloadClone);

    const pricesArrClone = [...pricesArr];
    pricesArrClone.splice(index + 2, 1);
    pricesArrClone.forEach((price, i) => {
      price.index = i + 1;
    });
    setPricesArr(pricesArrClone);
  };

  const fetchBillingPlan = async tenant => {
    const billing = await axios.get(`api/user/${tenant}/billing-resources`);
    if (billing.data) {
      setResources(billing.data.resources.reduce((accum, el) => ({ ...accum, [el.resource_id]: { ...el } }), {}));
    }
  };

  useMemo(() => {
    fetchBillingPlan(tenant);
  }, []);

  const findPriceBySlug = slugToFind => {
    for (const key in resources) {
      if (resources[key].slug === slugToFind) {
        return resources[key].price;
      }
    }
    return null;
  };

  useEffect(() => {
    const lbPrice =
      findPriceBySlug('cpu') * payload.load_balancer_cpu +
      findPriceBySlug('ram') * payload.load_balancer_ram +
      findPriceBySlug('storage') * payload.load_balancer_disk;

    const cpPrice =
      findPriceBySlug('cpu') * payload.control_plane_cpu +
      findPriceBySlug('ram') * payload.control_plane_ram +
      findPriceBySlug('storage') * payload.control_plane_disk;

    setLoadBalancerCost(lbPrice);
    setControlPlaneCost(cpPrice);

    const pricesArrClone = [...pricesArr];
    pricesArrClone[0].price = lbPrice * (payload.load_balancer_type === 'production' ? 2 : 1);
    pricesArrClone[1].price = cpPrice * (payload.control_plane_type === 'production' ? 3 : 1);
    setPricesArr(pricesArrClone);
  }, [resources, payload]);

  useEffect(() => {
    const newTotalPrice = pricesArr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.price;
    }, 0);

    setTotalPrice(newTotalPrice);
  }, [pricesArr]);

  const changePriceArr = (index, price) => {
    const pricesClone = [...pricesArr];
    pricesClone[index].price = price;
    setPricesArr(pricesClone);
  };

  const clearPayload = () => {
    setPayload(initialPayloadState);
    setSteps(initialStep);
  };

  return (
    <TalosCreationContext.Provider
      value={{
        payload,
        tenant,
        loadBalancerCost,
        controlPlaneCost,
        resources,
        steps,
        currentStep,
        pricesArr,
        totalPrice,
        isMainButtonDisabled,
        isShowSummaryPage,
        actions: {
          payloadChangeHandler,
          workerPoolChangeHandler,
          addPool,
          deletePool,
          changeStepHandler,
          changeStepStageHandler,
          findPriceBySlug,
          changePriceArr,
          clearPayload,
          setIsMainButtonDisabled,
          setIsShowSummaryPage,
        },
      }}
    >
      {props.children}
    </TalosCreationContext.Provider>
  );
};

export { TalosCreationContext };
export default withRouter(AddTalosProvider);
