import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Pool from './Pool';

const PoolsAndNodes = ({
  clusterIdentifier,
  clusterPoolsNodes,
  fetchingClusterPoolsNodes,
  deleteNode,
  toggleCollapse,
  setEditPoolModalIsOpen,
  setAddNodeModalIsOpen,
  setEditedPool,
  fetchClusterPoolsNodes,
}) => {
  return (
    <div>
      {fetchingClusterPoolsNodes ? (
        <div className="pl-2 pr-2">
          <Skeleton count={10} height={30} />
        </div>
      ) : (
        clusterPoolsNodes.map((pool, index) => (
          <Pool
            key={`${index}-${pool.name}`}
            clusterIdentifier={clusterIdentifier}
            pool={pool}
            poolIndex={index}
            toggleCollapse={toggleCollapse}
            setEditPoolModalIsOpen={setEditPoolModalIsOpen}
            setEditedPool={setEditedPool}
            setAddNodeModalIsOpen={setAddNodeModalIsOpen}
            deleteNode={deleteNode}
            fetchClusterPoolsNodes={fetchClusterPoolsNodes}
          />
        ))
      )}
    </div>
  );
};

export default PoolsAndNodes;
