import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import Skeleton from 'react-loading-skeleton';

import axios from '../../axiosInstance';
import PaymentModal from './PaymentModal';
import PaymentImg from '../../images/mirage-payment-processed.png';
import ButtonWithConfirmationModal from '../UI/ButtonWithConfirmationModal';
import { AuthContext } from '../contexts/AuthContext';

const columns = (
  onModalOpen,
  allowBankTransfer,
  onPayByBankTransfer,
  onViewInvoice,
  loading,
  userPermissions,
  getInvoices,
  setUserInfo,
  userCredits
) => [
  {
    text: 'Invoice',
    dataField: 'createdAt',
    formatter: cell => cell,
  },
  {
    dataField: 'total',
    text: 'CHF',
  },
  {
    dataField: 'state',
    text: '',
    formatter: cell => {
      return cell === 'paid' ? (
        <p className="text-success my-auto">Paid</p>
      ) : (
        <p className="text-danger my-auto">{cell === 'pending' ? 'Pending' : 'Expired'}</p>
      );
    },
  },
  {
    dataField: 'id',
    text: '',
    formatter: (cell, row) => (
      <div className="d-flex flex-row justify-content-end">
        {(row.state === 'pending' || row.state === 'expired') && userPermissions.allowManagePaymentMethods && (
          <UncontrolledButtonDropdown className="btn btn-blue p-0" direction="down">
            <Button
              data-cy="pay-for-invoice"
              id="caret"
              className="py-0 bg-clear"
              onClick={() => onModalOpen()(true, row.id)}
            >
              Pay
            </Button>
            <DropdownToggle caret className="p-0 bg-clear pr-2" />
            <DropdownMenu>
              <DropdownItem>
                <div onClick={() => onModalOpen(true)(true, row.id)}>Pay anonymously</div>
              </DropdownItem>
              {row.type !== 'credits' && row.sum <= userCredits ? (
                <DropdownItem>
                  <div
                    onClick={async () => {
                      await axios.post(`api/user/payment/${row.id}`, {
                        type: row.type,
                        payWithCredits: true,
                      });
                      getInvoices();
                      setUserInfo();
                    }}
                  >
                    Pay with credits
                  </div>
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )}
        {allowBankTransfer && (row.state === 'pending' || row.state === 'expired') && (
          <ButtonWithConfirmationModal
            message="Your request will be processed within 7 days!"
            label="Submit bank transfer payment"
            className="btn btn-light ml-3"
            onClick={() => onPayByBankTransfer(row.id)}
          >
            Pay by bank transfer
          </ButtonWithConfirmationModal>
        )}
        {row.loading ? (
          <div className="btn btn-light ml-3">
            <Skeleton />
          </div>
        ) : (
          userPermissions.allowViewInvoiceDetails && (
            <button className="btn btn-light ml-3" onClick={() => onViewInvoice(row.id)}>
              View invoice
            </button>
          )
        )}
      </div>
    ),
  },
];

const Invoices = ({ getInvoices, invoices, className, noHeader, handleShowInvoices }) => {
  const [loading, setLoading] = useState({});

  const handlePayByBankTransfer = id => {
    axios
      .post(`/api/user/payment/bank-transfer/request`, {
        invoice_id: id,
      })
      .then(() => getInvoices());
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [showInvoices, setShowInvoices] = useState(false);
  const [showPaidInvoices, setShowPaidInvoices] = useState(false);
  const { user, paidTenant, setPaidTenant, userPermissions, setUserInfo } = useContext(AuthContext);
  const allowBankTransfer = user.tenant.allow_bank_transfer;
  const handleModalOpen = (anon = false) => (open, id) => {
    setModalOpen(open ? (anon ? { type: 'anon', id } : { type: 'norm', id }) : false);
  };

  const handlePaymentComplete = () => {
    if (!paidTenant) {
      setPaidTenant(true);
    }
  };

  const handleViewInvoice = id => {
    setLoading({ ...loading, [id]: true });
    axios(`/api/user/invoice/${id}`, {
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      setLoading({ ...loading, [id]: false });
    });
  };

  useEffect(() => {
    getInvoices();
  }, []);
  useEffect(() => {
    invoices && invoices.some(el => el.state === 'pending' || el.state === 'expired')
      ? setShowInvoices(true)
      : setShowInvoices(false);
    if (handleShowInvoices) {
      setShowInvoices(true);
    }
  }, [invoices, handleShowInvoices]);

  let filteredInvoices = invoices
    ? invoices
        .map(el => ({
          ...el,
          loading: loading[el.id],
        }))
        .sort((a, b) => {
          return Date.parse(b.created_at) - Date.parse(a.created_at);
        })
    : [];
  if (!handleShowInvoices) {
    filteredInvoices = filteredInvoices.filter(el =>
      showPaidInvoices ? el.state === 'paid' : el.state === 'pending' || el.state === 'expired'
    );
  }

  return (
    <>
      {invoices ? (
        <Card className={`border-0 ${className}`}>
          {showInvoices ? (
            invoices.length === 0 ? (
              <CardBody className="d-flex justify-content-center">
                <h3>Invoices history is empty</h3>
              </CardBody>
            ) : (
              <BootstrapTable
                columns={columns(
                  handleModalOpen,
                  allowBankTransfer,
                  handlePayByBankTransfer,
                  handleViewInvoice,
                  loading,
                  userPermissions,
                  getInvoices,
                  setUserInfo,
                  user.credits
                )}
                data={filteredInvoices}
                keyField="id"
                headerClasses={noHeader && 'd-none'}
                classes="entity-block-table"
              />
            )
          ) : (
            <div style={{ maxHeight: '12rem' }} className="d-flex flex-row justify-content-center">
              <img src={PaymentImg} className="width-px-150 width-md-px-200" />
              <div className="d-flex flex-column justify-content-center">
                <h3>All invoices are paid</h3>
                <Button
                  className="btn btn-light"
                  onClick={() => {
                    setShowPaidInvoices(true);
                    setShowInvoices(true);
                  }}
                >
                  Invoice history
                </Button>
              </div>
            </div>
          )}
        </Card>
      ) : (
        <Skeleton height={100} />
      )}
      {modalOpen && (
        <PaymentModal
          open={modalOpen}
          onClose={() => {
            handleModalOpen()(false);
          }}
          onPaymentComplete={handlePaymentComplete}
          invoice={invoices && modalOpen && invoices.find(el => el.id === modalOpen.id)}
          getInvoices={getInvoices}
          setUserInfo={setUserInfo}
        />
      )}
    </>
  );
};

Invoices.propTypes = {
  getInvoices: PropTypes.func,
  invoices: PropTypes.array,
  className: PropTypes.string,
  noHeader: PropTypes.bool,
  handleShowInvoices: PropTypes.func,
};

export default Invoices;
