import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import ServerErrorImage from '../images/page-server-error.png';
import ErrorImage from '../images/error_500.svg';
import ReportModal from './Reports/ReportModal';

const ServerError500 = props => {
  const [reportModalVisible, setReportModalVisible] = useState(false);
  let homePath = '/';

  if (props.history.location.pathname.includes('login')) {
    homePath = null;
  }
  return (
    <div className="not-found-page d-flex flex-wrap">
      <div className="col-md-6 d-flex align-items-center not-found-page-left-side mt-md-0">
        <img className="img-fluid" src={ServerErrorImage} alt="Server error" />
      </div>
      <div className="col-md-6 not-found-page-right-side d-flex flex-column justify-content-center mt-md-0">
        <img className="img-fluid w-25 mb-5 img-night" src={ErrorImage} alt="500 error" />
        <p style={{ fontSize: '2rem', fontWeight: 'bold' }}>Server Error</p>
        <p className="h2">We'll be back on track soon. If you need help while</p>
        <p className="h2">
          this is down, search through our{' '}
          <span className="btn-link cursor-pointer" onClick={() => window.open('https://www.xelon.ch/docs', '_blank')}>
            Knowledge Base
          </span>
        </p>
        <p className="h2">or call the support team at +41 41 552 28 60</p>
        {homePath && (
          <div className="d-flex flex-wrap align-items-center mt">
            <Button color="primary" className="btn mr-2 mb-1" onClick={() => props.history.push(homePath)}>
              Dashboard
            </Button>
            <Button type="button" className="btn-light" onClick={() => setReportModalVisible(true)}>
              Report a bug
            </Button>
          </div>
        )}
      </div>
      {reportModalVisible && (
        <ReportModal
          modalOpen={reportModalVisible}
          type="Bug"
          toggleModal={() => setReportModalVisible(!reportModalVisible)}
        />
      )}
    </div>
  );
};

export default withRouter(ServerError500);
