import { FormGroup, Label } from 'reactstrap';
import React from 'react';
import Select from 'react-select';
import FileUpload from '../../UI/FileUpload';

const BugModalBody = ({
  summary,
  setSummary,
  setCategory,
  impact,
  setImpact,
  description,
  setDescription,
  expectedBehavior,
  setExpectedBehavior,
  actualBehavior,
  setActualBehavior,
  additionalInfo,
  setAdditionalInfo,
}) => {
  const categories = [
    { value: 'Organizations & Users', label: 'Organizations & Users' },
    { value: 'Devices', label: 'Devices' },
    { value: 'Templates, ISOs, Scripts', label: 'Templates, ISOs, Scripts' },
    { value: 'Kubernetes', label: 'Kubernetes' },
    { value: 'Networking', label: 'Networking' },
    { value: 'Billing & Reports', label: 'Billing & Reports' },
    { value: 'API & Documentation', label: 'API & Documentation' },
    { value: 'Other', label: 'Other' },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <FormGroup>
            <Label>{`Bug Name *`}</Label>
            <input
              autoComplete="off"
              data-cy="bug-name"
              type="text"
              name="summary"
              value={summary}
              className="form-control"
              placeholder="Short title describing an issue"
              onChange={e => setSummary(e.target.value)}
            />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup>
            <Label>{`Impact *`}</Label>
            <input
              autoComplete="off"
              data-cy="bug-impact"
              type="text"
              name="impact"
              value={impact}
              className="form-control"
              placeholder="Few words on what’s wrong"
              onChange={e => setImpact(e.target.value)}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormGroup>
            <Label for="category" data-cy="bug-category-select">
              {`Category *`}
            </Label>
            <Select name="category" options={categories} onChange={e => setCategory(e.value)} />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormGroup>
            <Label>{`Expected behavior *`}</Label>
            <textarea
              rows="5"
              data-cy="bug-expected-behavior"
              name="expected-behavior"
              value={expectedBehavior}
              className="form-control"
              placeholder="I expected X but it didn’t happen…"
              onChange={e => setExpectedBehavior(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>{`Bug description *`}</Label>
            <textarea
              rows="5"
              data-cy="bug-short-description"
              name="description"
              value={description}
              className="form-control"
              placeholder="The main problem is…"
              onChange={e => setDescription(e.target.value)}
            />
          </FormGroup>
        </div>
        <div className="col-md-6">
          <FormGroup>
            <Label>{`Actual behavior *`}</Label>
            <textarea
              rows="5"
              data-cy="bug-actual-behavior"
              name="actual-behavior"
              value={actualBehavior}
              className="form-control"
              placeholder="The actual behavior is…"
              onChange={e => setActualBehavior(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>{`Additional info`}</Label>
            <textarea
              rows="5"
              data-cy="bug-additional-info"
              name="additional-info"
              value={additionalInfo}
              className="form-control"
              placeholder="I just wanted you to know…"
              onChange={e => setAdditionalInfo(e.target.value)}
            />
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default BugModalBody;
