import React, { useContext, useState, Fragment } from 'react';
import generator from 'generate-password';
import qs from 'query-string';
import customerTypeOptions from './customerTypes';
import { Card, CardBody, FormGroup, Input, Button, FormFeedback, Label, Nav } from 'reactstrap';
import Select from 'react-select';
import { withRouter, useParams, useLocation, useRouteMatch, Route, Switch } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import clsx from 'clsx';

import Tabs from '../UI/Tabs/Tabs';
import axios from '../../axiosInstance.js';
import Form from '../../utils/form/form';
import PaymentImage from '../../images/mirage-message-sent.png';
import FraudImage from '../../images/mirage-unsubscribe.png';
import PasswordField from '../UI/NewPasswordField';
import { AuthContext } from '../contexts/AuthContext';
import cloneObject from '../../utils/cloneObject';
import { jobTitles } from '../../utils/constants';

const NavPanel = () => {
  const match = useRouteMatch();
  const params = useParams();

  const urlSplit = match.url.split('/');
  const customizeExperienceVisible = urlSplit[urlSplit.length - 1] === 'customize-experience';
  const verifyAccountVisible = urlSplit[urlSplit.length - 1] === 'verify-account';
  return (
    !verifyAccountVisible && (
      <Nav pills justified className="arrow-pills mb-3">
        <span className={clsx('nav-item nav-link', !params.token && 'active')}>Email Confirmation</span>
        <span className={clsx('nav-item nav-link', params.token && !customizeExperienceVisible && 'active')}>
          Account Details
        </span>
        <span className={clsx('nav-item nav-link', params.token && customizeExperienceVisible && 'active')}>
          Customize Experience
        </span>
      </Nav>
    )
  );
};

const StartDemo = props => {
  const initData = {
    tenantname: '',
    phone: '',
    country: 'CH',
    name: '',
    surname: '',
    job_title: '',
    password: '',
    token: props.match.params.token,
  };
  const match = useRouteMatch();
  const params = useParams();
  const location = useLocation();
  const [tenant, setTenant] = useState('');
  const email = qs.parse(location.search, { ignoreQueryPrefix: true }).email;

  const { loginSuccessHandler, handleRedirect } = useContext(AuthContext);

  const urlSplit = match.url.split('/');
  const customizeExperienceVisible = urlSplit[urlSplit.length - 1] === 'customize-experience';
  const verifyAccountVisible = urlSplit[urlSplit.length - 1] === 'verify-account';
  const [data, setData] = useState(initData);

  const [customizeExperience, setCustomizeExperience] = useState({
    customer_type: 'system-integrator',
    servers_quantity: 'mid',
    personal_demo: true,
    pricing_information: false,
    technical_questions: false,
  });
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [sending, setSending] = useState(false);
  const [registerEmail, setRegisterEmail] = useState('');

  const generatePasswordHandler = () => {
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      strict: true,
    });
    const newUserData = cloneObject(userData);
    newUserData.password = password;
    setUserData(newUserData);
    setVisiblePassword(true);
  };

  const [userData, setUserData] = useState(
    new Form({
      name: '',
      surname: '',
      phone: '',
      job_title: '',
      tenantname: '',
      password: '',
      token: props.match.params.token,
    })
  );

  const onInputChange = e => {
    const newUserData = cloneObject(userData);
    const name = e.name || e.target.name;
    newUserData[name] = e.value || (e.target ? e.target.value : '');
    newUserData.clearResults(name);
    setUserData(newUserData);
  };

  const onVerify = async e => {
    e.preventDefault();

    const payload = { email: registerEmail };

    try {
      await axios.post('/api/user/trial/subscribe', payload);
    } catch (e) {
      setRegisterEmail('');
    }
  };

  const onSave = async e => {
    e.preventDefault();
    const payload = { ...userData.data() };
    const phone = parsePhoneNumber(payload.phone);

    payload.phone_country_code = phone ? phone.countryCallingCode : '';
    payload.country = phone ? phone.country : '';

    try {
      await userData.submitUsing(async () => {
        const result = await axios.post('/api/user/trial/createTenant', payload);

        if (result && result.data && result.status === 200) {
          if (!result.data.fraud_check_failed) {
            setTenant(result.data.user.tenantIdentifier);

            loginSuccessHandler(result.data, props, `${match.url}/customize-experience`);
          } else {
            setTenant(result.data.tenant);
            handleRedirect(props, `${match.url}/verify-account`);
          }
        }
      });
    } catch (e) {
      // for rerender component
      setUserData(cloneObject(userData));
    }
  };

  const sendCustomizedExperienceHandler = () => {
    if (!sending) {
      setSending(true);
      const { token } = data;
      axios
        .post('/api/user/trial/createCustomizedExperience', {
          customizeExperience,
          token,
          tenant,
        })
        .then(r => {
          setSending(false);
          handleRedirect(props, {
            pathname: `/`,
            // pathname: `/my-organization/devices/addDevice`,
            // state: { backLink: '/my-organization/devices' },
          });
        })
        .catch(e => {
          console.warn(e.message);
          setSending(false);
        });
    }
  };

  const handleEmailResend = () => {
    axios.post('/api/user/trial/resend', {
      email,
    });
  };

  const handleCustomizeExperienceChange = (name, value) => {
    customizeExperience[name] = value;
    setCustomizeExperience({ ...customizeExperience, [name]: value });
  };

  const options = customerTypeOptions.map(item => ({
    ...item,
    onClick: () => handleCustomizeExperienceChange('customer_type', item.value),
    className: 'customize-experience-tab',
  }));

  let serversQuantityOptions = [
    {
      label: '1-3',
      value: 'min',
    },
    {
      label: '4-20',
      value: 'mid',
    },
    {
      label: '20+',
      value: 'max',
    },
  ];
  serversQuantityOptions = serversQuantityOptions.map(item => ({
    ...item,
    onClick: () => handleCustomizeExperienceChange('servers_quantity', item.value),
    className: 'customize-experience-tab',
  }));

  const specialistContactOptions = [
    process.env.REACT_APP_WHITELABELING !== 'true' && {
      label: 'Yes, I want a Personal Demo of Xelon HQ!',
      value: 'personal_demo',
    },
    {
      label: 'Yes, I need pricing information',
      value: 'pricing_information',
    },
    {
      label: 'Yes, I have technical questions',
      value: 'technical_questions',
    },
  ].filter(el => el);

  let jobTitle = jobTitles[0];
  jobTitle = jobTitle && jobTitle.length ? jobTitle[0].label : null;

  return (
    <Switch>
      <Fragment>
        <div className="auth-block">
          <Card className="demo login">
            <CardBody className="container">
              <NavPanel />
              <Route path={match.url === '/demo' && !params.token ? match.url : '/goodluck'} exact>
                <div className="row">
                  <img className="img-fluid col-5" style={{ width: 200 }} src={PaymentImage} alt="Not found" />
                  <div className="row flex-column col-7 py-5">
                    <h2>Check your Email</h2>
                    <div>
                      We have sent you a link to <b>{email}</b> to proceed with the setup.
                    </div>
                    <div>If you don’t see the email, try checking the “Spam” folder.</div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <button onClick={handleEmailResend} className=" col-12 btn btn-primary">
                    Resend Email
                  </button>
                </div>
              </Route>
              <Route path={match.url !== '/demo' && params.token && verifyAccountVisible ? match.url : 'beach'}>
                <div className="verify-account p-5">
                  <img style={{ width: 150 }} src={FraudImage} alt="Verify Account" />
                  <h1>We can't let you in with this info</h1>
                  <p>
                    We need to verify your account. Please, use your business email or provide more corporate
                    information during the sing-up.
                  </p>
                  <form onSubmit={onVerify}>
                    <div className="row d-flex justify-content-center position-relative">
                      <FormGroup className="m-0 w-100">
                        <Input
                          placeholder="Email address"
                          onChange={e => setRegisterEmail(e.target.value)}
                          type="email"
                          name="email"
                          className={clsx(
                            userData.errors.has('email') && 'is-invalid',
                            userData.validatedFields.has('email') && 'is-valid'
                          )}
                          value={registerEmail}
                          data-cy="verify-account-email-input"
                        />
                      </FormGroup>
                      <Button
                        data-cy="verify-account"
                        disabled={sending}
                        color="primary"
                        className="m-0 position-absolute"
                      >
                        Register
                      </Button>
                    </div>
                  </form>
                </div>
              </Route>
              <Route
                path={
                  match.url !== '/demo' && params.token && !customizeExperienceVisible && !verifyAccountVisible
                    ? match.url
                    : 'beach'
                }
              >
                <form onSubmit={onSave}>
                  <div className="row">
                    <FormGroup className="col-6">
                      <Label for="name">First name *</Label>
                      <Input
                        onChange={onInputChange}
                        type="text"
                        name="name"
                        value={userData.name}
                        className={clsx(
                          userData.errors.has('name') && 'is-invalid',
                          userData.validatedFields.has('name') && 'is-valid'
                        )}
                        data-cy="start-demo-name-input"
                      />
                      <FormFeedback className="d-block input__danger-message">
                        {userData.errors.first('name')}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup className="col-6">
                      <Label for="surname">Last Name *</Label>
                      <Input
                        onChange={onInputChange}
                        type="text"
                        name="surname"
                        value={userData.surname}
                        className={clsx(
                          userData.errors.has('surname') && 'is-invalid',
                          userData.validatedFields.has('surname') && 'is-valid'
                        )}
                        data-cy="start-demo-surname-input"
                      />
                      <FormFeedback className="d-block input__danger-message">
                        {userData.errors.first('surname')}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="row">
                    <FormGroup className="col-12">
                      <Label for="job_title" data-cy="start-demo-job-title-select">
                        Job Title
                      </Label>
                      <Select
                        name="job_title"
                        defaultValue={{
                          label: jobTitle,
                          value: userData.job_title,
                        }}
                        className={clsx(
                          userData.errors.has('job_title') && 'is-invalid',
                          userData.validatedFields.has('job_title') && 'is-valid'
                        )}
                        options={jobTitles}
                        onChange={e => onInputChange({ value: e.value, name: 'job_title' })}
                      />
                      <FormFeedback className="d-block input__danger-message">
                        {userData.errors.first('job_title')}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="row">
                    <FormGroup className="col-6">
                      <Label for="tenantname">Organization Name *</Label>
                      <Input
                        onChange={onInputChange}
                        type="text"
                        name="tenantname"
                        value={userData.tenantname}
                        className={clsx(
                          userData.errors.has('tenantname') && 'is-invalid',
                          userData.validatedFields.has('tenantname') && 'is-valid'
                        )}
                        data-cy="start-demo-tenantname-input"
                        autoComplete="off"
                      />
                      <FormFeedback className="d-block input__danger-message">
                        {userData.errors.first('tenantname')}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup className="col-6">
                      <Label for="phone">Mobile Phone *</Label>
                      <PhoneInput
                        value={userData.phone}
                        onChange={e => onInputChange({ value: e || '', name: 'phone' })}
                        autoComplete="new-random-phone"
                        displayInitialValueAsLocalNumber={false}
                        className={clsx(
                          'phone-input-styled',
                          'form-control',
                          userData.errors.has('phone') && 'is-invalid',
                          userData.validatedFields.has('phone') && 'is-valid'
                        )}
                        flags={flags}
                        country="CH"
                        data-cy="start-demo-phone-input"
                      />
                      <FormFeedback className="d-block input__danger-message">
                        {userData.errors.first('phone')}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="row">
                    <FormGroup className="col-6">
                      <PasswordField
                        name="password"
                        buttonClassName="mt-0"
                        passwordClassname={clsx(
                          userData.errors.has('password') && 'is-invalid',
                          userData.validatedFields.has('password') && 'is-valid'
                        )}
                        validationMessage={userData.errors.first('password')}
                        value={userData.password}
                        handleInputChange={onInputChange}
                        label="Password *"
                        visiblePassword={visiblePassword}
                        data-cy="start-demo-password"
                      />
                    </FormGroup>
                    <div className="col-4 row no-gutters align-items-end">
                      <button
                        className="btn btn-blue mb-auto"
                        style={{
                          height: 40,
                          width: '12rem',
                          marginTop: '3.4rem',
                          padding: '0 0.5rem',
                        }}
                        type="button"
                        onClick={generatePasswordHandler}
                      >
                        Generate Password
                      </button>
                    </div>
                  </div>
                  <Button data-cy="continue-demo" disabled={sending} color="primary" className="w-100">
                    Continue
                  </Button>
                </form>
              </Route>
              <Route
                path={
                  match.url !== '/demo' && params.token && customizeExperienceVisible && !verifyAccountVisible
                    ? match.url
                    : 'beach'
                }
              >
                <Fragment>
                  <br />
                  <h2 className="text-center bold">I am...</h2>
                  <Tabs value={customizeExperience.customer_type} options={options} type="sm" />
                  <br />
                  <h2 className="text-center bold">I need ... Servers / Containers</h2>
                  <Tabs value={customizeExperience.servers_quantity} options={serversQuantityOptions} type="sm" />
                  <br />
                  <h2 className="bold">
                    Would you like to get contacted
                    <br />
                    by our cloud specialists?
                  </h2>
                  <br />
                  {specialistContactOptions.map(item => (
                    <Fragment key={item.value}>
                      <FormGroup className="customize-experience-div">
                        <Input
                          id={`specialist-contact-${item.value}`}
                          type="checkbox"
                          checked={customizeExperience[item.value]}
                          onChange={e => {
                            const { checked } = e.target;
                            handleCustomizeExperienceChange(item.value, checked);
                          }}
                        />
                        <Label for={`specialist-contact-${item.value}`}>{item.label}</Label>
                      </FormGroup>
                    </Fragment>
                  ))}
                  <Button
                    data-cy="proceed-vdc"
                    type="button"
                    onClick={sendCustomizedExperienceHandler}
                    disabled={sending}
                    color="primary"
                    className="w-100"
                  >
                    Proceed to vDC
                  </Button>
                </Fragment>
              </Route>
            </CardBody>
          </Card>
        </div>
      </Fragment>
    </Switch>
  );
};

export default withRouter(StartDemo);
