import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const ReportDropdown = ({ toggleDropdown, isOpen, openReportModal }) => {
  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="d-inline-block">
      <DropdownToggle data-cy="header-bug-button" className="nav-link user-panel-square" color="default">
        <i className="fas fa-bug mr-3 my-auto" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem data-cy="header-bug-report-button" onClick={() => openReportModal('Bug')}>
          Report a bug
        </DropdownItem>
        <DropdownItem data-cy="header-feature-report-button" onClick={() => openReportModal('Improvement')}>
          Request a feature
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

ReportDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  openReportModal: PropTypes.func.isRequired,
};

export default ReportDropdown;
