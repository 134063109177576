import React from 'react';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Spinner = props => {
  const regularSpinner =
    props.size === 'sm' || props.size === 'inside-btn' ? (
      <div className={clsx(`lds-ellipsis ${props.size}`, props.className)}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    ) : (
      <Card className={'mt mb text-center ' + (props.className ? props.className : '')} style={props.style}>
        <CardBody>
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </CardBody>
      </Card>
    );

  const downloadSpinner = (
    <div className={'lds-facebook ' + (props.className ? props.className : '')}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  return props.download
    ? downloadSpinner
    : // : props.loadingData
      // ? loadingDataSpinner
      regularSpinner;
};

Spinner.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  download: PropTypes.bool,
  loadingData: PropTypes.bool,
};

export default Spinner;
