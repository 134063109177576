import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import generator from 'generate-password';
import { FormGroup, Card, CardBody } from 'reactstrap';

import StepCard from '../../AddDevicePage/StepCard';
import OSSelector from '../../shared/OSSelector';
import { withViewContext } from '../../hoc/withViewContext';
import PasswordField from '../../UI/NewPasswordField';

const OSs = [
  {
    value: 'templates_linux',
    icon: 'fab fa-linux',
    name: 'Linux',
  },
  {
    value: 'templates_windows',
    icon: 'fab fa-windows',
    name: 'Windows',
  },
];

const DeviceSettings = ({
  sideTitle,
  templates,
  onCurrentTemplateChange,
  results,
  onChange,
  setPasswordFilled,
  templateName,
  screenSize,
  modalOpen,
}) => {
  const [currentOS, setCurrentOS] = useState(undefined);
  const [distributive, setDistributive] = useState(undefined);
  const [visiblePassword, setVisiblePassword] = useState(false);

  useEffect(() => {
    if (modalOpen === false) {
      handleGeneratePassword();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (templates && templateName) {
      setCurrentOS(templateName);
      if (templates[templateName] && templates[templateName].length) {
        const distributiveName =
          templateName === 'templates_linux' ? 'Ubuntu 18.04 LTS 64 Bit EN' : 'Windows Server 2016 EN Std';
        const template = templates[templateName].filter(item => item.name === distributiveName);
        if (distributive === undefined) {
          setDistributive(getDistributiveItem(template[0]));
        }
      }
    }
  }, [templates, templateName]);

  const handleGeneratePassword = () => {
    const password = generator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      strict: true,
      exclude: '$[]{}<>~^`£|/\\',
    });
    onChange('password')(password);
    onChange('password_confirmation')(password);
    setVisiblePassword(true);
    setPasswordFilled(true);
  };

  const togglePasswordVisibility = () => {
    setVisiblePassword(!visiblePassword);
  };

  const handlePasswordChange = e => {
    onChange(e.target.name)(e.target.value);
    const secondField = e.target.name === 'password' ? 'password_confirmation' : 'password';
    if (e.target.value && results[secondField]) {
      setPasswordFilled(true);
    }
  };

  const getDistributiveItem = el => ({
    key: el.id,
    label: el.name,
    value: el.id,
  });
  //Ubuntu 18.04 LTS 64 Bit EN
  return (
    <>
      <div className="row onboarding-row">
        <div className="col-12">
          <StepCard title="Naming and OS" sideTitle={sideTitle}>
            <div className="row">
              <div className="col-6" style={{ borderRight: '1px solid #e5e5e5' }}>
                <OSSelector
                  OSs={OSs}
                  distributives={templates}
                  getDistributiveItem={getDistributiveItem}
                  currentOS={currentOS}
                  currentDistributive={distributive}
                  onOSChange={currentOS => {
                    setCurrentOS(currentOS);
                    setDistributive(undefined);
                  }}
                  onDistributiveChange={setDistributive}
                  onCurrentTemplateChange={onCurrentTemplateChange}
                  className="mt-0 mb-0 onboarding-os-selector"
                  defaultMenuNotShown={true}
                />
              </div>
              <div className="col-6">
                <FormGroup>
                  <label htmlFor="address-line-2">
                    <span className="bold">Display Name</span>
                  </label>
                  <input
                    data-cy="onboarding-display-name"
                    value={results['displayname'] || ''}
                    onChange={event => onChange('displayname')(event.target.value)}
                    type="text"
                    id="address-line-2"
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="postcode">
                    <span className="bold">Hostname</span>
                  </label>
                  <input
                    data-cy="onboarding-hostname"
                    value={results['hostname'] || ''}
                    onChange={event => onChange('hostname')(event.target.value)}
                    type="text"
                    id="postcode"
                    className="form-control"
                    required
                  />
                </FormGroup>
              </div>
            </div>
          </StepCard>
        </div>
      </div>
      <div className="row mt-3 ml-0 mr-0">
        <div className="col-12">
          <Card>
            <CardBody>
              <div className="row container">
                <PasswordField
                  dataCy="add-device-password"
                  validationMessage=""
                  className={screenSize === 'xs' ? 'flex-grow-2 flex-shrink-1 w-100' : 'width-35 mx-auto'}
                  name="password"
                  value={results['password'] || ''}
                  handleInputChange={handlePasswordChange}
                  label="Password*"
                  required={true}
                  visiblePassword={visiblePassword}
                  toggleVisibility={togglePasswordVisibility}
                  dataCheckBlacklisting={true}
                />
                <PasswordField
                  dataCy="add-device-password-confirmation"
                  validationMessage=""
                  className={screenSize === 'xs' ? 'flex-grow-2 flex-shrink-1 w-100' : 'width-35 mx-auto'}
                  hidePasswordVisibilityButton={true}
                  name="password_confirmation"
                  value={results['password_confirmation'] || ''}
                  handleInputChange={handlePasswordChange}
                  label="Password Confirmation*"
                  required={true}
                  visiblePassword={visiblePassword}
                  toggleVisibility={togglePasswordVisibility}
                  dataCheckBlacklisting={true}
                  formText="Password must contain uppercase and lowercase letters and numbers and have length min 6 characters"
                />
                <div className="col-3">
                  <button
                    type="button"
                    data-cy="onboarding-password-generate"
                    style={{ marginTop: '3.3rem' }}
                    className="btn btn-blue-dark w-100"
                    onClick={handleGeneratePassword}
                  >
                    Generate Password
                  </button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

DeviceSettings.propTypes = {
  sideTitle: PropTypes.string,
  templates: PropTypes.object,
  onCurrentTemplateChange: PropTypes.func,
  results: PropTypes.object,
  onChange: PropTypes.func,
  setPasswordFilled: PropTypes.func,
  templateName: PropTypes.string,
};

export default withViewContext(DeviceSettings);
