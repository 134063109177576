import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from '../../../axiosInstance';
import Skeleton from 'react-loading-skeleton';
import { Document, Page, pdfjs } from 'react-pdf';
import { ProgressBar } from 'react-bootstrap';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import pdfjsWorker from 'react-pdf/dist/esm/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ReportModal = ({ modalOpen, type, toggleModal }) => {
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [documentsUrls, setDocumentsUrls] = useState([]);
  const [isPending, setIsPending] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isAccepting, setIsAccepting] = useState(false);
  const [acceptedDocuments, setAcceptedDocuments] = useState([]);

  useEffect(() => {
    if (modalOpen) {
      fetchDocuments();
    }
  }, [modalOpen]);

  const fetchDocuments = () => {
    setIsPending(true);
    axios.get('/api/user/documents/not-accepted').then(response => {
      if (response.data.length > 0) {
        setDocuments(response.data);
      }
      setIsPending(false);
    });
  };

  useEffect(() => {
    documents.forEach(document => getPdf(document.id, documentsUrls));
  }, [documents]);

  useEffect(() => {
    setPageNumber(1);
  }, [currentDocumentIndex]);

  const getPdf = documentId => {
    axios
      .post(
        '/api/user/documents/download',
        {
          id: documentId,
        },
        {
          responseType: 'blob',
        }
      )
      .then(response => {
        setDocumentsUrls(documentUrls => ({
          ...documentUrls,
          [documents.findIndex(document => document.id === documentId)]: window.URL.createObjectURL(response.data),
        }));
      })
      .catch(error => {
        console.error('Error fetching PDF:', error);
      });
  };

  const acceptDocument = () => {
    setIsAccepting(true);
    axios
      .post('/api/user/documents/accept', { id: documents[currentDocumentIndex].id })
      .then(() => {
        setIsAccepting(false);
        if (acceptedDocuments.length < documents.length - 1) {
          setAcceptedDocuments([...acceptedDocuments, currentDocumentIndex]);

          if (currentDocumentIndex < documents.length - 1) {
            setPageNumber(1);
            setCurrentDocumentIndex(currentDocumentIndex + 1);
          }
        } else {
          toggleModal();
          setCurrentDocumentIndex(null);
          setAcceptedDocuments([]);
          setDocuments([]);
          setDocumentsUrls([]);
        }
      })
      .catch(e => {
        setIsAccepting(false);
        console.error('Error accepting document:', e);
      });
  };

  const downloadDocument = documentIndex => {
    const link = document.createElement('a');
    link.href = documentsUrls[documentIndex];
    link.setAttribute('download', documents[currentDocumentIndex].name + '.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Modal centered isOpen={modalOpen} className="modal-lg">
      <ModalHeader toggle={toggleModal}>
        {currentDocumentIndex === null ? (
          'Disclaimer'
        ) : (
          <p>
            {documents[currentDocumentIndex].name}
            {documents[currentDocumentIndex].required && (
              <span className="sm badge badge-danger badge-pill ml-3">Required</span>
            )}
          </p>
        )}
      </ModalHeader>
      <ModalBody>
        {currentDocumentIndex !== null && (
          <ProgressBar
            active={true}
            striped={true}
            now={
              documents.length
                ? ((currentDocumentIndex === null ? 0 : currentDocumentIndex + 1) / (documents.length + 1)) * 100
                : 0
            }
            label=""
          />
        )}
        {currentDocumentIndex === null && (
          <p>
            The Terms and Conditions of Xelon AG and the accompanying documents and third-party terms and conditions are
            part of the individual agreement between you or the company you represent ("the Customer") and Xelon AG
            regarding the purchase of products and services from Xelon AG. You must read and agree to Xelon AG's terms
            and conditions and the accompanying documents and third-party terms and conditions before ordering and using
            Xelon AG products or services. By ordering a subscription online by clicking on the "Accept" button and/or
            by using Xelon products or services, you agree to be bound by the terms and conditions and the accompanying
            documents and third-party terms and conditions. If you do not wish to agree to these documents, you should
            refrain from ordering Xelon AG products and services online. If you place the online order, including
            acceptance of the terms and conditions and the specified accompanying documents and third-party terms and
            conditions, on behalf of another person, company, or other legal entity, you represent and warrant that you
            are authorized to validly represent that person or company and to enter into this online agreement on behalf
            of another person or company.
          </p>
        )}
        {currentDocumentIndex !== null && !isPending && (
          <div>
            {documentsUrls[currentDocumentIndex] ? (
              <>
                <Document file={documentsUrls[currentDocumentIndex]} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
                <div className="text-center">
                  <p>
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                  </p>
                  <Button color="white" disabled={pageNumber <= 1} onClick={previousPage}>
                    &#60;
                  </Button>
                  <Button color="white" disabled={pageNumber >= numPages} onClick={nextPage}>
                    &#62;
                  </Button>
                </div>
              </>
            ) : (
              <Skeleton height={500} />
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {currentDocumentIndex !== null && (
          <Button
            type="button"
            className="btn-light"
            disabled={isAccepting}
            onClick={() => setCurrentDocumentIndex(currentDocumentIndex !== 0 ? currentDocumentIndex - 1 : null)}
          >
            Back
          </Button>
        )}
        {currentDocumentIndex !== null && currentDocumentIndex < documents.length - 1 && (
          <Button
            type="button"
            className="btn-light"
            disabled={isAccepting}
            onClick={() => setCurrentDocumentIndex(currentDocumentIndex + 1)}
          >
            Next
          </Button>
        )}
        {currentDocumentIndex !== null && !!documentsUrls[currentDocumentIndex] && (
          <Button
            type="button"
            disabled={isPending}
            className="btn-light"
            onClick={() => downloadDocument(currentDocumentIndex)}
          >
            Download
          </Button>
        )}
        {currentDocumentIndex === null ? (
          <Button
            color="primary"
            data-cy="report-bug-submit"
            className="ml-auto"
            disabled={documents.length === 0}
            onClick={() => setCurrentDocumentIndex(0)}
          >
            Start
          </Button>
        ) : (
          <Button
            color="primary"
            data-cy="report-bug-submit"
            disabled={isAccepting || acceptedDocuments.includes(currentDocumentIndex)}
            className="ml-auto"
            onClick={acceptDocument}
          >
            {isAccepting ? 'Accepting...' : acceptedDocuments.includes(currentDocumentIndex) ? 'Accepted' : 'Accept'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ReportModal;
