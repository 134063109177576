import React, { useState, useEffect } from 'react';
import { Label, FormGroup, FormFeedback, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const PasswordField = props => {
  const [visible, setVisibility] = useState(false);

  const toggleVisibility =
    props.toggleVisibility ??
    (() => {
      setVisibility(!visible);
    });

  const btnStyle = {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '40px',
  };

  useEffect(() => {
    setVisibility(props.visiblePassword);
  }, [props.visiblePassword]);

  return (
    <FormGroup className={clsx(props.validationMessage.length === 0 ? 'mb-0' : 'mb-4', props.className)}>
      {props.label && <Label for={props.name}>{props.label}</Label>}
      <div className="position-relative">
        <input
          className={clsx('mt-auto form-control', props.passwordClassname)}
          type={visible ? 'text' : 'password'}
          name={props.name}
          id={props.name}
          autoComplete="new-password"
          value={props.value}
          onChange={props.handleInputChange}
          style={{ paddingRight: '62px' }}
          required={props.required}
          data-check-blacklisting={props.dataCheckBlacklisting}
          data-cy={props.dataCy}
        />
        {!props.hidePasswordVisibilityButton &&
          (visible ? (
            <Button className={props.buttonClassName} color="white" onClick={toggleVisibility} style={btnStyle}>
              <i className="fas fa-eye-slash" style={{ fontSize: '18px' }} />
            </Button>
          ) : (
            <Button color="white" className={props.buttonClassName} onClick={toggleVisibility} style={btnStyle}>
              <i className="fas fa-eye" style={{ fontSize: '18px' }} />
            </Button>
          ))}
      </div>
      <FormFeedback className="d-block input__danger-message">{props.validationMessage}</FormFeedback>
      {props.formText && <FormFeedback className="d-block input__muted-message">{props.formText}</FormFeedback>}
    </FormGroup>
  );
};

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  minLength: PropTypes.number,
  label: PropTypes.string,
  visiblePassword: PropTypes.bool,
  dataCheckBlacklisting: PropTypes.bool,
};

export default PasswordField;
