import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Card } from 'reactstrap';

import CityOfServerSvg from '../../../images/city-of-server.svg';
import LogoWithText from '../../../images/xelon-logo-with-text.svg';

const StartOnboardingModal = ({ modalOpen, setModalOpen }) => (
  <Modal
    data-cy="onboarding-start-modal"
    isOpen={modalOpen}
    toggle={() => setModalOpen(false)}
    fade={false}
    className="modal-container p-0"
    id="start-onboarding-modal"
  >
    {process.env.REACT_APP_WHITELABELING !== 'true' && (
      <div className="onboarding-logo-overlay">
        <img src={LogoWithText} />
      </div>
    )}
    <Card id="start-onboarding-card">
      <div className="flex-container">
        <div className="col-5">
          <p className="font-size-lg-plus bold txt-align-left">
            {`${process.env.REACT_APP_WHITELABELING !== 'true' ? 'Welcome to Xelon! 👋' : 'Welcome! 👋'}`}
          </p>
          <p className="font-size-sm mt-2 txt-align-left text-muted">We are glad to have you on board!</p>
          <p className="font-size-lg txt-align-left mt-4">
            Whether you only want to test our services, or already create powerful server infrastructure based on Linux,
            Windows or Kubernetes,
          </p>
          <p className="font-size-lg-plus bold txt-align-left">we got your back! 💙</p>
          <Button
            data-cy="onboarding-close-modal-btn"
            type="button"
            className="btn-success mt-4"
            style={{ width: '50%' }}
            onClick={() => setModalOpen(false)}
          >
            Create your first Device
          </Button>
        </div>
        {process.env.REACT_APP_WHITELABELING !== 'true' && (
          <img src={CityOfServerSvg} alt="city-of-server" id="city-of-server-img" />
        )}
      </div>
    </Card>
  </Modal>
);

StartOnboardingModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
};

export default StartOnboardingModal;
