import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import Form from '../UI/Form';
import axios from '../../axiosInstance';
import { AuthContext } from '../contexts/AuthContext';
import { ceil10 } from '../helpers/helperFunc';
import { withEcho } from '../hoc/withEcho';
import resourcesToId from '../AddDevicePage/resourceToId';
import { rangedInputOptionsDemo } from '../AddDevicePage/defaultData';
import StartOnboardingModal from './StartOnboardingModal';
import TrialSelect from './TrialSelect';
import DeviceSettings from './DeviceSettings';
import NetworkTypeSelect from './NetworkTypeSelect';
import HardwareSettings from './HardwareSettings';
import Footer from './Footer';
import LogoWithText from '../../images/xelon-logo-with-text.svg';

const Onboarding = props => {
  const [modalOpen, setModalOpen] = useState(true);
  const [trialType, setTrialType] = useState(undefined);
  const [billingFormOpen, setBillingFormOpen] = useState(false);
  const [billingAddress, setBillingAddress] = useState({
    address: null,
    // address2: undefined,
    city: null,
    postcode: null,
    country: null,
    vat: undefined,
  });
  const [isPending, setIsPending] = useState(false);

  const [cards, setCards] = useState([]);
  const [passwordFilled, setPasswordFilled] = useState(false);
  const [templates, setTemplates] = useState();
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [templateChangeCallback, setTemplateChangeCallback] = useState(0);
  const [results, setResults] = useState({
    hostname: 'server-01',
  });
  const [cloudId, setCloudId] = useState(undefined);
  const [resources, setResources] = useState(undefined);
  const [networkType, setNetworkType] = useState('lan_only');
  const [firewallPrice, setFirewallPrice] = useState(undefined);
  const [availableNetworks, setAvailableNetworks] = useState([]);
  const [templateName, setTemplateName] = useState(undefined);
  const [stateRangedInputOptions, setStateRangedInputOptions] = useState(rangedInputOptionsDemo);
  const trialSelectRef = useRef(null);
  const { tenant, user, logout, onboardingDeactivate, customerType } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    getTemplates(tenant);
    getDisplayName(tenant);
    fetchBillingPlan(tenant);
  }, []);

  useEffect(() => {
    if (currentTemplate.id) {
      getTemplateDetails(currentTemplate);
    }
  }, [templateChangeCallback]);

  useEffect(() => {
    const updatedResults = { ...results };
    for (const option in stateRangedInputOptions) {
      if (stateRangedInputOptions[option].allowed) {
        updatedResults[option] = stateRangedInputOptions[option].min;
      } else updatedResults[option] = undefined;
    }
    setResults(updatedResults);
  }, [stateRangedInputOptions]);

  const getTemplates = tenant => {
    axios
      .get('/api/user/hv/list/' + tenant)
      .then(res => {
        if (res && res.data && res.data.length) {
          setCloudId(res.data[0].id);
          return res.data[0].id;
        }
      })
      .then(cloudId => {
        axios
          .get(`/api/user/device/templates?tenant=${tenant}&cloudId=${cloudId}`)
          .then(res => {
            if (res && res.data) {
              setTemplates(res.data);
            }
          })
          .catch(e => console.warn(e.message));
      })
      .catch(e => console.warn(e));
  };

  const updateRangeInputOptions = template => {
    // setResults(prevState => ({
    //   ...prevState,
    //   disksize: template.allowed_setdisk ? prevState.disksize : undefined,
    //   swapdisksize:
    //     template.has_swap && template.allowed_setdisk
    //       ? prevState.swapdisksize
    //       : undefined,
    // }));
    setStateRangedInputOptions(prevState => ({
      ...prevState,
      disksize: {
        ...prevState.disksize,
        allowed: template.allowed_setdisk,
        min: template.hdd_minsize_gb,
      },
      swapdisksize: {
        ...stateRangedInputOptions.swapdisksize,
        allowed: template.has_swap && template.allowed_setdisk,
      },
    }));
  };

  const getTemplateDetails = async template => {
    if (template && 'id' in template) {
      const { category, type } = template;
      let { id } = template;
      if (category === 'iso') {
        id = template.iso_id;
      }
      const response = await axios.get(`api/user/${tenant}/vmlist/create/${category}/${type}/${id}`);
      if (response && response.data) {
        const { data } = response;
        setAvailableNetworks(
          data.nics.map(({ ips, ...nic }) => ({
            ...nic,
            networks: nic.networks.map(network => ({
              ...network,
              ips: ips[network.value],
            })),
          }))
        );
        updateRangeInputOptions(data.template);
      }
    }
  };

  const getDisplayName = tenant => {
    axios
      .get('api/user/tenant/' + tenant)
      .then(res => {
        if (res && res.data) {
          setResults({ ...results, displayname: `${res.data.tenantname} VM` });
        }
      })
      .catch(e => console.warn(e.message));
  };

  const fetchBillingPlan = async tenant => {
    const billingId = await axios.get(`api/user/${tenant}/billing`);
    const billing = await axios.post('/api/user/billing/' + billingId.data.billingplan);
    if (billing.data) {
      const resources = billing.data.resources.reduce((accum, el) => ({ ...accum, [el.resource_id]: { ...el } }), {});
      const firewallItem = billing.data.resources.filter(item => item.name === 'Per Firewall instance');
      setResources(resources);
      if (firewallItem && firewallItem.length) {
        setFirewallPrice(ceil10(firewallItem[0].price, -4));
      }
    }
  };

  const getTemplateById = (id, currentOS) => {
    const result = templates[currentOS].find(ten => ten.id + '' === id + '');
    return result;
  };

  const handleTemplateChange = (templateId, currentOS) => {
    if (templateId === '') {
      setCurrentTemplate({});
      return;
    }
    const template = getTemplateById(templateId, currentOS);
    setCurrentTemplate(template);
    setTemplateName(currentOS);
    setResults(prevState => ({
      ...prevState,
      monitoring: false,
    }));
    setTemplateChangeCallback(prevState => prevState + 1);
  };

  const handleChange = form => value => {
    if (form === 'password' || form === 'password_confirmation') {
      const passwordField = document.getElementsByName('password')[0];
      passwordField.setCustomValidity('');
      const confirmPaswwordField = document.getElementsByName('password_confirmation')[0];
      confirmPaswwordField.setCustomValidity('');
    }
    setResults(prevState => ({ ...prevState, [form]: value }));
  };

  useEffect(() => {
    if (templates && customerType) {
      const templateName = customerType === 'developer-saas' ? 'templates_linux' : 'templates_windows';
      if (templates[templateName] && templates[templateName].length) {
        const templateId = templates[templateName][0].id;
        handleTemplateChange(templateId, templateName);
      }
    }
  }, [templates, customerType]);

  const devicePrice = ceil10(
    currentTemplate
      ? currentTemplate.type === 'Windows' && resources && resources[resourcesToId['windows']]
        ? resources[resourcesToId['windows']].price
        : 0
      : 0,
    -4
  );

  const configPrice = ceil10(
    resources
      ? Object.entries(results).reduce((accum, [parameter, value]) => {
          if (resourcesToId[parameter] && value) return accum + resources[resourcesToId[parameter]].price * value;
          else return accum;
        }, 0)
      : 0,
    -4
  );

  const scrollToTrialSelect = () => {
    trialSelectRef.current.scrollIntoView();
    setBillingFormOpen(true);
  };

  const handleSubmit = () => {
    setIsPending(true);
    const networkAdditionalData = availableNetworks.reduce((accum, nic) => {
      const { networks, nicname, ...usefulData } = nic;
      return { ...accum, ...usefulData };
    }, {});

    const payload = {
      ...results,
      ...networkAdditionalData,
      cloudId,
      tenant_identifier: tenant,
      trial_type: trialType,
      network_type: networkType,
      template: currentTemplate.id,
    };

    axios
      .post('api/user/onboarding', payload)
      .then(() => {
        setIsPending(false);
        onboardingDeactivate();
        props.history.push({
          pathname: '/device-success',
          state: {
            data: results,
            backLink: props.location.state && props.location.state.backLink,
            currentTemplate,
            availableNetworks,
          },
        });
      })
      .catch(() => {
        setIsPending(false);
      });
  };

  const networkTypes = {
    lan_only: 'LAN',
    wan_only: 'WAN',
    firewall: 'Firewall',
  };

  let networkSideTitle = '';
  let totalPrice = devicePrice + configPrice;
  if (networkType != 'firewall') {
    networkSideTitle = `First ${networkTypes[networkType]} for free`;
  } else if (firewallPrice) {
    networkSideTitle = `${firewallPrice} CHF / Hour`;
    totalPrice += firewallPrice;
  }

  return (
    <>
      <Form submit={handleSubmit}>
        <div className="container">
          <button
            data-cy="onboarding-logout-button"
            className="btn btn-light onboarding-logout-button"
            onClick={() => {
              logout();
              history.push('/');
            }}
          >
            Log Out
          </button>
          {process.env.REACT_APP_WHITELABELING !== 'true' && (
            <div className="onboarding-logo-overlay">
              <img src={LogoWithText} alt="Xelon" />
            </div>
          )}
          <StartOnboardingModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
          <TrialSelect
            trialType={trialType}
            setTrialType={setTrialType}
            billingFormOpen={billingFormOpen}
            setBillingFormOpen={setBillingFormOpen}
            billingAddress={billingAddress}
            setBillingAddress={data => setBillingAddress(data)}
            setCards={setCards}
            trialSelectRef={trialSelectRef}
          />
          <DeviceSettings
            templates={templates}
            sideTitle={`${devicePrice} CHF / Hour`}
            onCurrentTemplateChange={handleTemplateChange}
            results={results}
            onChange={handleChange}
            setPasswordFilled={setPasswordFilled}
            templateName={templateName}
            modalOpen={modalOpen}
          />
          <NetworkTypeSelect
            sideTitle={networkSideTitle}
            trialType={trialType}
            networkType={networkType}
            setNetworkType={setNetworkType}
            onAttachButtonClick={scrollToTrialSelect}
          />
          <HardwareSettings
            rangedInputOptions={stateRangedInputOptions}
            sideTitle={`${configPrice} CHF / Hour`}
            results={results}
            onChange={handleChange}
          />
        </div>
        <Footer
          visible={passwordFilled && trialType}
          price={ceil10(totalPrice, -4)}
          balance={user.credits}
          isPending={isPending}
        />
      </Form>
    </>
  );
};

export default withRouter(withEcho(Onboarding));
