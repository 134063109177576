import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';

import Tabs from './Tabs';

const NavigationTabs = ({ match, options, type, className }) => {
  const location = useLocation();
  const history = useHistory();
  const value = options.reduce((accum, option) => {
    return location.pathname.includes(option.path)
      ? option.path === ''
        ? accum
          ? accum
          : option.path
        : option.path
      : accum;
  }, undefined);

  return (
    <Tabs
      className={className}
      value={value}
      options={options.map(el => ({
        label: el.label,
        value: el.path,
        dataCy: el.dataCy ? el.dataCy : undefined,
        infoMessage: el.infoMessage ? el.infoMessage : undefined,
        id: el.id ? el.id : undefined,
        onClick: value => history.replace(match.url + value),
      }))}
      type={type}
    />
  );
};

NavigationTabs.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  type: PropTypes.oneOf(['lg', 'md', 'sm']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withRouter(NavigationTabs);
