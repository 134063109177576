import React, { useEffect, useState } from 'react';
import { Button, FormGroup, Label, Input, Modal, ModalHeader } from 'reactstrap';

import FormTabs from '../UI/FormTabs';
import Form from '../UI/Form';
import { ceil10 } from '../helpers/helperFunc';

const CreditsModal = ({ open, onClose, total, periodOptions, discounts, onCreditsBuy }) => {
  const [period, setPeriod] = useState(undefined);
  const [amountOfCredits, setAmountOfCredits] = useState(undefined);
  const [message, setMessage] = useState('');
  const [warningVisible, setWarningVisible] = useState(false);

  const handlePeriodChange = value => {
    if (value !== 'custom') {
      setPeriod(value);
      setAmountOfCredits(total * discounts[value].month);
    } else {
      setPeriod(value);
      setAmountOfCredits(0);
      setMessage('');
    }
  };

  const handleCreditChange = value => {
    value = parseFloat(value);
    if (value > 20000) {
      setWarningVisible(true);
      return;
    }
    setWarningVisible(false);
    setAmountOfCredits(value || '');
    const enoughFor = Math.floor(value / total);

    const curMessage = total > 50 && enoughFor ? `Enough for ${enoughFor} month` : '';
    setMessage(curMessage);
  };

  useEffect(() => {
    handlePeriodChange(periodOptions[0].value);
  }, [total]);

  return (
    <Modal centered isOpen={open} data-cy="credits-modal">
      <ModalHeader toggle={onClose}>Buy Credits</ModalHeader>
      <Form
        className="d-flex flex-column"
        submit={() => {
          onCreditsBuy(amountOfCredits);
        }}
      >
        <p className="mx-auto my-4">{`Your monthly expense is ${total} Credits.`}</p>
        <FormTabs className="mb-4 p-3" options={periodOptions} value={period} onChange={handlePeriodChange} />
        <FormGroup className="mb-4 p-3">
          <Label for="creditsamount">Credits Amount</Label>
          <Input
            name="creditsamount"
            data-cy="credits-modal-amount"
            className="w-50"
            value={ceil10(amountOfCredits, -2)}
            required
            disabled={period !== 'custom'}
            onChange={event => handleCreditChange(event.target.value)}
          />
          {warningVisible && <div className="form-error-message mt-1">Maximum value is 20000</div>}
          <div>{message}</div>
        </FormGroup>
        <div className="border-top p-3">
          <Button type="submit" color="success" className="btn border-0 w-100" data-cy="credits-modal-submit-btn">
            Proceed to Payment
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

CreditsModal.defaultProps = {
  periodOptions: [
    { label: '1 Year', value: '1_year', dataCy: 'credits-modal-tab-1_year' },
    { label: '2 Year', value: '2_year', dataCy: 'credits-modal-tab-2_year' },
    { label: '3 Year', value: '3_year', dataCy: 'credits-modal-tab-3_year' },
    { label: 'Custom', value: 'custom', dataCy: 'credits-modal-tab-custom' },
  ],
  discounts: {
    '1_year': { month: 12 },
    '2_year': { month: 24 },
    '3_year': { month: 36 },
  },
};

export default CreditsModal;
